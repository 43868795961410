import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';
import dayjs, { type Dayjs } from 'dayjs';
import { useAccountingFiltersNormalize } from 'hooks/index';
import { Box, Button, Dialog, DialogActions, DialogContent, HeaderText, IconButton } from '..';
import CustomPickersDay from './CustomPickersDay';

interface IDateTimePickerProps {
  value: Dayjs;
  onChange: (date: Dayjs) => void;
  max: Dayjs;
  min: Dayjs;
  from: Dayjs;
  to: Dayjs;
  title: string;
  dayOnly?: boolean;
}

const DateTimePicker = ({ value, onChange, max, min, title, from, to, dayOnly }: IDateTimePickerProps) => {
  const [timeView, setTimeView] = useState<'hours' | 'minutes' | 'seconds'>('hours');

  return (
    <Box>
      <HeaderText>{title}</HeaderText>
      <DateCalendar
        value={value}
        onChange={onChange}
        maxDate={max}
        minDate={min}
        disableHighlightToday
        showDaysOutsideCurrentMonth
        disableFuture
        slots={{ day: (props) => <CustomPickersDay {...props} from={from} to={to} /> }}
        views={['year', 'month', 'day']}
      />
      {!dayOnly && (
        <>
          <TimeClock ampm={false} value={value} onChange={onChange} view={'hours'} views={['hours']} />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IconButton color={timeView === 'hours' ? 'primary' : 'default'} onClick={() => setTimeView('hours')}>
              {value.format('HH')}
            </IconButton>
            <span>:</span>
            <IconButton color={timeView === 'minutes' ? 'primary' : 'default'} disabled>
              {value.format('mm')}
            </IconButton>
            <span>:</span>
            <IconButton color={timeView === 'seconds' ? 'primary' : 'default'} disabled>
              {value.format('ss')}
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

interface IProps {
  open: boolean;
  close: () => void;
  onAccept: (from: string, to: string) => void;
  initFrom: string;
  initTo: string;
  zone: string;
}

export const DateTimeRangePicker = ({ open, close, onAccept, initFrom, initTo, zone }: IProps) => {
  const { t } = useTranslation();
  const [maxDate, setMaxDate] = useState(dayjs());
  const minDate = useMemo(() => dayjs().subtract(2, 'year'), []);
  const [from, setFrom] = useState(dayjs(initFrom));
  const [to, setTo] = useState(dayjs(initTo));

  useEffect(() => {
    const activeTabHandler = () => setMaxDate(dayjs());
    window.addEventListener('visibilitychange', activeTabHandler);
    return () => window.removeEventListener('visibilitychange', activeTabHandler);
  }, []);

  const { groupBy } = useAccountingFiltersNormalize();

  useEffect(() => {
    setFrom(dayjs(initFrom));
    setTo(dayjs(initTo));
  }, [initFrom, initTo]);

  const handleAccept = useCallback(() => {
    onAccept(from.tz(zone).format(), to.tz(zone).format());
    close();
  }, [from, to, close]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog open={open} onClose={close} maxWidth={false}>
        <DialogContent sx={{ display: 'flex', gap: 2 }} dividers>
          <DateTimePicker
            title={t('accounting.DateFrom')}
            value={from}
            onChange={setFrom}
            max={to}
            min={minDate}
            from={from}
            to={to}
            dayOnly={groupBy === 'day'}
          />
          <DateTimePicker
            title={t('accounting.DateTo')}
            value={to}
            onChange={setTo}
            max={maxDate}
            min={from}
            from={from}
            to={to}
            dayOnly={groupBy === 'day'}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setFrom(dayjs().startOf('day'));
              setTo(dayjs().endOf('day'));
            }}
          >
            {t('base.today')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setFrom(dayjs().startOf('week'));
              setTo(dayjs().endOf('day'));
            }}
          >
            {t('base.thisWeek')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setFrom(dayjs().subtract(1, 'week').startOf('week'));
              setTo(dayjs().subtract(1, 'week').endOf('week'));
            }}
          >
            {t('base.lastWeek')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setFrom(dayjs().startOf('month'));
              setTo(dayjs().endOf('day'));
            }}
          >
            {t('base.thisMonth')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              setFrom(dayjs().subtract(1, 'month').startOf('month'));
              setTo(dayjs().subtract(1, 'month').endOf('month'));
            }}
          >
            {t('base.lastMonth')}
          </Button>

          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleAccept}>{t('base.accept')}</Button>
          <Button onClick={close} variant="outlined">
            {t('base.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default DateTimeRangePicker;
