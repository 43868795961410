import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type DocumentNode, type QueryResult } from '@apollo/client';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, MenuItem, Tooltip } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { PopperMenu } from 'components/PopperMenu';
import { useLoading } from 'hooks/useLoading';
import { useReportExport } from 'hooks/useReportExport';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { parseWhereFilter } from 'utils/filterUtils';
import { getFilterTableKey } from './translationMap';
import { getColumnMappers, type TColumnMapperTransformFn } from './utils';

interface IQuery {
  type: 'csv' | 'xlsx';
  query: DocumentNode;
  key: string;
  label: string;
}

interface INormalization {
  normalizeCurrency?: string;
  currencyDate?: string;
}

interface ITableExportProps<T> extends INormalization {
  authorized?: boolean;
  blockConditions?: {
    if: boolean;
    message: string;
  }[];
  columnMapperFn?: TColumnMapperTransformFn;
  excludedKeys?: string[];
  isExportBlocked?: boolean;
  filter: T;
  queries: IQuery[];
  groupBy?: string;
}

export function TableExport<T>({
  queries,
  authorized = true,
  excludedKeys,
  filter,
  columnMapperFn,
  blockConditions,
  currencyDate,
  normalizeCurrency,
  groupBy,
}: ITableExportProps<T>) {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { generatingReport, generate } = useReportExport();

  const { loading } = useLoading();

  const {
    userPreferences: { timezone },
  } = useUserPreferences();

  const fulfilledBlockCondition = blockConditions?.find((condition) => condition.if);

  const handleClick = useCallback((query: DocumentNode, result: (result: QueryResult) => string) => {
    setOpen(false);

    const sortModel = apiRef.current.getSortModel();
    const columns = apiRef.current?.getVisibleColumns();
    const columnsMappers = columnMapperFn ? columnMapperFn(columns) : getColumnMappers(columns);
    const filteredColumnsMappers = columnsMappers.filter((mapper) => !excludedKeys?.includes(mapper.field));

    const orderBy = sortModel.length > 0 ? getFilterTableKey(sortModel[0].field, !!normalizeCurrency) : null;

    const currencyDateUpdated = dayjs(currencyDate).tz(timezone).hour(12).minute(0).second(0).utc().format();

    const parsedFilter = parseWhereFilter((filter as { filter: Record<string, unknown> }).filter!, {
      normalized: !!normalizeCurrency,
    });

    const variables = {
      columnsMappers: filteredColumnsMappers,
      ...(orderBy && { orderBy }),
      orderDirection: sortModel?.[0]?.sort,
      timezone,
      ...(normalizeCurrency && {
        normalization: {
          baseCurrency: normalizeCurrency,
          ...(currencyDateUpdated && { currencyDate: currencyDateUpdated }),
        },
      }),
      filter: parsedFilter,
      ...(groupBy && { groupBy }),
    };

    generate(query, variables, result);
  }, []);

  if (!authorized) {
    return null;
  }

  return (
    <>
      <Tooltip title={generatingReport ? t('reportExport.onlyOneAtTime') : ''}>
        <Box>
          <Tooltip title={fulfilledBlockCondition && !generatingReport ? fulfilledBlockCondition.message : null}>
            <span>
              <Button
                ref={buttonRef}
                disabled={loading || generatingReport || !!fulfilledBlockCondition}
                variant="text"
                size="small"
                onClick={() => setOpen((s) => (generatingReport ? false : !s))}
              >
                <FileDownloadIcon fontSize="small" sx={{ mr: 1 }} />
                {t('base.export')}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Tooltip>
      <PopperMenu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        {queries.map(({ key, label, query }) => (
          <MenuItem key={key} onClick={() => handleClick(query, (result) => result.data[key].url)}>
            {label}
          </MenuItem>
        ))}
      </PopperMenu>
    </>
  );
}
