import { useEffect, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import {
  GridPreferencePanelsValue,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext,
  type GridDensity,
} from '@mui/x-data-grid';
import { Box, Button, Tooltip } from 'components';
import { useSaveDataGridColumnsVisibility, useSaveDataGridColumnsWidth, useUserPreferences } from 'hooks';

interface IProps extends PropsWithChildren {
  localStorageKey: string;
  additionalColumnElements?: Record<string, string>;
  disableManageColumns?: boolean;
  manageColumnsTooltip?: string;
  defaultHideColumns?: string[];
}

const DEFAULT_DENSITY = 'compact';

export const DataGridToolbar = ({
  localStorageKey,
  children,
  additionalColumnElements,
  disableManageColumns,
  manageColumnsTooltip,
  defaultHideColumns,
}: IProps) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const { adjustColumnsWidth } = useSaveDataGridColumnsWidth(localStorageKey, apiRef, additionalColumnElements);
  useSaveDataGridColumnsVisibility({
    localStorageKey,
    apiRef,
    disableManageColumns,
    defaultHideColumns,
  });
  const { userPreferences, setUserPreference } = useUserPreferences();

  useEffect(() => {
    const savedDensity = localStorage.getItem('x-data-grid-column-density') || DEFAULT_DENSITY;
    if (['compact', 'standard', 'comfortable'].includes(savedDensity)) {
      apiRef.current.setDensity(savedDensity as GridDensity);
    }

    return apiRef.current.subscribeEvent('densityChange', (density) => {
      localStorage.setItem('x-data-grid-column-density', density);
    });
  }, []);

  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <Tooltip title={manageColumnsTooltip}>
        <Box>
          <Button
            variant="text"
            size="small"
            onClick={() => apiRef.current.showPreferences(GridPreferencePanelsValue.columns)}
            disabled={disableManageColumns}
          >
            <ViewColumnIcon fontSize="small" sx={{ mr: 1 }} />
            {apiRef.current.getLocaleText('columnMenuManageColumns')}
          </Button>
        </Box>
      </Tooltip>
      <Button
        variant="text"
        size="small"
        onClick={() => {
          setUserPreference('autoAdjustColumnsWidth', (prev) => {
            if (!prev) {
              adjustColumnsWidth();
            }
            return !prev;
          });
        }}
      >
        <AspectRatioIcon fontSize="small" sx={{ mr: 1 }} />
        {userPreferences.autoAdjustColumnsWidth ? t('base.autoColumnsWidth') : t('base.customColumnsWidth')}
      </Button>
      {children}
    </GridToolbarContainer>
  );
};

export default DataGridToolbar;
