import { atom } from 'jotai';
import {
  buildClients,
  buildClusters,
  buildOperators,
  type TBuildClients,
  type TBuildClusters,
  type TBuildOperators,
} from './accessBuildGroups';
import {
  canSelectAndAllClient,
  canSelectAndAllCluster,
  createGroup,
  getFilteredClients,
  getFilteredOperators,
  getUncheckedChildGroups,
  getUpdatedClientsSelectAll,
  getUpdatedClustersSelectAll,
  uncheckClientOperators,
  uncheckClusterClients,
  uncheckClusterOperators,
} from './accessGroupAtomHelpers';
import { enrichClientsPermissions, enrichClustersPermissions, enrichOperatorsPermissions } from './permissionMapping';

export type TClusterAtomReturn = ReturnType<typeof createAccessGroups>['clusterGroup'];
export type TClientAtomReturn = ReturnType<typeof createAccessGroups>['clientGroup'];
export type TOperatorAtomReturn = ReturnType<typeof createAccessGroups>['operatorGroup'];
export type TClusterDataAtom = TClusterAtomReturn['data'];
export type TClientDataAtom = TClientAtomReturn['data'];
export type TOperatorDataAtom = TOperatorAtomReturn['data'];

export function createAccessGroups() {
  const clusterDataAtom = atom<TBuildClusters>([]);
  const clientDataAtom = atom<TBuildClients>([]);
  const operatorDataAtom = atom<TBuildOperators>([]);

  const clusterGroup = createGroup({
    dataAtom: clusterDataAtom,
    buildFn: (items, permissions) => {
      const clusters = buildClusters(items);
      const enrichedClusters = enrichClustersPermissions(clusters, permissions);
      return enrichedClusters;
    },
    dataVisibleFn: (get) => get(clusterDataAtom),
    selectAllAndNew: (get, set, clusterId, shouldCheck) => {
      const clusters = get(clusterDataAtom);
      const updatedClusters = getUpdatedClustersSelectAll(clusters, clusterId, shouldCheck);

      if (!shouldCheck) {
        const updatedClients = uncheckClusterClients(clusterId, get(clientDataAtom));
        const updatedOperators = uncheckClusterOperators(clusterId, get(operatorDataAtom));

        set(clientDataAtom, updatedClients);
        set(operatorDataAtom, updatedOperators);
      }

      set(clusterDataAtom, updatedClusters);
    },
    onItemsClear: (get, set) => {
      const clusters = get(clusterDataAtom);
      const clients = get(clientDataAtom);
      const operators = get(operatorDataAtom);

      const { updatedClients, updatedOperators } = getUncheckedChildGroups(clusters, clients, operators);

      set(clientDataAtom, updatedClients);
      set(operatorDataAtom, updatedOperators);
    },
    canUserSelectAllAndNew: (_, user, clusterId: string) => canSelectAndAllCluster(user, clusterId),
  });

  const clientGroup = createGroup({
    dataAtom: clientDataAtom,
    buildFn: (items, permissions, get) => {
      const clients = buildClients(items, get(clusterGroup.data));
      const enrichedClients = enrichClientsPermissions(clients, permissions);
      return enrichedClients;
    },
    dataVisibleFn: (get) => {
      const clusters = get(clusterDataAtom);
      const clients = get(clientDataAtom);
      const filteredClients = getFilteredClients(clusters, clients);
      return filteredClients;
    },
    selectAllAndNew: (get, set, clientId, shouldCheck) => {
      const clients = get(clientDataAtom);
      const updatedClients = getUpdatedClientsSelectAll(clients, clientId, shouldCheck);

      if (!shouldCheck) {
        const updatedOperators = uncheckClientOperators(clientId, get(operatorDataAtom));
        set(operatorDataAtom, updatedOperators);
      }

      set(clientDataAtom, updatedClients);
    },
    onItemsClear: (get, set) => {
      const clusters = get(clusterDataAtom);
      const clients = get(clientDataAtom);
      const operators = get(operatorDataAtom);

      const { updatedClients, updatedOperators } = getUncheckedChildGroups(clusters, clients, operators);

      set(clientDataAtom, updatedClients);
      set(operatorDataAtom, updatedOperators);
    },
    canUserSelectAllAndNew: (get, user, clientId: string) => {
      const clusters = get(clusterDataAtom);
      const clients = get(clientDataAtom);
      const client = clients.find((client) => client.id === clientId);
      const clusterId = clusters.find((cluster) => cluster.id === client?.value.clusterId)?.id;

      if (!clusterId) {
        throw new Error('Cluster ID not found.');
      }

      return canSelectAndAllClient(user, clientId, clusterId);
    },
  });

  const operatorGroup = createGroup({
    dataAtom: operatorDataAtom,
    buildFn: (items, permissions, get) => {
      const operators = buildOperators(items, get(clusterGroup.data));
      const enrichedOperators = enrichOperatorsPermissions(operators, permissions);
      return enrichedOperators;
    },
    dataVisibleFn: (get) => {
      const clusters = get(clusterDataAtom);
      const clients = get(clientDataAtom);
      const operators = get(operatorDataAtom);
      const filteredOperators = getFilteredOperators(clusters, clients, operators);
      return filteredOperators;
    },
  });

  return { clusterGroup, clientGroup, operatorGroup };
}
