type TParseRedirectToBetValues = {
  countries?: string;
  currencies?: string;
  playerId?: string;
  playerExternalId?: string;
  playerInternalId?: string;
  clusters?: string;
  clients?: string;
  operators?: string;
};

export const parseFilterToURLParam = (browserValues: TParseRedirectToBetValues, rest?: unknown[]) => {
  return [...(rest || []), ...Object.entries(browserValues).map(([k, v]) => getFilterObject(k, v))];
};

const getFilterObject = (key: string, value: string) => {
  return {
    key,
    state: {
      values: [value],
      operator: 'equal',
    },
  };
};
