import { useTranslation } from 'react-i18next';
import { Box, ChangePasswordForm, HeaderText } from 'components';

export const ChangePassword = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 4 }}>
      <HeaderText>{t('login.changePassword')}</HeaderText>
      <ChangePasswordForm />
    </Box>
  );
};

export default ChangePassword;
