export const findTargetIndexAlphabetically = (items: { title: string }[], comparedItem: { title: string }) => {
  const targetIndex = items.reduce((acc, val) => (val.title.localeCompare(comparedItem.title) < 0 ? acc + 1 : acc), 0);
  return targetIndex;
};

export const getAbsoluteTargetPosition = (
  relativeTargetPosition: number,
  selectedLength: number,
  disabledLength: number,
  itemHeight: number,
  checked: boolean,
) => {
  const absolutePosition = relativeTargetPosition + disabledLength * itemHeight;

  const finalAbsolutePosition = !checked ? absolutePosition : absolutePosition + selectedLength * itemHeight;

  return finalAbsolutePosition;
};
