import { type TFunction } from 'i18next';

const MAX_EXPORT_COUNT = 15000;

export const getLimitExportBlockCondition = (count: number, t: TFunction) => {
  return {
    if: count > MAX_EXPORT_COUNT,
    message: t('reportExport.limit', {
      count: MAX_EXPORT_COUNT,
    }),
  };
};
