import { memo } from 'react';
import { CreateNewFolder, Lock } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { type IGroupItem } from 'components/CheckboxGroup/GroupItem';

interface IAllNewProps<T> {
  item: IGroupItem<T & { selectAndAllParent: boolean }>;
  selectAllAndNew: (clientId: string, shouldCheck: boolean) => void;
  hovered: boolean;
  selectText: string;
  unselectText: string;
}

export const SelectAllAndNew = memo(function SelectAllAndNew<T>({
  item,
  selectAllAndNew,
  hovered,
  selectText,
  unselectText,
}: IAllNewProps<T>) {
  const { id, value, disabled } = item;

  if (disabled) {
    return null;
  }

  const { selectAndAllParent } = value;

  const show = hovered || item.value.selectAndAllParent;

  return (
    <Box
      sx={{
        width: show ? '30px' : '0px',
        height: '30px',
        backgroundColor: 'background.default',
        opacity: show ? 1 : 0,
        transition: '0.2s opacity, 0.2s width',
        boxShadow: !item.value.selectAndAllParent ? '0px 0px 10px rgba(0,0,0,0.1)' : 'unset',
      }}
    >
      {selectAndAllParent ? (
        <Tooltip title={unselectText} placement="right">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              selectAllAndNew(id, false);
            }}
            sx={{ borderRadius: 0 }}
            size="small"
          >
            <Lock fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={selectText} placement="right">
          <IconButton
            size="small"
            sx={{ borderRadius: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              selectAllAndNew(id, true);
            }}
          >
            <CreateNewFolder fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
});
