import { useTranslation } from 'react-i18next';
import { type TFilter } from 'components/ReportExport/types';
import { TableExport } from 'components/TableExport';
import { exportTranslations } from 'components/TableExport/translationMap';
import { buildNormalizedMappers } from 'components/TableExport/utils';
import { getLimitExportBlockCondition } from 'utils/limitExport';
import { GET_BETS_CSV, GET_BETS_XLSX } from 'views/PlayerBrowser/exportQueries';

export const BetsExport = ({ filter, totalCount }: { filter: TFilter; totalCount: number }) => {
  const { t } = useTranslation();

  return (
    <TableExport
      filter={{ filter }}
      blockConditions={[
        { if: !filter || totalCount === 0, message: t('reportExport.noDataToExport') },
        getLimitExportBlockCondition(totalCount, t),
      ]}
      columnMapperFn={buildNormalizedMappers(
        {
          normalizeCurrency: filter && (filter.baseCurrency as string),
          affectedFields: ['debitAmount', 'creditAmount', 'refundAmount'],
          prefixes: ['normalizedValues', 'values'],
          translations: exportTranslations,
          keyMap: {
            betTime: 'createdAt',
          },
        },
        t,
      )}
      queries={[
        {
          type: 'csv',
          query: GET_BETS_CSV,
          key: 'betsCsv',
          label: 'CSV',
        },
        {
          type: 'xlsx',
          query: GET_BETS_XLSX,
          key: 'betsXlsx',
          label: 'XLSX',
        },
      ]}
      normalizeCurrency={filter?.baseCurrency as string}
      currencyDate={filter?.currencyDate as string}
    />
  );
};
