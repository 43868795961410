import { ApolloClient, InMemoryCache } from '@apollo/client';
import { isAuthorizedAtom } from 'auth/authAtoms';
import { getDefaultStore } from 'jotai';

export const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
});

const store = getDefaultStore();
store.sub(isAuthorizedAtom, () => {
  const isAuthorized = store.get(isAuthorizedAtom);
  if (!isAuthorized) {
    client.resetStore();
  }
});
