import { Outlet } from 'react-router-dom';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { fetchUser } from 'auth';

export const PrivateRoute = () => {
  return (
    <SessionAuth
      onSessionExpired={() => {
        fetchUser();
      }}
    >
      <Outlet />
    </SessionAuth>
  );
};

export default PrivateRoute;
