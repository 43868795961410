import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FilterCard,
  HeaderText,
  type IButtonInputsFormAppliedFilter,
  type ISelectButtonInputState,
} from 'components';
import { type TFilter } from 'components/ReportExport/types';
import {
  playersMap,
  useButtonInputsFormWithDataGridAndDefaultQuery,
  useDefaultFiltersData,
  useLoading,
  useUserPreferences,
  type EPlayerFilter,
} from 'hooks';
import { parseButtonInputsFormToWhere } from 'utils';
import { GET_PLAYERS, parsePlayerBrowserData, type IPlayerBrowserRawData } from './parsePlayerBrowserData';
import PlayerBrowserFilters from './PlayerBrowserFilters';
import PlayerBrowserTable from './PlayerBrowserTable';

const parseFilterKeyMap: Record<string, string> = {
  games: 'slotId',
  win: 'creditTotal',
  bet: 'debitTotal',
  refund: 'refundTotal',
  currencies: 'currency',
  countries: 'country',
  clients: 'clientId',
  operators: 'operatorId',
  clusters: 'clusterId',
  betId: 'entityId',
  createdAt: 'betTime',
};

export const PlayerBrowser = () => {
  const { t, i18n } = useTranslation();
  const { userPreferences } = useUserPreferences();
  const { whereFilters, setWhereFilters, page, setPage, setSort, rawData, sort } =
    useButtonInputsFormWithDataGridAndDefaultQuery<IPlayerBrowserRawData>({
      GQL: GET_PLAYERS,
      loadingKey: 'fetchPlayerBrowserData',
      keepData: true,
      defaultSort: {
        field: 'lastTransactionDate',
        sort: 'desc',
      },
    });
  const { filtersData } = useDefaultFiltersData('fetchPlayerBrowserFilters');

  const { loading } = useLoading();

  const { data, totalCount, summary } = useMemo(() => {
    if (rawData) {
      return parsePlayerBrowserData(rawData, i18n.language, userPreferences.timezone, whereFilters?.baseCurrency);
    }
    return { data: [], totalCount: -1, summary: {} };
  }, [rawData, i18n.language, userPreferences.timezone]);

  const handleAcceptFilters = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = filters.map((filter) => {
      if (filter.key === 'tester') {
        const state = filter.state as ISelectButtonInputState;
        return {
          ...filter,
          state: {
            value: playersMap[state.value as EPlayerFilter] as unknown as string,
          },
        };
      }
      return filter;
    });

    const whereFilters = parseButtonInputsFormToWhere(mappedFilters, parseFilterKeyMap);

    setWhereFilters(whereFilters);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <HeaderText>{t('playerBrowser.title')}</HeaderText>
      <FilterCard sx={{ alignSelf: 'flex-start' }}>
        <PlayerBrowserFilters onAccept={handleAcceptFilters} selectOptions={filtersData} loading={loading} />
      </FilterCard>
      <PlayerBrowserTable
        data={data}
        rawData={rawData!}
        page={page}
        setPage={setPage}
        setSort={setSort}
        totalCount={totalCount}
        sort={sort}
        filter={whereFilters as TFilter}
        summary={summary}
        filterOptions={filtersData}
      />
    </Box>
  );
};

export default PlayerBrowser;
