import { type TPerm } from 'auth';
import Layout from 'components/Layout';
import AccountingReport from 'views/AccountingReport';
import BetBrowser from 'views/BetBrowser';
import ChangePassword from 'views/ChangePassword';
import { Dashboard } from 'views/Dashboard';
import NotFound from 'views/NotFound';
import PlayerBrowser from 'views/PlayerBrowser';
import SignIn from 'views/SignIn';
import TeamMembers from 'views/TeamMembers';
import { TeamMemberUser } from 'views/TeamMembersUser';
import PrivateRoute from './PrivateRoute';
import { ERoute, type TRoutes } from './routeTypes';

export const getRoutesTemplate = (perms: TPerm): TRoutes => {
  return [
    {
      element: <Layout />,
      enabled: true,
      children: [
        { element: <SignIn />, path: ERoute.SignIn, enabled: true },
        {
          element: <PrivateRoute />,
          enabled: true,
          children: [
            { element: <Dashboard />, path: ERoute.Default, enabled: true },
            {
              element: <AccountingReport />,
              path: ERoute.AccountingReport,
              enabled: perms.reportsAccounting,
              title: 'menu.Accounting',
              menuSection: 'menu.Reports',
            },
            {
              element: <PlayerBrowser />,
              path: ERoute.PlayerBrowser,
              enabled: perms.reportsPlayerBrowser,
              title: 'menu.PlayerBrowser',
              menuSection: 'menu.Reports',
            },
            {
              element: <BetBrowser />,
              path: ERoute.BetBrowser,
              enabled: perms.reportsBets,
              title: 'menu.BetsBrowser',
              menuSection: 'menu.Reports',
            },
            {
              element: <TeamMembers />,
              path: ERoute.ManagementTeamMembers,
              enabled: perms.team,
              title: 'menu.Users',
              menuSection: 'menu.Management',
            },
            {
              element: <TeamMemberUser />,
              path: ERoute.ManagementTeamMembersDetails,
              enabled: perms.team,
              title: 'menu.UserDetails',
            },
            { element: <TeamMemberUser />, path: ERoute.ManagementTeamMembersAdd, enabled: perms.team },
            { element: <ChangePassword />, path: ERoute.ChangePassword, enabled: true },
            {
              element: <NotFound />,
              path: '*',
              enabled: true,
            },
          ],
        },
      ],
    },
  ];
};
