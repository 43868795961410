import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSetAtom } from 'jotai';
import { useSetLoading } from 'hooks';
import { buildGroupValues } from './accessesFeature/accessBuildGroups';
import { createAccessGroups } from './accessesFeature/groupsAtom';
import { type IScope } from './accessesFeature/permissionMapping';
import { GROUP_LIST } from './accessesFeature/queryGroups';

export const useMemberAtoms = ({ scopes = [] }: { scopes: IScope[] }) => {
  const { startLoading, endLoading } = useSetLoading();
  const query = useQuery(GROUP_LIST, {
    onCompleted: () => endLoading('userMemberGetFilters'),
  });

  useEffect(() => startLoading('userMemberGetFilters'), []);

  const { clusterGroup, clientGroup, operatorGroup } = useMemo(() => createAccessGroups(), []);

  const setClusters = useSetAtom(clusterGroup.set);
  const setClients = useSetAtom(clientGroup.set);
  const setOperators = useSetAtom(operatorGroup.set);

  useEffect(() => {
    if (query.data) {
      try {
        const { clusters, clients, operators } = buildGroupValues(query.data);

        setClusters(clusters as never, scopes);
        setClients(clients as never, scopes);
        setOperators(operators, scopes);
      } catch (e) {
        console.error(e);
      }
    }
  }, [query.data, scopes]);

  return {
    clusterAtomGroup: clusterGroup,
    clientAtomGroup: clientGroup,
    operatorAtomGroup: operatorGroup,
    query,
  };
};
