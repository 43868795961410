import { useEffect, useMemo, type Dispatch, type MutableRefObject, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { type GridSortModel } from '@mui/x-data-grid';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';
import { DataGridBox, DataGridPagination, DataGridToolbar, DefaultDataGrid } from 'components';
import { type TFilter } from 'components/ReportExport/types';
import { useLoading, usePerms } from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import { useGenerateSummary } from 'hooks/useGenerateSummary';
import { parseCurrencyNumber } from 'utils';
import GridFooterSummary from 'views/AccountingReport/AccountingReportTable/GridFooterSummary';
import { BetsExport } from './BetExport';
import { generateColumns, type TPlayerBrowserDetailField } from './betsGenerateColumn';

export interface IBetsSummary {
  creditTotalSummary: number;
  debitTotalSummary: number;
  refundTotalSummary: number;
}

interface IProps {
  page: number;
  setPage: (page: number) => void;
  setSort: (sort: GridSortModel) => void;
  sort: GridSortModel;
  data: object[];
  totalCount: number;
  selectedBetId: string | number | null;
  setSelectedBetId: Dispatch<SetStateAction<string | number | null>>;
  filter: TFilter;
  summary: IBetsSummary | object;
  gridRef: MutableRefObject<GridApiCommunity>;
}
export const BetsBrowserTable = ({
  setSelectedBetId,
  selectedBetId,
  sort,
  setSort,
  totalCount,
  page,
  setPage,
  data,
  filter,
  summary,
  gridRef,
}: IProps) => {
  const { t, i18n } = useTranslation();

  const { loading } = useLoading();

  const perms = usePerms();

  const columns = useMemo(() => generateColumns(t, perms), [i18n.language, perms]);

  const normalizeCurrency = filter?.baseCurrency as string;

  const footerSummary = useGenerateSummary<TPlayerBrowserDetailField, IBetsSummary>(summary as IBetsSummary, {
    grid: gridRef.current,
    parser: ({ creditTotalSummary, debitTotalSummary, refundTotalSummary }) => {
      return {
        debitAmount: parseCurrencyNumber(debitTotalSummary, normalizeCurrency),
        creditAmount: parseCurrencyNumber(creditTotalSummary, normalizeCurrency),
        refundAmount: parseCurrencyNumber(refundTotalSummary, normalizeCurrency),
      };
    },
  });

  const { height } = useComputedGridHeight({ apiRef: gridRef, additionalRowCount: normalizeCurrency ? 1 : 0 });

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const nextRowIndexDiff = e.key === 'ArrowDown' ? 1 : e.key === 'ArrowUp' ? -1 : 0;
      if (nextRowIndexDiff) {
        setSelectedBetId((currentId) => {
          if (currentId) {
            const selectedRowIndex = gridRef.current?.getRowIndexRelativeToVisibleRows(currentId);
            const newRowId = gridRef.current?.getRowIdFromRowIndex(selectedRowIndex + nextRowIndexDiff);
            if (newRowId) {
              return newRowId;
            }
          }
          return currentId;
        });
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        apiRef={gridRef}
        loading={loading}
        sortModel={sort}
        onSortModelChange={setSort}
        onRowSelectionModelChange={(e) => setSelectedBetId((currentId) => (currentId === e[0] ? null : e[0]))}
        rowSelectionModel={selectedBetId ? [selectedBetId] : []}
        disableRowSelectionOnClick={false}
        slots={{
          toolbar: () => (
            <DataGridToolbar localStorageKey="player-browser-details">
              <BetsExport filter={filter} totalCount={totalCount} />
            </DataGridToolbar>
          ),
          pagination: () => <DataGridPagination count={totalCount} page={page} onPageChange={setPage} />,

          footer: normalizeCurrency
            ? () => (
                <GridFooterSummary page={page} setPage={setPage} totalDataCount={totalCount} summary={footerSummary} />
              )
            : undefined,
        }}
      />
    </DataGridBox>
  );
};

export default BetsBrowserTable;
