export type TMenuParentSection = 'menu.Reports' | 'menu.Management';

export type TRoute = {
  path?: string;
  element: JSX.Element;
  children?: TRoute[];
  menuSection?: TMenuParentSection;
  showInMenu?: boolean;
  enabled: boolean;
  title?: string;
};

export type TRoutes = TRoute[];

export enum ERoute {
  Default = '/',
  SignIn = '/sign/in',
  AccountingReport = '/report/accounting',
  PlayerBrowser = '/report/players',
  BetBrowser = '/report/bets',
  ManagementTeamMembers = '/management/users',
  ManagementTeamMembersDetails = '/management/users/:id',
  ManagementTeamMembersAdd = '/management/users/add',
  ChangePassword = '/me/change-password',
}
