import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import { useAtom } from 'jotai';
import { FormControl, InputLabel, MenuItem } from 'components';
import { EPlayerFilter, playersAtom } from 'hooks/useAccountingFilters';

export const AccountingReportFilterTesters = () => {
  const { t } = useTranslation();
  const [players, setPlayers] = useAtom(playersAtom);

  return (
    <FormControl>
      <InputLabel size="small">{t('accounting.Players')}</InputLabel>
      <Select
        size="small"
        value={players}
        label={t('accounting.Players')}
        onChange={(evt) => setPlayers(evt.target.value as EPlayerFilter)}
      >
        <MenuItem value={EPlayerFilter.ALL}>{t('accounting.allPlayers')}</MenuItem>
        <MenuItem value={EPlayerFilter.REAL_ONLY}>{t('accounting.realPlayersOnly')}</MenuItem>
        <MenuItem value={EPlayerFilter.TESTERS_ONLY}>{t('accounting.testersPlayersOnly')}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AccountingReportFilterTesters;
