import { type PropsWithChildren } from 'react';
import { Box, type SxProps } from '@mui/material';

interface IProps extends PropsWithChildren {
  sx?: SxProps;
  height?: string | number;
  width?: string | number;
}

export const DataGridBox = ({ children, sx, height, width }: IProps) => {
  const widthStyle = width || 'calc(100vw - 80px - 24px)';

  return (
    <Box
      sx={[
        (theme) => ({
          width: widthStyle,
          height,
          position: 'relative',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
        {
          '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-columnHeaderTitle, .MuiDataGrid-menuIcon button, .MuiDataGrid-iconButtonContainer button': {
            fontWeight: 'bold',
            color: 'white',
          },
          '.MuiDataGrid-columnSeparator': {
            '&:hover': {
              color: 'white',
            },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export default DataGridBox;
