import { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery, type DocumentNode, type OperationVariables } from '@apollo/client';
import { type GridSortModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { atom, useAtom, useAtomValue, type PrimitiveAtom } from 'jotai';
import { dataGridRowsPerPageAtom } from 'components';
import { getFilterTableKey } from 'components/TableExport/translationMap';
import { useSetLoading } from 'hooks/useLoading';
import { showError } from 'utils';
import { parseWhereFilter } from 'utils/filterUtils';
import { useUserPreferences } from './useUserPreferences';

type TDefaultSort = {
  field: string;
  sort: 'asc' | 'desc';
};

interface IProps {
  GQL: DocumentNode;
  loadingKey: string;
  fetchVariables?: OperationVariables;
  keepData?: boolean;
  shouldShowError?: (err: Error) => boolean;
  defaultSort?: TDefaultSort;
}

interface IAtoms<RawData = unknown> {
  page: PrimitiveAtom<number>;
  sort: PrimitiveAtom<GridSortModel>;
  rawData: PrimitiveAtom<RawData | null>;
  whereFilters: PrimitiveAtom<Record<string, unknown> | null>;
}

const makeAtoms = (defaultSort?: TDefaultSort) =>
  ({
    page: atom(0),
    sort: atom(defaultSort ? [defaultSort] : ([] as GridSortModel)),
    rawData: atom(null),
    whereFilters: atom(null),
  }) as IAtoms;

const keepDataAtoms: Record<string, IAtoms> = {};

export const useButtonInputsFormWithDataGridAndDefaultQuery = <TRawData = unknown>({
  GQL,
  loadingKey,
  fetchVariables = {},
  keepData,
  shouldShowError,
  defaultSort,
}: IProps) => {
  const { startLoading, endLoading } = useSetLoading();
  const rowsPerPage = useAtomValue(dataGridRowsPerPageAtom);
  const [fetchData, { error, loading }] = useLazyQuery<TRawData>(GQL);

  const atoms = useMemo(() => {
    if (keepData) {
      if (!keepDataAtoms[loadingKey]) {
        keepDataAtoms[loadingKey] = makeAtoms(defaultSort);
      }
      return keepDataAtoms[loadingKey] as IAtoms<TRawData>;
    }
    return makeAtoms(defaultSort) as IAtoms<TRawData>;
  }, []);

  const [whereFilters, setWhereFilters] = useAtom(atoms.whereFilters);
  const [rawData, setRawData] = useAtom(atoms.rawData);
  const [sort, setSort] = useAtom(atoms.sort);
  const [page, setPage] = useAtom(atoms.page);

  const {
    userPreferences: { timezone },
  } = useUserPreferences();

  const setSortAndClearPage = useCallback((...params: Parameters<typeof setSort>) => {
    setPage(0);
    setSort(...params);
  }, []);

  const setWhereFiltersAndClearPage = useCallback((...params: Parameters<typeof setWhereFilters>) => {
    setPage(0);

    setWhereFilters(...params);
  }, []);

  useEffect(() => {
    if (whereFilters) {
      startLoading(loadingKey);

      const baseCurrency = whereFilters.baseCurrency;

      const orderBy = sort.length > 0 ? getFilterTableKey(sort[0].field, !!baseCurrency) : null;

      const currencyDateUpdated = dayjs(whereFilters?.currencyDate as string)
        .tz(timezone)
        .hour(12)
        .minute(0)
        .second(0)
        .utc()
        .format();

      fetchData({
        variables: {
          where: parseWhereFilter(whereFilters, {
            removedKeys: ['baseCurrency', 'currencyDate'],
            normalized: !!whereFilters.baseCurrency,
          }),
          skip: page * rowsPerPage,
          take: rowsPerPage,
          ...(orderBy && { orderBy }),
          orderDirection: sort[0]?.sort,
          normalized: whereFilters.baseCurrency !== undefined,
          ...(whereFilters.baseCurrency !== undefined && {
            normalization: {
              baseCurrency: whereFilters.baseCurrency,
              ...(currencyDateUpdated && { currencyDate: currencyDateUpdated }),
            },
          }),
          ...fetchVariables,
        },
      })
        .then((response) => {
          endLoading(loadingKey);
          if (response.data) {
            setRawData(response.data);
          } else if (response.error) {
            if (shouldShowError && !shouldShowError(response.error)) {
              return;
            }
            showError(response.error);
          }
        })
        .catch((err) => {
          endLoading(loadingKey);
          if (shouldShowError && !shouldShowError(err)) {
            return;
          }
          showError(err);
        });
    }
  }, [whereFilters, page, rowsPerPage, sort]);

  return {
    whereFilters,
    setWhereFilters: setWhereFiltersAndClearPage,
    setSort: setSortAndClearPage,
    setPage,
    sort,
    page,
    rawData,
    error,
    loading,
  };
};
