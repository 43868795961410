import { useCallback, useEffect, useRef } from 'react';
import { atom, useAtomValue, useSetAtom } from 'jotai';

export const loadingAtom = atom(false);
export const loadingSet = new Set<string>();

export const useSetLoading = () => {
  const keyRef = useRef<string>('');

  const setLoading = useSetAtom(loadingAtom);
  const startLoading = useCallback((uniqueKey: string) => {
    setLoading(true);
    keyRef.current = uniqueKey;
    loadingSet.add(uniqueKey);
  }, []);
  const endLoading = useCallback((uniqueKey: string) => {
    loadingSet.delete(uniqueKey);
    if (loadingSet.size === 0) {
      setLoading(false);
    }
  }, []);

  // ends loading on unmount
  useEffect(
    () => () => {
      if (keyRef.current) {
        endLoading(keyRef.current);
      }
    },
    [],
  );

  return {
    startLoading,
    endLoading,
  };
};

export const useLoading = () => {
  const isLoading = useAtomValue(loadingAtom);
  const loadingActions = useSetLoading();

  return { loading: isLoading, ...loadingActions };
};
