import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box, Button, Dialog, ListItemText, Paper, Tooltip, Typography } from 'components';
import { EUserStatus, type IUser } from 'hooks';

interface IProps {
  data: IUser[];
  onConfirm: () => void;
  onClose: () => void;
  usersToDelete: string[];
  open: boolean;
}

export const TeamMembersApproveDelete = ({ usersToDelete, onConfirm, onClose, data, open }: IProps) => {
  const { t } = useTranslation();

  const { users, canRemoveUser } = useMemo(() => {
    const users = usersToDelete.map((id) => data.find((el) => el.id === id)).filter(Boolean) as IUser[];
    const canRemoveUser = users.every(
      (user) => user.status === EUserStatus.PENDING || user.status === EUserStatus.INACTIVE,
    );

    return { users, canRemoveUser };
  }, [data, usersToDelete]);

  return (
    <Dialog onClose={onClose} open={open} PaperProps={{ sx: { gap: 2, p: 2, maxWidth: 500 } }}>
      <Typography variant="h5" textAlign="center">
        {t('team.areYouSureDelete')}
      </Typography>
      <Paper
        sx={{ maxHeight: '60dvh', overflow: 'auto', backgroundColor: 'action.hover', p: 1 }}
        variant="outlined"
        square
      >
        {users.map((user) => {
          return (
            <ListItemText
              key={user.id}
              primary={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Tooltip title={user.status === EUserStatus.INACTIVE ? t('team.inactive') : t('team.active')}>
                    {user.status === EUserStatus.INACTIVE ? (
                      <PersonOffIcon color="info" />
                    ) : (
                      <PersonIcon color="success" />
                    )}
                  </Tooltip>
                  {user.email}
                </Box>
              }
              disableTypography
            />
          );
        })}
      </Paper>

      {!canRemoveUser && (
        <Typography color="error" textAlign="center">
          {t('team.youCanDeleteOnlyDeactive')}
        </Typography>
      )}

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button onClick={onConfirm} color="error" sx={{ width: '50%' }} disabled={!canRemoveUser}>
          {t('base.delete')}
        </Button>
        <Button onClick={onClose} variant="outlined" sx={{ width: '50%' }}>
          {t('base.close')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default TeamMembersApproveDelete;
