import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { GridCell, type GridSortModel } from '@mui/x-data-grid';
import { useSetAtom } from 'jotai';
import {
  DataGridBox,
  DataGridPagination,
  DataGridToolbar,
  DefaultDataGrid,
  menuCellAtom,
  TableContextMenu,
} from 'components';
import { type TFilter } from 'components/ReportExport/types';
import { useLoading, usePerms, type IDefaultFiltersData } from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import { useGenerateSummary } from 'hooks/useGenerateSummary';
import { useGrid } from 'hooks/useGrid';
import { ERoute } from 'routing/routeTypes';
import { parseBigNumber, parseCurrencyNumber } from 'utils';
import GridFooterSummary from 'views/AccountingReport/AccountingReportTable/GridFooterSummary';
import { parseFilterToURLParam } from './parseFilterBetRedirect';
import { type IPlayerBrowserRawData, type TParsedPlayerBrowserData } from './parsePlayerBrowserData';
import { PlayerBrowserExport } from './PlayerBrowserExport';
import { generateColumns } from './playerBrowserGenerateColumns';

export interface ICombinedTransactionsByPlayersSummary {
  creditTotalSummary: number;
  debitTotalSummary: number;
  refundTotalSummary: number;
  totalBuyBetSummary: number;
  totalBuyCountSummary: number;
  totalSpinCountSummary: number;
}

interface IProps {
  page: number;
  setPage: (page: number) => void;
  setSort: (sort: GridSortModel) => void;
  sort: GridSortModel;
  data: TParsedPlayerBrowserData['data'];
  totalCount: number;
  filter: TFilter;
  summary: ICombinedTransactionsByPlayersSummary | object;
  rawData: IPlayerBrowserRawData;
  filterOptions: IDefaultFiltersData | null;
}

export const PlayerBrowserTable = ({
  setSort,
  page,
  setPage,
  data,
  totalCount,
  sort,
  filter,
  summary,
  rawData,
  filterOptions,
}: IProps) => {
  const { gridRef, gridState } = useGrid();
  const perms = usePerms();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { loading } = useLoading();
  const setMenuCell = useSetAtom(menuCellAtom);

  const navigateToPlayer = useCallback(
    (id: string, newTab?: boolean) => {
      const currentFilters = JSON.parse(new URLSearchParams(window.location.search).get('filters') || '') as unknown[];

      const transactions = rawData.combinedTransactionsByPlayers?.items!;
      const {
        currency,
        country,
        playerExternalId,
        playerId,
        playerInternalId,
        clientLabel,
        operatorLabel,
        clusterLabel,
      } = transactions.find((player) => player.playerId === id)!;

      const cluster = filterOptions?.clusters.find((cluster) => cluster.name === clusterLabel);

      const client = filterOptions?.clients.find(
        (client) => client.parentId === cluster?.id && (client.name || client.id) === clientLabel,
      );

      const operator = filterOptions?.operators.find(
        (operator) => operator.parentId === client?.id && (operator.name || operator.id) === operatorLabel,
      );

      const parsedParams = parseFilterToURLParam(
        {
          countries: country,
          currencies: currency,
          playerExternalId,
          clusters: cluster?.id!,
          clients: client?.id!,
          operators: operator?.id!,
          playerInternalId,
          playerId,
        },
        currentFilters,
      );

      const path = generatePath(ERoute.BetBrowser, {
        id,
      });

      const finalPath = `${path}?filters=${encodeURIComponent(JSON.stringify(parsedParams))}&acceptOnInit=true`;

      newTab ? window.open(finalPath, '_blank') : navigate(finalPath);
    },
    [rawData, filter, navigate, filterOptions],
  );
  const normalizeCurrency = filter?.baseCurrency as string;

  const columns = useMemo(() => [...generateColumns(t, perms)], [i18n.language, perms]);

  const footerSummary = useGenerateSummary(summary as ICombinedTransactionsByPlayersSummary, {
    grid: gridRef.current,
    parser: ({
      creditTotalSummary,
      debitTotalSummary,
      totalBuyCountSummary,
      totalSpinCountSummary,
      refundTotalSummary,
    }) => {
      return {
        debitTotal: parseCurrencyNumber(debitTotalSummary, normalizeCurrency),
        creditTotal: parseCurrencyNumber(creditTotalSummary, normalizeCurrency),
        refundTotal: parseCurrencyNumber(refundTotalSummary, normalizeCurrency),
        totalBuyCount: parseBigNumber(totalBuyCountSummary),
        totalSpinCount: parseBigNumber(totalSpinCountSummary),
      };
    },
  });

  const { height } = useComputedGridHeight({ apiRef: gridRef, additionalRowCount: normalizeCurrency ? 1 : 0 });

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        apiRef={gridRef}
        loading={loading}
        sortModel={sort}
        onSortModelChange={setSort}
        slots={{
          toolbar: () => (
            <DataGridToolbar
              localStorageKey="player-browser"
              defaultHideColumns={['tester', 'firstTransactionDate', 'playerId', 'playerInternalId']}
            >
              <PlayerBrowserExport filter={filter} totalCount={totalCount} />
            </DataGridToolbar>
          ),
          pagination: () => <DataGridPagination count={totalCount} page={page} onPageChange={setPage} />,
          cell: (props) => {
            return (
              <GridCell
                {...props}
                onContextMenu={(evt: MouseEvent) => {
                  evt.preventDefault();
                  evt.stopPropagation();

                  // eslint-disable-next-line react/prop-types
                  setMenuCell({ rowId: props.rowId, column: props.column });
                }}
              />
            );
          },

          footer: normalizeCurrency
            ? () => (
                <GridFooterSummary page={page} setPage={setPage} totalDataCount={totalCount} summary={footerSummary} />
              )
            : undefined,
        }}
      />
      {gridState.visibleColumns.length > 0 && (
        <TableContextMenu apiRef={gridRef} navigateToDetails={navigateToPlayer} detailsFieldId="playerId" />
      )}
    </DataGridBox>
  );
};

export default PlayerBrowserTable;
