import { useEffect, useState } from 'react';
import { useSetAtom, useStore } from 'jotai';
import { appliedFiltersAtom } from 'hooks/useAccountingData';
import {
  clientsAtoms,
  clustersAtoms,
  countriesAtoms,
  currenciesAtoms,
  currencyDateAtom,
  dateFromAtom,
  dateToAtom,
  gamesAtoms,
  normalizeCurrencyAtom,
  normalizeEnabledAtom,
  normalizeGroupByAtom,
  operatorsAtoms,
  playersAtom,
  timeZoneAtom,
  type TFilterAtoms,
} from './accountingFiltersAtoms';

type TFiltersData = { title: string; atoms: TFilterAtoms }[];

export const useAccountingFiltersValidate = (filters: TFiltersData) => {
  const setAppliedFilters = useSetAtom(appliedFiltersAtom);

  const store = useStore();

  const [, setRerender] = useState(false);

  const filterAtoms = filters.map((filter) => ({ ...filter.atoms, value: store.get(filter.atoms.data) }));

  useEffect(() => {
    const subs = filters.map((filter) =>
      store.sub(filter.atoms.selected, () => {
        setRerender((val) => !val);
      }),
    );

    return () => subs.forEach((sub) => sub());
  }, [filters]);

  useEffect(() => {
    filterAtoms.forEach((filterAtom) => {
      const isError = store.get(filterAtom.error);
      if (isError) {
        const errorSelected = store.get(filterAtom.selected) as string[];
        store.set(filterAtom.error, errorSelected.length === 0);
      }
    });
  });

  const validate = (): boolean => {
    const passed = filterAtoms.reduce((acc, filterAtom) => {
      const errorData = store.get(filterAtom.data);
      const errorSelected = store.get(filterAtom.selected);
      const isValid = errorData ? errorSelected.length > 0 : true;

      store.set(filterAtom.error, !isValid);

      if (acc && isValid) {
        return true;
      }
      return false;
    }, true);

    if (passed) {
      const values = {
        clusters: store.get(clustersAtoms.selected),
        clients: store.get(clientsAtoms.selected),
        operators: store.get(operatorsAtoms.selected),
        games: store.get(gamesAtoms.selected),
        currencies: store.get(currenciesAtoms.selected),
        countries: store.get(countriesAtoms.selected),
        normalizeEnabled: store.get(normalizeEnabledAtom),
        normalizeCurrency: store.get(normalizeCurrencyAtom),
        normalizeGroupBy: store.get(normalizeGroupByAtom),
        currencyDate: store.get(currencyDateAtom),
        dateFrom: store.get(dateFromAtom),
        dateTo: store.get(dateToAtom),
        timezone: store.get(timeZoneAtom),
        players: store.get(playersAtom),
      };
      localStorage.setItem('accountingFilters', JSON.stringify(values));
      setAppliedFilters(values);
    }

    return passed;
  };

  const isValidationFailed = filterAtoms.some((filter) => store.get(filter.selected).length === 0);

  return {
    isError: isValidationFailed,
    validate,
  };
};

export default useAccountingFiltersValidate;
