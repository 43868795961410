import { useMemo } from 'react';
import { usePerms } from 'hooks/usePerms';
import { generateMenuData } from './generateMenuData';

export const useMenuItems = () => {
  const perms = usePerms();

  const sections = useMemo(() => generateMenuData(perms), [perms]);

  const menuRoutes = useMemo(
    () =>
      sections
        .map((section) => section.routes)
        .flat()
        .map((route) => route.route),
    [sections],
  );

  return { sections, menuRoutes };
};
