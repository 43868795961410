import { useEffect, useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid';
import { type GridStateColDef } from '@mui/x-data-grid/internals';

export const useGrid = () => {
  const gridRef = useGridApiRef();
  const [gridState, setGridState] = useState<{
    visibleColumns: GridStateColDef[];
  }>({ visibleColumns: [] });

  const update = () => setGridState((state) => ({ ...state, visibleColumns: gridRef.current.getVisibleColumns() }));

  useEffect(() => {
    const unsubColumnVisibility = gridRef.current.subscribeEvent('columnVisibilityModelChange', update);
    update();
    return () => unsubColumnVisibility();
  }, [gridRef]);

  return { gridState, gridRef };
};
