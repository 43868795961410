import { useMemo, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ArrowDropDown, ChevronRight } from '@mui/icons-material';
import { Box, Collapse, Fade, MenuItem as MUIMenuItem, Typography } from '@mui/material';
import { useSidemenu } from 'hooks/useSideMenu';
import { useTheme } from 'hooks/useTheme';
import { type TMenuItem, type TMenuSection } from './generateMenuData';

const isActiveWithin = (items: TMenuItem[], pathname: string) => {
  for (const item of items) {
    if (pathname === item.route) {
      return true;
    }
  }

  return false;
};

export const MenuSection: FC<TMenuSection> = ({ icon, title, routes }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { menuOpen } = useSidemenu();
  const [expanded, setExpanded] = useState(true);

  const { theme } = useTheme();

  const toggleExpanded = () => setExpanded((expanded) => !expanded);

  const shouldHighlight = useMemo(() => {
    const hasActiveItem = isActiveWithin(routes, pathname);
    return hasActiveItem && !menuOpen;
  }, [pathname, menuOpen]);

  if (routes.length === 0) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, pl: 0.5 }}>
      <MUIMenuItem
        onClick={toggleExpanded}
        selected={shouldHighlight}
        sx={{
          transition: 'background-color 0.3s',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, width: '100%' }}>
          {icon}

          <Fade in={menuOpen}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              {t(title)}

              <ArrowDropDown
                sx={(theme) => ({
                  marginLeft: 'auto',
                  transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.short,
                  }),
                  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                })}
              />
            </Box>
          </Fade>
        </Box>
      </MUIMenuItem>
      <Collapse in={menuOpen && expanded}>
        <Box sx={{ pt: 0.5 }}>
          {routes.map((route) => {
            return (
              <Box key={route.route}>
                <Box sx={{ display: 'flex' }} key={route.route}>
                  <Link
                    to={route.route}
                    style={{ width: '100%', textDecoration: 'none', color: theme.palette.text.primary }}
                    unstable_viewTransition={true}
                  >
                    <MUIMenuItem
                      selected={pathname === route.route}
                      sx={{ width: '100%', pl: 6, borderRadius: 1, transition: 'background-color 0.3s' }}
                    >
                      <Typography variant="body2">{t(route.title)}</Typography>{' '}
                      <ChevronRight fontSize="small" sx={{ marginLeft: 'auto', opacity: 0.2 }} />
                    </MUIMenuItem>
                  </Link>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Box sx={{ mt: 2 }}></Box>
      </Collapse>
    </Box>
  );
};
