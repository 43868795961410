import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type TPerm } from 'auth';
import dayjs from 'dayjs';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter } from 'components';
import { EPlayerFilter, useButtonInputsFormQueryParams, usePerms, type IDefaultFiltersData } from 'hooks';
import { mapButtonInputsToCurrency } from 'utils';
import { getRoundTypeOptions } from 'utils/roundTypes';

const generateInputsData = (perms: TPerm) => {
  const data = [
    perms.clusters && { type: 'multiselect', key: 'clusters', translateKey: 'playerBrowser.clusters' },
    perms.clients && { type: 'multiselect', key: 'clients', translateKey: 'playerBrowser.clients' },
    perms.operators && { type: 'multiselect', key: 'operators', translateKey: 'playerBrowser.operators' },
    { type: 'uuid', key: 'playerId', translateKey: 'playerBrowser.playerId' },
    { type: 'string', key: 'playerExternalId', translateKey: 'playerBrowser.playerExternalId' },
    { type: 'uuid', key: 'entityId', translateKey: 'playerBrowser.betId' },
    { type: 'uuid', key: 'playerInternalId', translateKey: 'playerBrowser.playerInternalId' },
    { type: 'multiselect', key: 'games', translateKey: 'playerBrowser.games' },
    { type: 'number', key: 'bet', translateKey: 'playerBrowser.betValue' },
    { type: 'number', key: 'win', translateKey: 'playerBrowser.winValue' },
    { type: 'number', key: 'refund', translateKey: 'playerBrowser.refundValue' },
    {
      type: 'date',
      key: 'createdAt',
      translateKey: 'playerBrowser.betTime',
      config: {
        defaultValue: {
          operator: 'greater',
          value: dayjs().subtract(7, 'days').format(),
          value2: '',
        },
      },
    },
    { type: 'multiselect', key: 'roundType', translateKey: 'playerBrowser.betType' },
    { type: 'multiselect', key: 'currencies', translateKey: 'playerBrowser.currencies' },
    { type: 'multiselect', key: 'countries', translateKey: 'playerBrowser.countries' },
    {
      type: 'currencyNormalization',
      key: 'currencyNormalization',
      translateKey: 'playerBrowser.currencyNormalization',
      config: {
        selected: 'NONE',
      },
    },
    {
      type: 'select',
      key: 'tester',
      translateKey: 'accounting.Players',
      config: {
        defaultValue: {
          value: EPlayerFilter.REAL_ONLY,
        },
      },
    },
  ];
  return data;
};

const initialShowingFilters = [
  'clusters',
  'clients',
  'operators',
  'playerExternalId',
  'entityId',
  'games',
  'bet',
  'win',
  'refund',
  'createdAt',
  'roundType',
  'currencyNormalization',
  'countries',
  'tester',
];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
  filtersData: IDefaultFiltersData | null;
  loading: boolean;
  acceptOnInit?: boolean;
}

export const BetsFilters = ({ onAccept, filtersData, loading, acceptOnInit }: IProps) => {
  const { t } = useTranslation();
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search, {
    parseParam: {
      bet: (state) => ({ ...state, ...(state.value && { value: state.value / 100 }) }),
      win: (state) => ({ ...state, ...(state.value && { value: state.value / 100 }) }),
      refund: (state) => ({ ...state, ...(state.value && { value: state.value / 100 }) }),
    },
  });
  const [showingInputs, setShowingInputs] = useState(() => {
    const showingInputs = [...initialShowingFilters];
    Object.keys(initialState).forEach((key) => {
      if (!showingInputs.includes(key)) {
        showingInputs.push(key);
      }
    });
    return showingInputs;
  });

  const perms = usePerms();

  const inputsData = useMemo(() => generateInputsData(perms), [perms]);

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = mapButtonInputsToCurrency(filters, ['bet', 'win']);
    setQueryParams(mappedFilters);
    onAccept(mappedFilters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
      <ButtonInputForm
        inputsData={inputsData as never}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          ...filtersData,
          roundType: getRoundTypeOptions(t),
          tester: [
            { name: t('accounting.testersPlayersOnly'), id: EPlayerFilter.TESTERS_ONLY },
            { name: t('accounting.realPlayersOnly'), id: EPlayerFilter.REAL_ONLY },
            { name: t('accounting.allPlayers'), id: EPlayerFilter.ALL },
          ],
        }}
        onAccept={handleAccept}
        acceptOnInit={acceptOnInit}
      />
    </Box>
  );
};

export default BetsFilters;
