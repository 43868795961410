import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { type GridRowSelectionModel } from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import {
  Box,
  Button,
  Collapse,
  FilterButton,
  FilterCard,
  HeaderText,
  SearchInput,
  type IButtonInputsFormAppliedFilter,
} from 'components';
import { EUserRole, EUsersBulkAction, useUser, useUserManagement, useUserPreferences, type IUser } from 'hooks';
import { ERoute } from 'routing/routeTypes';
import { parseDateStringTz, validateButtonInput } from 'utils';
import { atomWithAuthReset } from 'utils/atomWithAuthReset';
import { mapScopesToLists } from 'views/TeamMembersUser/accessesFeature/accessGroupAtomHelpers';
import TeamMembersApproveDelete from './TeamMembersApproveDelete';
import TeamMembersFilters from './TeamMembersFilters';
import TeamMembersTable, { statusTranslateKeyMap } from './TeamMembersTable';

export const userRoleMap = {
  [EUserRole.ACCOUNT_MANAGER]: 'team.accountManager',
  [EUserRole.ADMIN]: 'team.admin',
  [EUserRole.AREA_ADMIN]: 'team.areaAdmin',
  [EUserRole.SUPPORT]: 'team.support',
};

const showFiltersAtom = atomWithStorage('teamMembersShowFilters', false, undefined, { getOnInit: true });
const searchAtom = atomWithAuthReset('');

export const Team = () => {
  const { t, i18n } = useTranslation();
  const { handleUsersBulkAction, rawData } = useUserManagement();
  const navigate = useNavigate();
  const currentUser = useUser();
  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [deleteConfirm, setDeleteConfirm] = useState({ open: false, usersToDelete: [] as string[] });
  const [appliedFilters, setAppliedFilters] = useState<IButtonInputsFormAppliedFilter[]>([]);
  const [search, setSearch] = useAtom(searchAtom);
  const [showFilters, setShowFilters] = useAtom(showFiltersAtom);
  const { userPreferences } = useUserPreferences();

  const data: IUser[] = useMemo(() => {
    if (!rawData) {
      return [];
    }

    return rawData.users
      .filter((user: IUser) => user.id !== currentUser.id)
      .map((user: IUser) => ({
        id: user.id,
        email: user.email,
        role: t(userRoleMap[user.role]),
        status: t(statusTranslateKeyMap[user.status]),
        createdAt: parseDateStringTz(user.createdAt, userPreferences.timezone),
        lastActivity: user.lastActiveAt && parseDateStringTz(user.lastActiveAt, userPreferences.timezone),
        action: '!!!!!!!!!!!!!!!!!!!!!',
      }));
  }, [rawData, userPreferences.timezone, i18n.language]);

  const dataToShow = useMemo(() => {
    if (!rawData) {
      return [];
    }

    const usersToShow = (rawData.users as IUser[]).reduce(
      (acc, user) => {
        const mappedUser = { ...user, ...mapScopesToLists(user.scopes || []) };

        const passed = appliedFilters.every((filter) =>
          validateButtonInput(mappedUser[filter.key as keyof IUser], filter),
        );
        if (passed) {
          acc[user.id] = true;
        }
        return acc;
      },
      {} as Record<string, boolean>,
    );
    const searchString = search.toLowerCase().trim();
    return data.filter(
      (user) =>
        usersToShow[user.id] &&
        Boolean(Object.values(user).find((value: string) => value?.toLowerCase().includes(searchString))),
    );
  }, [data, appliedFilters, search]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <HeaderText>{t('team.title')}</HeaderText>
      <FilterCard sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
          <SearchInput value={search} onValueChange={setSearch} />
          <FilterButton checked={showFilters} onClick={() => setShowFilters((s) => !s)} />

          <Box sx={{ flexGrow: 1 }} />
          <Button
            onClick={() => navigate(ERoute.ManagementTeamMembersDetails.replace(':id', 'add'))}
            endIcon={<PersonAddAltIcon fontSize="small" />}
            variant="contained"
          >
            {t('team.addUser')}
          </Button>
        </Box>
        <Collapse in={showFilters}>
          <TeamMembersFilters onAccept={setAppliedFilters} />
        </Collapse>
      </FilterCard>

      <TeamMembersTable
        data={dataToShow}
        selected={selected}
        setSelected={setSelected}
        onActivate={(usersIds) => handleUsersBulkAction(EUsersBulkAction.ACTIVE, usersIds || (selected as string[]))}
        onDeactive={(usersIds) => handleUsersBulkAction(EUsersBulkAction.DEACTIVE, usersIds || (selected as string[]))}
        onDelete={(usersIds) => setDeleteConfirm({ open: true, usersToDelete: usersIds || (selected as string[]) })}
      />
      <TeamMembersApproveDelete
        usersToDelete={deleteConfirm.usersToDelete}
        open={deleteConfirm.open}
        onConfirm={() => {
          handleUsersBulkAction(EUsersBulkAction.DELETE, deleteConfirm.usersToDelete);
          setDeleteConfirm((s) => ({ ...s, open: false }));
        }}
        onClose={() => setDeleteConfirm((s) => ({ ...s, open: false }))}
        data={rawData?.users || []}
      />
    </Box>
  );
};

export default Team;
