import { useEffect, useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { isAuthorizedAtom } from 'auth';
import { useAtomValue } from 'jotai';
import { usePerms } from 'hooks';
import { ERoute } from './routeTypes';
import { createRoutes } from './routing.utils';

export const Router = () => {
  const perms = usePerms();
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const router = useMemo(() => createBrowserRouter(createRoutes(perms)), [perms]);

  useEffect(() => {
    if (!isAuthorized) {
      router.navigate(ERoute.SignIn);
    }
  }, [router, isAuthorized]);

  return <RouterProvider router={router} />;
};

export default Router;
