import { type FC, type PropsWithChildren } from 'react';
import { Box, Divider, Drawer, Fade, Typography } from '@mui/material';
import { useSidemenu } from 'hooks/useSideMenu';

export const ContainerMenu: FC<PropsWithChildren> = ({ children }) => {
  const { menuOpen, setMenuOpen } = useSidemenu();

  const version = __APP_VERSION__;

  return (
    <Box component="nav" onMouseEnter={() => setMenuOpen(true)} onMouseLeave={() => setMenuOpen(false)}>
      <Drawer
        variant="permanent"
        sx={{
          'display': { xs: 'none', sm: 'block' },
          'transition': (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: menuOpen ? 220 : 60,
            transition: (theme) =>
              theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            background: 'transparent',
          },
        }}
        open
      >
        <Box
          sx={{
            paddingTop: '81px',
            position: 'relative',
            zIndex: 100,
            backgroundColor: 'background.default',
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            backgroundColor: 'background.default',
            height: '100%',
            overflow: 'hidden',
          }}
          role="presentation"
        >
          <Divider sx={{ width: '100%' }} />
          <Box
            sx={{
              background: 'main.primary',

              opacity: menuOpen ? 1 : 0.8,

              transition: (theme) =>
                theme.transitions.create(['opacity'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
            }}
          >
            {children}
          </Box>

          <Fade in={menuOpen}>
            <Typography variant="caption" align="center" color="gray" sx={{ mt: 'auto' }}>
              v{version}
            </Typography>
          </Fade>
        </Box>
      </Drawer>
    </Box>
  );
};
