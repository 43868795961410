import type { IButtonInputsFormAppliedFilter } from 'components/ButtonInputsForm';
import type { INumberButtonInputState } from 'components/NumberButtonInput';

export const mapButtonInputsToCurrency = (filters: IButtonInputsFormAppliedFilter[], keys: string[]) => {
  return filters.map((filter) => {
    if (filter.type === 'number' && keys.includes(filter.key)) {
      const state = { ...filter.state } as INumberButtonInputState;
      if (state.value) {
        state.value = round(state.value);
      }
      if (state.value2) {
        state.value2 = round(state.value2);
      }
      return {
        ...filter,
        state,
      };
    }

    return filter;
  });
};

const round = (value: number) => {
  return value ? Math.round(value * 100) : value;
};
