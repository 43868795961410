import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  FilterCard,
  GameHistoryViewer,
  HeaderText,
  SubWindow,
  type IButtonInputsFormAppliedFilter,
  type ISelectButtonInputState,
} from 'components';
import { type TFilter } from 'components/ReportExport/types';
import {
  playersMap,
  useButtonInputsFormWithDataGridAndDefaultQuery,
  useDefaultFiltersData,
  useLoading,
  useUserPreferences,
  type EPlayerFilter,
} from 'hooks';
import { useGrid } from 'hooks/useGrid';
import { isSpecificError, parseButtonInputsFormToWhere } from 'utils';
import { ERoundTypes } from 'utils/roundTypes';
import NotFound from 'views/NotFound';
import { BetsFilters } from './BetBrowserFilters';
import BetsBrowserTable from './BetBrowserTable';
import { GET_BETS, parseBetsData, type IBetsRawData } from './betsData';

const keysMap: Record<string, string> = {
  games: 'slotId',
  win: 'creditAmount',
  bet: 'debitAmount',
  refund: 'refundAmount',
  currencies: 'currency',
  countries: 'country',
  clients: 'clientId',
  operators: 'operatorId',
  clusters: 'clusterId',
  betTime: 'createdAt',
};

export const BetBrowser = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();

  const [searchParams] = useSearchParams();

  const [selectedBetId, setSelectedBetId] = useState<string | number | null>(null);
  const [playerNotFound, setPlayerNotFound] = useState(false);
  const { userPreferences } = useUserPreferences();

  const { gridRef, gridState } = useGrid();

  const { loading } = useLoading();

  const { setWhereFilters, page, setPage, setSort, rawData, sort, whereFilters } =
    useButtonInputsFormWithDataGridAndDefaultQuery<IBetsRawData>({
      GQL: GET_BETS,
      loadingKey: 'fetchPlayerBrowserDetailsData',
      shouldShowError: (err) => {
        const isPlayerNotFoundError = isSpecificError(err, 'ERROR: invalid input syntax for type uuid');
        setPlayerNotFound(isPlayerNotFoundError);
        return !isPlayerNotFoundError;
      },
      defaultSort: {
        field: 'createdAt',
        sort: 'desc',
      },
    });
  const { filtersData } = useDefaultFiltersData('fetchPlayerBrowserDetailsFilters');

  const handleAcceptFilters = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    const mappedFilters = filters.map((filter) => {
      if (filter.key === 'tester') {
        const state = filter.state as ISelectButtonInputState;
        return {
          ...filter,
          state: {
            value: playersMap[state.value as EPlayerFilter] as unknown as string,
          },
        };
      }
      return filter;
    });

    const whereFilters = parseButtonInputsFormToWhere(mappedFilters, keysMap);

    setWhereFilters(whereFilters);
  }, []);

  const { data, totalCount, summary } = useMemo(() => {
    if (rawData) {
      const parsedData = parseBetsData(rawData, i18n.language, userPreferences.timezone, whereFilters?.baseCurrency, t);
      return parsedData;
    }
    return { data: [], totalCount: -1, summary: {} };
  }, [rawData, i18n.language, userPreferences.timezone]);

  const { historyViewerUrl, isBuyFeatureTrigger } = useMemo(() => {
    const bet = data.find((item) => item.id === selectedBetId);
    const rawBet = rawData?.bets.items.find((item) => item.entityId === selectedBetId);
    return {
      historyViewerUrl: bet?.historyViewerUrl,
      isBuyFeatureTrigger: rawBet?.roundType === ERoundTypes.BuyFeature,
    };
  }, [selectedBetId, data, rawData]);

  if (!rawData && playerNotFound) {
    return (
      <NotFound
        overrideTitle={t('playerBrowser.title')}
        overrideSubtitle={t('playerBrowser.playerNotFound', { id: params.id })}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <HeaderText>{t('playerBrowser.detailsTitle')}</HeaderText>

      <FilterCard>
        <BetsFilters
          onAccept={handleAcceptFilters}
          filtersData={filtersData}
          loading={loading}
          acceptOnInit={Boolean(searchParams.get('acceptOnInit'))}
        />
      </FilterCard>

      <BetsBrowserTable
        data={data}
        page={page}
        totalCount={totalCount}
        setPage={setPage}
        setSort={setSort}
        sort={sort}
        selectedBetId={selectedBetId}
        setSelectedBetId={setSelectedBetId}
        filter={whereFilters as TFilter}
        summary={summary}
        gridRef={gridRef}
      />
      {gridState.visibleColumns.length > 0 && (
        <SubWindow
          onClose={() => setSelectedBetId(null)}
          startOpened
          title={t('playerBrowser.roundDetails')}
          sx={{ visibility: selectedBetId ? 'visible' : 'hidden' }}
          startSize={{ x: 700, y: window.innerHeight - 150 }}
          startPosition={{ x: Math.max(0, window.innerWidth - 740), y: 85 }}
        >
          <GameHistoryViewer
            betId={selectedBetId}
            url={historyViewerUrl}
            elevation={8}
            isBuyFeatureTrigger={isBuyFeatureTrigger}
          />
        </SubWindow>
      )}
    </Box>
  );
};

export default BetBrowser;
