export const downloadFile = (url: string, name?: string) => {
  const link = document.createElement('a');
  if (name) {
    link.setAttribute('download', name);
  }
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  link.remove();
};
