import { matchRoutes, useLocation } from 'react-router-dom';
import { ERoute } from 'routing/routeTypes';

const routes = Object.values(ERoute).map((route) => ({
  path: route,
}));

export const useCurrentPath = () => {
  const location = useLocation();
  const route = matchRoutes(routes, location)?.find((m) => m.pathname === location.pathname);

  return route?.route.path;
};
