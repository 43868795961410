import { type TBuildClients, type TBuildClusters, type TBuildOperators } from './accessBuildGroups';

export interface IScope {
  clusterId: string | null;
  clientId: string | null;
  operatorId: string | null;
}

export function createScopes({
  clusters,
  clients,
  operators,
}: {
  clusters: TBuildClusters;
  clients: TBuildClients;
  operators: TBuildOperators;
}): IScope[] {
  const scopes = [];

  for (const cluster of clusters) {
    if (cluster.value.selectAndAllParent) {
      scopes.push(createScope(cluster.id, null, null));
    } else if (cluster.checked) {
      const clusterClientsSelected = clients.filter(
        (client) => cluster.id === client.value.clusterId && client.checked,
      );

      for (const client of clusterClientsSelected) {
        if (client.value.selectAndAllParent) {
          scopes.push(createScope(cluster.id, client.id, null));
        } else if (client.checked) {
          const clusterOperatorsSelected = operators.filter(
            (operator) => operator.value.clientId === client.id && operator.checked,
          );

          for (const operator of clusterOperatorsSelected) {
            scopes.push(createScope(cluster.id, client.id, operator.id));
          }
        }
      }
    }
  }

  return scopes;
}

export function enrichClustersPermissions(clusters: TBuildClusters, permissions: IScope[]): TBuildClusters {
  const mutatedClusters = [...clusters];

  for (const permission of permissions) {
    const { clusterId, clientId, operatorId } = permission;

    const clusterIndex = mutatedClusters.findIndex((cluster) => cluster.id === clusterId);

    if (clusterIndex !== -1) {
      mutatedClusters[clusterIndex].checked = true;
    }

    if (!clientId && !operatorId) {
      mutatedClusters[clusterIndex].value.selectAndAllParent = true;
    }
  }

  return mutatedClusters;
}

export function enrichClientsPermissions(clients: TBuildClients, permissions: IScope[]): TBuildClients {
  const mutatedClients = [...clients];

  for (const permission of permissions) {
    const { clusterId, clientId, operatorId } = permission;

    const clientIndex = mutatedClients.findIndex((client) => client.id === clientId);

    if (clientIndex !== -1) {
      mutatedClients[clientIndex].checked = true;
    }

    if (clusterId && clientId && !operatorId) {
      mutatedClients[clientIndex].value.selectAndAllParent = true;
    }
  }

  return mutatedClients;
}

export function enrichOperatorsPermissions(operators: TBuildOperators, permissions: IScope[]): TBuildOperators {
  const mutatedOperators = [...operators];

  for (const permission of permissions) {
    const { operatorId } = permission;

    const operatorIndex = mutatedOperators.findIndex((operator) => operator.id === operatorId);

    if (operatorIndex !== -1) {
      mutatedOperators[operatorIndex].checked = true;
    }
  }

  return mutatedOperators;
}

const createScope = (clusterId: string | null, clientId: string | null, operatorId: string | null) => ({
  clusterId,
  clientId,
  operatorId,
});
