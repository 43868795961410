import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useAtom } from 'jotai';
import {
  Box,
  Button,
  LogicOperatorButton,
  PopperMenu,
  PopperSelect,
  TextField,
  Typography,
  type ILogicOperator,
  type TBaseInput,
} from 'components';

type Operator = 'equal' | 'greater' | 'less' | 'greaterOrEqual' | 'lessOrEqual' | 'between';

export interface INumberButtonInputState {
  value: number | undefined;
  value2: number | undefined;
  operator: Operator;
}

interface IProps {
  onToggle?: (open: boolean) => void;
}

enum EErrors {
  NOT_NUMBER_1,
  NOT_NUMBER_2,
  BETWEEN,
}

export const NumberButtonInput = ({ label, onToggle, atom, disabled }: TBaseInput<IProps, INumberButtonInputState>) => {
  const { t } = useTranslation();
  const [state, setState] = useAtom(atom);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [tempOperator, setTempOperator] = useState<Operator>('equal');
  const [error, setError] = useState<EErrors | null>(null);

  const logicOperators = [
    { sign: '=', label: t('inputButtons.equal'), key: 'equal' },
    { sign: '>', label: t('inputButtons.greater'), key: 'greater' },
    { sign: '<', label: t('inputButtons.less'), key: 'less' },
    { sign: '≥', label: t('inputButtons.greaterOrEqual'), key: 'greaterOrEqual' },
    { sign: '≤', label: t('inputButtons.lessOrEqual'), key: 'lessOrEqual' },
    { sign: '∈', label: t('inputButtons.between'), key: 'between' },
  ] as ILogicOperator<Operator>[];
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => inputRef.current?.focus());
      setInputValue(state.value === undefined ? '' : String(state.value));
      setInputValue2(state.value === undefined ? '' : String(state.value2));
      setTempOperator(state.operator);
    } else {
      setError(null);
    }
    onToggle?.(open);
  }, [open, onToggle]);

  useEffect(() => {
    setInputValue2(tempOperator === 'between' ? inputValue : '');
  }, [tempOperator]);

  const handleAccept = useCallback(() => {
    const numbered = Number(inputValue);

    if (isNaN(numbered) || !inputValue) {
      setError(EErrors.NOT_NUMBER_1);
      return;
    }
    if (tempOperator === 'between') {
      const numbered2 = Number(inputValue2);
      if (isNaN(numbered2) || !inputValue2) {
        setError(EErrors.NOT_NUMBER_2);
        return;
      }
      if (numbered > numbered2) {
        setError(EErrors.BETWEEN);
        return;
      }
      setInputValue2('');
      setState((prevState) => ({ ...prevState, value: numbered, operator: tempOperator, value2: numbered2 }));
    } else {
      setState((prevState) => ({ ...prevState, value: numbered, operator: tempOperator }));
    }

    setInputValue('');

    setOpen(false);
  }, [inputValue, inputValue2]);

  const handleClear = useCallback(() => {
    setInputValue('');
    setState({ value: undefined, value2: undefined, operator: 'equal' });
    setOpen(false);
  }, []);

  const handleLogicOperatorButtonChange = useCallback(
    (operator: Operator) => {
      setState((prevState) => {
        if (operator === 'between') {
          return { ...prevState, operator: operator, value2: prevState.value };
        } else {
          return { ...prevState, operator: operator };
        }
      });
    },
    [state.value, state.operator],
  );

  return (
    <>
      <Button
        ref={buttonRef}
        disabled={disabled}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: state.value !== undefined ? 'main.primary' : 'text.primary',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        onClick={() => setOpen(true)}
      >
        {label}
        {state.value !== undefined && (
          <>
            <LogicOperatorButton
              operators={logicOperators}
              value={state.operator}
              onChange={handleLogicOperatorButtonChange}
            />
            {state.operator === 'between' ? `[${state.value}, ${state.value2}]` : state.value}
          </>
        )}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ marginLeft: 1 }} />
        )}
      </Button>

      <PopperMenu
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        placement="bottom-start"
        paperProps={{ sx: { padding: 1, display: 'flex', flexDirection: 'column', gap: 1, width: 300 } }}
      >
        <PopperSelect
          value={tempOperator}
          options={logicOperators.map((operator) => ({
            key: operator.key,
            label: `${label} ${operator.sign} (${operator.label})`,
          }))}
          onChange={(value) => setTempOperator(value as Operator)}
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <TextField
            error={error === EErrors.NOT_NUMBER_1 || error === EErrors.BETWEEN}
            size="small"
            value={inputValue}
            onChange={(evt) => setInputValue(evt.target.value)}
            inputRef={inputRef}
            fullWidth
            placeholder="0"
          />
          {tempOperator === 'between' && (
            <>
              <Box>-</Box>
              <TextField
                error={error === EErrors.NOT_NUMBER_2}
                size="small"
                value={inputValue2}
                onChange={(evt) => setInputValue2(evt.target.value)}
                inputRef={inputRef}
                fullWidth
                placeholder="0"
              />
            </>
          )}
        </Box>
        {error !== null && (
          <Typography color="error" variant="caption">
            {error === EErrors.BETWEEN && t('inputButtons.numberBetweenError')}
            {error === EErrors.NOT_NUMBER_1 && t('inputButtons.notNumberError')}
            {error === EErrors.NOT_NUMBER_2 && t('inputButtons.notNumberError')}
          </Typography>
        )}

        <Box sx={{ alignSelf: 'flex-end', display: 'flex', gap: 1 }}>
          {state.value !== undefined && (
            <Button onClick={handleClear} variant="outlined" size="small">
              {t('base.clear')}
            </Button>
          )}
          <Button onClick={handleAccept} variant="outlined" size="small">
            {t('base.accept')}
          </Button>
        </Box>
      </PopperMenu>
    </>
  );
};

export default NumberButtonInput;
