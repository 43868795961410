import { useCallback, useEffect } from 'react';
import { useAtomValue, useStore, type PrimitiveAtom } from 'jotai';
import { useDefaultFiltersData, type IFilterData } from 'hooks/useDefaultFiltersData';
import { usePerms } from 'hooks/usePerms';

interface IAtoms {
  selected: PrimitiveAtom<string[]>;
  data: PrimitiveAtom<IFilterData[]>;
}

interface IProps {
  loadingKey: string;
  clustersAtoms: IAtoms;
  clientsAtoms: IAtoms;
  operatorsAtoms: IAtoms;
  gamesAtoms?: IAtoms;
  countriesAtoms?: IAtoms;
  currenciesAtoms?: IAtoms;
}

export const defaultSelectedFilter = (
  selected: string[],
  filters: { id: string }[],
  showRecord?: Record<string, boolean>,
) => selected.filter((id) => (showRecord ? showRecord[id] : true) && filters.some((filter) => filter.id === id));

export const useLimitedFiltersData = ({
  loadingKey,
  clustersAtoms,
  clientsAtoms,
  operatorsAtoms,
  gamesAtoms,
  countriesAtoms,
  currenciesAtoms,
}: IProps) => {
  const { filtersData, rawData, loading } = useDefaultFiltersData(loadingKey);
  const store = useStore();
  const perms = usePerms();

  const selectedClusters = useAtomValue(clustersAtoms.selected);
  const selectedClients = useAtomValue(clientsAtoms.selected);
  const selectedOperators = useAtomValue(operatorsAtoms.selected);

  const limitFilters = useCallback(
    ({ data, atoms, showRecord }: { data: IFilterData[]; showRecord: Record<string, boolean>; atoms: IAtoms }) => {
      if (Object.keys(showRecord).length) {
        store.set(
          atoms.data,
          data.filter((filter) => showRecord[filter.id]),
        );
        store.set(atoms.selected, (selected) => defaultSelectedFilter(selected, data, showRecord));
      } else {
        store.set(atoms.data, []);
        store.set(atoms.selected, []);
      }
    },
    [],
  );

  useEffect(() => {
    if (filtersData) {
      const setDefaultData = (data: IFilterData[], atoms?: IAtoms) => {
        if (atoms) {
          store.set(atoms.data, data);
          store.set(atoms.selected, (selected) => defaultSelectedFilter(selected, data));
        }
      };
      setDefaultData(filtersData.clusters, clustersAtoms);
      setDefaultData(filtersData.clients, clientsAtoms);
      setDefaultData(filtersData.operators, operatorsAtoms);
      setDefaultData(filtersData.games, gamesAtoms);
      setDefaultData(filtersData.currencies, currenciesAtoms);
      setDefaultData(filtersData.countries, countriesAtoms);
    }
  }, [filtersData]);

  // limit clients
  useEffect(() => {
    if (!rawData || !filtersData || !perms.clusters) {
      return;
    }
    const clientsToShow =
      rawData.clients?.reduce(
        (acc, client) => {
          acc[client.id] = selectedClusters.some((clusterId) =>
            client.clusterId ? clusterId === client.clusterId : true,
          );
          return acc;
        },
        {} as Record<string, boolean>,
      ) || {};
    limitFilters({
      data: filtersData.clients,
      showRecord: clientsToShow,
      atoms: clientsAtoms,
    });
  }, [rawData, filtersData, selectedClusters, perms]);

  // limit operators
  useEffect(() => {
    if (!rawData || !filtersData || !perms.clients) {
      return;
    }
    const operatorsToShow = rawData.operators.reduce(
      (acc, operator) => {
        acc[operator.id] = selectedClients.some((clientId) => (clientId ? clientId === operator.clientId : true));
        return acc;
      },
      {} as Record<string, boolean>,
    );
    limitFilters({
      data: filtersData.operators,
      showRecord: operatorsToShow,
      atoms: operatorsAtoms,
    });
  }, [rawData, filtersData, selectedClients, perms]);

  // limit games
  useEffect(() => {
    if (!rawData || !filtersData || !perms.operators || !gamesAtoms) {
      return;
    }
    const gamesToShow = rawData.slots.reduce(
      (acc, slot) => {
        acc[slot.id] = selectedOperators.some((operatorId) => slot.operatorIds.includes(operatorId));
        return acc;
      },
      {} as Record<string, boolean>,
    );
    limitFilters({
      data: filtersData.games,
      showRecord: gamesToShow,
      atoms: gamesAtoms,
    });
  }, [rawData, filtersData, selectedOperators, perms]);

  return {
    filtersData,
    loading,
    rawData,
  };
};
