import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'hooks';

interface IProps {
  betId: string | number | null;
  isBuyFeatureTrigger?: boolean;
  elevation?: number;
  url?: string;
}

type TPostMessageValue = string | number | boolean | null | undefined;

export const GameHistoryViewer = ({ betId, elevation, url, isBuyFeatureTrigger }: IProps) => {
  const { darkMode } = useTheme();
  const { i18n } = useTranslation();
  const ref = useRef<HTMLIFrameElement>(null);

  const postMessage = useCallback(
    (type: string, value: TPostMessageValue) => {
      ref.current?.contentWindow?.postMessage({ type, value }, url || '*');
    },
    [url],
  );

  useEffect(() => {
    ref?.current?.addEventListener('load', () => {
      postMessage('betId', betId);
      postMessage('darkMode', darkMode);
      postMessage('expandFreeSpins', false);
      postMessage('bft', Boolean(isBuyFeatureTrigger));
      postMessage('lang', i18n.language);
      postMessage('elevation', elevation || 0);
    });
  }, [ref.current]);

  useEffect(() => {
    postMessage('betId', betId);
    postMessage('expandFreeSpins', false);
  }, [betId]);
  useEffect(() => postMessage('darkMode', darkMode), [darkMode]);
  useEffect(() => postMessage('bft', Boolean(isBuyFeatureTrigger)), [isBuyFeatureTrigger]);
  useEffect(() => postMessage('lang', i18n.language), [i18n.language]);
  useEffect(() => postMessage('elevation', elevation || 0), [elevation]);

  const initialSrc = useMemo(() => {
    let src = url;
    src += `?darkMode=${Boolean(darkMode)}`;
    src += `&expandFreeSpins=${false}`;
    src += `&bft=${Boolean(isBuyFeatureTrigger)}`;
    src += `&betId=${betId || ''}`;
    src += `&lang=${i18n.language}`;
    return src;
  }, [url]);

  if (!url) {
    return null;
  }

  return (
    <iframe ref={ref} src={initialSrc} style={{ width: '100%', height: '100%', outline: 'none', border: 'none' }} />
  );
};

export default GameHistoryViewer;
