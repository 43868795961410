import { useTranslation } from 'react-i18next';
import { CopyAllOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, InputAdornment, TextField, Typography } from 'components';
import { copyToClipboard } from 'utils';

interface IProps {
  userData: { password: string; email: string } | null;
  onClose: () => void;
  title: string;
}

export const TeamMemberUserPasswordPopup = ({ userData, onClose, title }: IProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={Boolean(userData)}>
      <Box sx={{ gap: 5, display: 'flex', flexDirection: 'column', p: 3, alignItems: 'center' }}>
        <Typography variant="h5" textAlign="center">
          {title}
        </Typography>
        <TextField
          variant="filled"
          label={t('login.email')}
          value={userData?.email || ''}
          InputProps={{ readOnly: true }}
          fullWidth
          sx={{ width: 400 }}
        />
        <TextField
          label={t('login.password')}
          variant="filled"
          helperText={t('team.createNewUserPasswordInputHelperText')}
          value={userData?.password || ''}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => copyToClipboard(userData?.password!)}>
                  <CopyAllOutlined />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{ width: 400 }}
        />
        <Button
          fullWidth
          onClick={() => {
            copyToClipboard(userData?.password!);
            onClose();
          }}
        >
          {t('team.copyAndClose')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default TeamMemberUserPasswordPopup;
