import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'translation';
import { generateSummary, type TGenerateSummary } from 'utils/generateSummary';

export function useGenerateSummary<TField extends string, TSummary>(
  rawSummary: TSummary,
  config: TGenerateSummary<TField, TSummary>,
) {
  const { t } = useTranslation();

  const [visibleColumns, setVisibleColumns] = useState(0);

  useEffect(() => {
    if (config.grid.subscribeEvent) {
      return config.grid.subscribeEvent('columnVisibilityModelChange', () =>
        setVisibleColumns(config.grid.getVisibleColumns().length),
      );
    }
  }, [config.grid]);

  const footerSummary = useMemo(
    () => generateSummary<TField, TSummary>(rawSummary, config, t),
    [rawSummary, i18n.language, visibleColumns],
  );

  return footerSummary;
}
