import { gql } from '@apollo/client';
import { type TFunction } from 'i18next';
import { parseCountryCode, parseCurrencyNumber, parseDateStringTz } from 'utils';
import { parseRoundType } from 'utils/roundTypes';
import { type IBetsSummary } from './BetBrowserTable';

interface IBetItemRecord {
  clusterLabel?: string;
  clientLabel?: string;
  currency: string;
  entityId: string;
  roundType: string;
  operatorLabel: string;
  playerExternalId: string;
  playerId: string;
  playerInternalId: string;
  slotLabel: string;
  tester: boolean;
  createdAt: string;
  country: string;
  historyViewerUrl: string;
  values: {
    creditAmount: number;
    debitAmount: number;
    refundAmount: number;
  };
  normalizedValues?: {
    creditAmount: number;
    debitAmount: number;
    refundAmount: number;
  };
}

export interface IBetsRawData {
  bets: { items: IBetItemRecord[]; skip: number; take: number; totalCount: number; summary: IBetsSummary };
}

export const GET_BETS = gql`
  query GetBets(
    $where: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $normalization: NormalizationInput
    $normalized: Boolean!
  ) {
    bets(
      filter: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      normalization: $normalization
    ) {
      totalCount
      skip
      take
      summary @include(if: $normalized) {
        creditTotalSummary
        debitTotalSummary
        refundTotalSummary
      }
      items {
        slotLabel
        playerId
        playerInternalId
        playerExternalId
        operatorLabel
        clusterLabel
        clientLabel
        entityId
        roundType
        currency
        createdAt
        country
        historyViewerUrl
        tester
        values @skip(if: $normalized) {
          debitAmount
          creditAmount
          refundAmount
        }
        normalizedValues @include(if: $normalized) {
          debitAmount
          creditAmount
          refundAmount
        }
      }
    }
  }
`;

export const parseBetsData = (
  rawData: IBetsRawData,
  lang: string,
  timezone: string,
  baseCurrency: string | null | unknown,
  t: TFunction,
) => {
  return {
    totalCount: rawData.bets.totalCount,
    summary: rawData.bets.summary,
    data: rawData.bets.items.map((item) => {
      return {
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        clusterLabel: item.clusterLabel,
        operatorLabel: item.operatorLabel,
        clientLabel: item.clientLabel,
        country: parseCountryCode(item.country, lang),
        historyViewerUrl: item.historyViewerUrl,
        slotLabel: item.slotLabel,
        id: item.entityId,
        entityId: item.entityId,
        currency: item.currency,
        createdAt: parseDateStringTz(item.createdAt, timezone),
        roundType: parseRoundType(item.roundType, t),
        tester: item.tester ? t('base.Yes') : t('base.No'),
        ...(typeof baseCurrency === 'string'
          ? {
              debitAmount: parseCurrencyNumber(item.normalizedValues?.debitAmount, baseCurrency),
              creditAmount: parseCurrencyNumber(item.normalizedValues?.creditAmount, baseCurrency),
              refundAmount: parseCurrencyNumber(item.normalizedValues?.refundAmount, baseCurrency),
            }
          : {
              debitAmount: parseCurrencyNumber(item.values?.debitAmount),
              creditAmount: parseCurrencyNumber(item.values?.creditAmount),
              refundAmount: parseCurrencyNumber(item.values?.refundAmount),
            }),
      };
    }),
  };
};
