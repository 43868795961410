import { useMemo, useRef } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { Box, Fade, List, Skeleton } from 'components';
import { type IAccountingFilterData, type TFilterAtoms } from 'hooks/useAccountingFilters';
import { useLoading } from 'hooks/useLoading';
import AccountingReportListItem from './AccountingReportListItem';

export const FILTER_ITEM_HEIGHT = 24;

interface IProps {
  atoms: TFilterAtoms;
  dataToShow: IAccountingFilterData[];
  itemsVisibleCount?: number;
}

export const AccountingReportFiltersList = ({ atoms, dataToShow, itemsVisibleCount = 12 }: IProps) => {
  const [selected, setSelected] = useAtom(atoms.selected);

  const isError = useAtomValue(atoms.error);
  const listRef = useRef<HTMLUListElement>(null);
  const listHeight = FILTER_ITEM_HEIGHT * itemsVisibleCount;

  const { loading } = useLoading();

  const shouldSendOptionalName = (name: string) => {
    return dataToShow.filter((data) => data.name === name).length > 1;
  };

  const randomizedSkeletonValue = useMemo(() => Math.random() * 5 + 4, []);

  return (
    <List
      disablePadding
      ref={listRef}
      sx={{
        height: isError ? listHeight - FILTER_ITEM_HEIGHT : listHeight,
        overflowY: 'auto',
        border: '1px solid',
        borderColor: 'divider',
        position: 'relative',
      }}
    >
      <Fade in={loading && dataToShow.length === 0}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            pl: 1,
            pr: 1,
            pt: 1,
            width: 1,
            margin: '0 auto',
            position: 'absolute',
          }}
        >
          {Array.from({ length: randomizedSkeletonValue }).map((_, i) => (
            <Skeleton variant="rectangular" key={i} height={17.5} />
          ))}
        </Box>
      </Fade>

      {dataToShow.map(
        (item) =>
          selected.includes(item.id) && (
            <AccountingReportListItem
              initialChecked={true}
              key={item.id}
              item={{
                ...item,
                ...(item.tooltip && shouldSendOptionalName(item.name) && { optionalName: item.tooltip.parentName }),
              }}
              onClick={() => setSelected(selected.filter((id) => id !== item.id))}
              shouldSkipAnimation={(item) => {
                const scrollTop = listRef.current?.scrollTop || 0;
                let targetIndex = selected.length - 1;
                dataToShow.find((el) => {
                  if (!selected.includes(el.id)) {
                    targetIndex++;
                  }
                  return el === item;
                });
                const targetPosition = targetIndex * FILTER_ITEM_HEIGHT;
                return targetPosition >= scrollTop && targetPosition <= scrollTop + listHeight;
              }}
            />
          ),
      )}
      {dataToShow.map(
        (item) =>
          !selected.includes(item.id) && (
            <AccountingReportListItem
              initialChecked={false}
              key={item.id}
              item={{
                ...item,
                ...(item.tooltip && shouldSendOptionalName(item.name) && { optionalName: item.tooltip.parentName }),
              }}
              onClick={() => setSelected([...selected, item.id])}
              shouldSkipAnimation={(item) => {
                const scrollTop = listRef.current?.scrollTop || 0;
                let targetIndex = 0;
                dataToShow.find((el) => {
                  if (selected.includes(el.id)) {
                    targetIndex++;
                  }
                  return el === item;
                });
                const targetPosition = targetIndex * FILTER_ITEM_HEIGHT;
                return targetPosition >= scrollTop && targetPosition <= scrollTop + listHeight;
              }}
            />
          ),
      )}
    </List>
  );
};

export default AccountingReportFiltersList;
