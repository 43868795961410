import * as reactRouterDom from 'react-router-dom';
import SuperTokens from 'supertokens-auth-react';
import emailpassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import session from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { gql } from '@apollo/client';
import { client } from 'apollo';
import { t } from 'i18next';
import { getDefaultStore } from 'jotai';
import {
  emptyUser,
  loadingAtom,
  loadingSet,
  parsePerms,
  userAtom,
  userPreferencesAtom,
  validateUserPreferences,
  type IUser,
} from 'hooks';
import { ERoute } from 'routing';
import { showError, showToast } from 'utils';
import { isAuthorizedAtom, noPerms, permsAtom, userIdAtom, type IAuthData } from './authAtoms';

const GET_USER = gql`
  query GetMe {
    me {
      role
      id
      email
      status
      permissions
      changePassword
      scopes {
        clusterId
        clientId
        operatorId
      }
      preferences {
        darkMode
        timezone
        locale
      }
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($changeMyPasswordInput: ChangeMyPasswordInput!) {
    changeMyPassword(changeMyPasswordInput: $changeMyPasswordInput) {
      status
    }
  }
`;

const store = getDefaultStore();
store.sub(userIdAtom, async () => {
  const userId = store.get(userIdAtom);
  if (userId) {
    loadingSet.add('getMe');
    store.set(loadingAtom, true);
    try {
      const user = await client.query({
        query: GET_USER,
        fetchPolicy: 'network-only',
      });
      const { preferences } = user.data.me;
      store.set(permsAtom, parsePerms(user.data.me));
      store.set(userPreferencesAtom, validateUserPreferences(preferences));
      store.set(userAtom, user.data.me);
    } catch (err) {
      console.error(err);
      signOut();
      showError(err as Error);
      store.set(permsAtom, noPerms);
    }
    loadingSet.delete('getMe');
    if (loadingSet.size === 0) {
      store.set(loadingAtom, false);
    }
  } else {
    store.set(permsAtom, noPerms);
  }
});

export const initSupertokens = () => {
  SuperTokens.init({
    appInfo: {
      appName: 'backoffice-v2',
      apiDomain: window.location.href,
      websiteDomain: window.location.href,
      apiBasePath: '/auth',
      websiteBasePath: ERoute.SignIn,
    },
    recipeList: [emailpassword.init(), session.init()],
  });
  fetchUser();
};

export const fetchUser = async () => {
  if (await session.doesSessionExist()) {
    store.set(isAuthorizedAtom, true);
    store.set(userIdAtom, await session.getUserId());
  } else {
    store.set(isAuthorizedAtom, false);
    store.set(userIdAtom, null);
  }
};

export const signOut = async () => {
  try {
    await Promise.all([emailpassword.signOut(), session.signOut()]);
    await fetchUser();
  } catch (e) {
    showError(e as Error);
  }
  store.set(userAtom, emptyUser);
};

export const signIn = async ({ email, password }: IAuthData) => {
  const response = await emailpassword.signIn({
    formFields: [
      { id: 'email', value: email },
      { id: 'password', value: password },
    ],
  });

  // this is workaround to use react-router-dom navigate for supertokens redirect
  // ToDo: make own redirect inside useAuth hook
  getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI]);

  if (response.status !== 'OK') {
    return { user: null, status: response.status };
  }
  fetchUser();

  //  wait on user data
  return new Promise<{ user: IUser | null; status: string }>((resolve) => {
    const unsubUser = store.sub(userAtom, () => {
      const user = store.get(userAtom);
      unsubUser();
      unsubPerms();
      resolve({ user, status: 'OK' });
    });

    // if user query fails, perms will be set to noPerms
    const unsubPerms = store.sub(permsAtom, () => {
      const perms = store.get(permsAtom);
      if (!perms.loaded) {
        resolve({ user: null, status: '' });
      }
      unsubUser();
      unsubPerms();
    });
  });
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
  try {
    const response = await client.mutate({
      mutation: CHANGE_PASSWORD,
      variables: {
        changeMyPasswordInput: { oldPassword, newPassword },
      },
      fetchPolicy: 'network-only',
    });

    if (response.data.changeMyPassword?.status === 'ok') {
      showToast({ title: t('login.passwordChanged'), severity: 'success' });
      return signOut();
    }
  } catch (e) {
    showError(e as Error);
  }
  return false;
};
