const intls: Record<string, Intl.NumberFormat> = {};

export const parseBigNumber = (value: number | string | undefined, digits = 0, normalizeCurrency = '') => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return '-';
  }

  if (!intls[digits + normalizeCurrency]) {
    intls[digits + normalizeCurrency] = new Intl.NumberFormat('en-US', {
      style: normalizeCurrency ? 'currency' : 'decimal',
      currency: normalizeCurrency || undefined,
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  }

  return intls[digits + normalizeCurrency].format(Number(value));
};
