import { useTranslation } from 'react-i18next';
import { Box, ChangePasswordForm, Dialog, Typography } from 'components';
import { useUser } from 'hooks';

export const FirstLoginChangePassword = () => {
  const user = useUser();
  const { t } = useTranslation();

  return (
    <Dialog open={user.changePassword}>
      <Box sx={{ gap: 2, display: 'flex', flexDirection: 'column', p: 3, alignItems: 'center', maxWidth: 500 }}>
        <Typography variant="h5" textAlign="center">
          {t('login.firstLoginChangePassword')}
        </Typography>
        <ChangePasswordForm />
      </Box>
    </Dialog>
  );
};

export default FirstLoginChangePassword;
