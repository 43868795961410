import { isAuthorizedAtom } from 'auth/authAtoms';
import { atom, getDefaultStore, type PrimitiveAtom } from 'jotai';

const store = getDefaultStore();
const atomsToReset: { atomToReset: PrimitiveAtom<unknown>; value: unknown }[] = [];

export const atomWithAuthReset = <T = unknown>(value: T) => {
  const atomToReset = atom<T>(value);
  atomsToReset.push({ atomToReset: atomToReset as PrimitiveAtom<unknown>, value });
  return atomToReset;
};

store.sub(isAuthorizedAtom, () => {
  const isAuthorized = store.get(isAuthorizedAtom);
  if (!isAuthorized) {
    atomsToReset.forEach(({ atomToReset, value }) => {
      store.set(atomToReset, value);
    });
  }
});
