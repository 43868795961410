import type { TPerm } from 'auth';
import type { TFunction } from 'i18next';
import { filterArray } from 'utils/arrays';

export type TPlayerBrowserField =
  | 'playerId'
  | 'playerExternalId'
  | 'playerInternalId'
  | 'country'
  | 'currency'
  | 'totalSpinCount'
  | 'totalBuyCount'
  | 'debitAmount'
  | 'creditAmount'
  | 'tester'
  | 'firstTransactionDate'
  | 'lastTransactionDate'
  | 'clusterLabel'
  | 'clientLabel'
  | 'operatorLabel';

export const generateColumns = (t: TFunction, perms: TPerm) =>
  filterArray([
    { field: 'playerId', headerName: t('playerBrowser.playerId') },
    { field: 'playerExternalId', headerName: t('playerBrowser.playerExternalId') },
    { field: 'playerInternalId', headerName: t('playerBrowser.playerInternalId') },
    perms.clusters && { field: 'clusterLabel', headerName: t('playerBrowser.cluster') },
    perms.clients && { field: 'clientLabel', headerName: t('playerBrowser.client') },
    perms.operators && { field: 'operatorLabel', headerName: t('playerBrowser.operator') },
    { field: 'country', headerName: t('playerBrowser.country') },
    { field: 'totalSpinCount', headerName: t('playerBrowser.betCount'), align: 'center', headerAlign: 'center' },
    { field: 'totalBuyCount', headerName: t('playerBrowser.buyFeatureCount'), align: 'center', headerAlign: 'center' },
    { field: 'currency', headerName: t('playerBrowser.currency') },
    { field: 'debitTotal', headerName: t('playerBrowser.totalBet'), align: 'right', headerAlign: 'right' },
    { field: 'creditTotal', headerName: t('playerBrowser.totalWin'), align: 'right', headerAlign: 'right' },
    { field: 'refundTotal', headerName: t('accounting.totalRefund'), align: 'right', headerAlign: 'right' },
    { field: 'firstTransactionDate', headerName: t('playerBrowser.firstActivity') },
    { field: 'lastTransactionDate', headerName: t('playerBrowser.lastActivity') },
    { field: 'tester', headerName: t('playerBrowser.isTester') },
  ] as const);
