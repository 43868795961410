import { useTranslation } from 'react-i18next';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { IconButton, Tooltip } from '@mui/material';
import { useTheme } from 'hooks';

export const ModeToggle = () => {
  const { darkMode, toggleColorMode } = useTheme();
  const { t } = useTranslation();

  return (
    <Tooltip title={t('menu.switchColorMode')}>
      <IconButton color="inherit" onClick={toggleColorMode}>
        {darkMode ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>
    </Tooltip>
  );
};

export default ModeToggle;
