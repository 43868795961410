import { type TPerm } from 'auth';
import { type TFunction } from 'i18next';
import { filterArray } from 'utils/arrays';

export type TPlayerBrowserDetailField =
  | 'entityId'
  | 'createdAt'
  | 'slotLabel'
  | 'debitAmount'
  | 'creditAmount'
  | 'country'
  | 'currency'
  | 'roundType'
  | 'refundAmount';

export const generateColumns = (t: TFunction, perms: TPerm) =>
  filterArray([
    { field: 'entityId', headerName: t('playerBrowser.betId') },
    { field: 'playerId', headerName: t('playerBrowser.playerId') },
    { field: 'playerExternalId', headerName: t('playerBrowser.playerExternalId') },
    { field: 'playerInternalId', headerName: t('playerBrowser.playerInternalId') },
    perms.clusters && { field: 'clusterLabel', headerName: t('playerBrowser.cluster') },
    perms.clients && { field: 'clientLabel', headerName: t('playerBrowser.client') },
    perms.operators && { field: 'operatorLabel', headerName: t('playerBrowser.operator') },
    { field: 'slotLabel', headerName: t('playerBrowser.gameName') },
    { field: 'currency', headerName: t('playerBrowser.currency') },
    { field: 'debitAmount', headerName: t('playerBrowser.bet'), align: 'right', headerAlign: 'right' },
    { field: 'creditAmount', headerName: t('playerBrowser.win'), align: 'right', headerAlign: 'right' },
    { field: 'refundAmount', headerName: t('accounting.refund'), align: 'right', headerAlign: 'right' },
    { field: 'createdAt', headerName: t('playerBrowser.date') },
    { field: 'country', headerName: t('playerBrowser.country') },
    { field: 'roundType', headerName: t('playerBrowser.betType') },
    { field: 'tester', headerName: t('playerBrowser.isTester') },
  ] as const);
