import { useAtom } from 'jotai';
import { atomWithAuthReset } from 'utils';

const sideMenuAtom = atomWithAuthReset(false);

export const useSidemenu = () => {
  const [menuOpen, setMenuOpen] = useAtom(sideMenuAtom);

  return { menuOpen, setMenuOpen };
};
