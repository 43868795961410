import { type TPerm } from 'auth';
import { type TMenuItem } from 'components/LeftMenu/generateMenuData';
import { type TRoute, type TRoutes } from './routeTypes';
import { getRoutesTemplate } from './routing';

export const createRoutes = (perms: TPerm) => {
  const template = getRoutesTemplate(perms);
  const filteredRoutes = filterRoutes(template) as TRoutes;
  return filteredRoutes;
};

export const createNavigationItems = (perms: TPerm): TMenuItem[] => {
  const routes = createRoutes(perms);
  const menuItems = extractMenuItems(routes).map(mapRouteToMenuItem);

  return menuItems;
};

export const filterRoutes = (routes: TRoutes): TRoutes => {
  return routes
    .filter((route) => route.enabled)
    .map((route) => ({
      ...route,
      children: route.children ? filterRoutes(route.children) : undefined,
    }));
};

export const mapRouteToMenuItem = (route: TRoute): TMenuItem => {
  return {
    menuSection: route.menuSection!,
    route: route.path!,
    title: route.title!,
  };
};

const extractMenuItems = (routes: TRoutes): TRoutes => {
  const result: TRoutes = [];

  const traverse = (routes: TRoutes) => {
    for (const route of routes) {
      if (route.menuSection) {
        result.push({
          path: route.path,
          menuSection: route.menuSection,
          element: route.element,
          title: route.title,
          showInMenu: route.showInMenu,
          enabled: true,
        });
      }
      if (route.children) {
        traverse(route.children);
      }
    }
  };

  traverse(routes);

  return result;
};
