import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FilterCard, Typography } from 'components';
import { useLimitedFiltersData, usePerms, type TFilterAtoms } from 'hooks';
import * as atoms from 'hooks/useAccountingFilters/accountingFiltersAtoms';
import AccountingReportFilter from './AccountingReportFilter';
import AccountingReportFilterDateRange from './AccountingReportFilterDateRange';
import AccountingReportFilterNormalize from './AccountingReportFilterNormalize';
import AccountingReportFiltersApplyButton from './AccountingReportFiltersApplyButton';
import AccountingReportFiltersClearButton from './AccountingReportFiltersClearButton';
import AccountingReportFilterTesters from './AccountingReportFilterTesters';

const AccountingReportFilters = () => {
  const { t } = useTranslation();
  const perms = usePerms();

  useLimitedFiltersData({
    loadingKey: 'accountingReportFilters',
    clustersAtoms: atoms.clustersAtoms,
    clientsAtoms: atoms.clientsAtoms,
    operatorsAtoms: atoms.operatorsAtoms,
    gamesAtoms: atoms.gamesAtoms,
    countriesAtoms: atoms.countriesAtoms,
    currenciesAtoms: atoms.currenciesAtoms,
  });

  const filtersData = useMemo(() => {
    return [
      perms.clusters && { title: 'accounting.clusters', atoms: atoms.clustersAtoms },
      perms.clients && { title: 'accounting.clients', atoms: atoms.clientsAtoms },
      perms.operators && { title: 'accounting.operators', atoms: atoms.operatorsAtoms },
      { title: 'accounting.games', atoms: atoms.gamesAtoms },
      { title: 'accounting.currencies', atoms: atoms.currenciesAtoms },
      { title: 'accounting.countries', atoms: atoms.countriesAtoms },
    ].filter(Boolean) as { title: string; atoms: typeof atoms.clustersAtoms }[];
  }, [perms]);

  return (
    <FilterCard
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          'display': 'flex',
          'gap': 2,
          'flexWrap': 'wrap',
          'pt': 2,
          '@media (max-width: 507px)': {
            justifyContent: 'center',
          },
        }}
      >
        {filtersData.map((item) => (
          <AccountingReportFilter key={item.title} atoms={item.atoms as TFilterAtoms} title={t(item.title)} />
        ))}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
            width: 220,
            pr: 1.5,
          }}
        >
          <Typography variant="button" fontWeight="bold" textAlign="center">
            <b>{t('accounting.fromTo')}</b>
          </Typography>
          <AccountingReportFilterDateRange />
          <AccountingReportFilterNormalize />
          <AccountingReportFilterTesters />
          <Box sx={{ display: 'flex', gap: 1, mt: 'auto' }}>
            <AccountingReportFiltersApplyButton filtersData={filtersData} />
            <AccountingReportFiltersClearButton />
          </Box>
        </Box>
      </Box>
    </FilterCard>
  );
};

export default AccountingReportFilters;
