import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useAtom } from 'jotai';
import { Box, Button, Typography } from 'components';
import { type IAccountingFilterData, type TFilterAtoms } from 'hooks/useAccountingFilters';

interface IProps {
  atoms: TFilterAtoms;
  dataToShow: IAccountingFilterData[];
}

export const AccountingReportFiltersActionButtons = ({ atoms, dataToShow }: IProps) => {
  const [selected, setSelected] = useAtom(atoms.selected);
  const { t } = useTranslation();

  const showClearAll = useMemo(
    () => dataToShow.length > 0 && dataToShow.every((item) => selected.includes(item.id)),
    [dataToShow, selected],
  );

  const handleInvertSelection = useCallback(() => {
    setSelected((currentSelected) => {
      const { itemsToRemove, itemsToAdd } = dataToShow.reduce(
        (acc, item) => {
          if (currentSelected.includes(item.id)) {
            return { ...acc, itemsToRemove: [...acc.itemsToRemove, item.id] };
          } else {
            return { ...acc, itemsToAdd: [...acc.itemsToAdd, item.id] };
          }
        },
        { itemsToRemove: [], itemsToAdd: [] } as { itemsToRemove: string[]; itemsToAdd: string[] },
      );
      return [...currentSelected.filter((id) => !itemsToRemove.includes(id)), ...itemsToAdd];
    });
  }, [dataToShow, selected]);

  const handleSelectAll = useCallback(() => {
    if (showClearAll) {
      setSelected((currentSelected) => currentSelected.filter((id) => !dataToShow.some((item) => item.id === id)));
    } else {
      setSelected((currentSelected) => [
        ...currentSelected,
        ...dataToShow.map((item) => item.id).filter((item) => !currentSelected.includes(item)),
      ]);
    }
  }, [dataToShow, showClearAll]);

  return (
    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
      <Button
        onClick={handleSelectAll}
        variant="text"
        size="small"
        sx={{
          textTransform: 'none',
          padding: '2px 4px',
          width: '50%',
          justifyContent: 'flex-start',
          gap: 1,
          paddingLeft: 0.75,
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
        color="primary"
      >
        {showClearAll ? (
          <CheckBoxIcon color="primary" fontSize="small" />
        ) : (
          <CheckBoxOutlineBlankIcon color="action" fontSize="small" />
        )}
        <Typography variant="caption" color="text.primary" sx={{ marginTop: '2px', whiteSpace: 'nowrap' }}>
          {showClearAll ? t('base.ClearAll') : t('base.SelectAll')}
        </Typography>
      </Button>

      <Button
        onClick={handleInvertSelection}
        variant="text"
        size="small"
        sx={{
          textTransform: 'none',
          padding: '2px 4px',
          width: '50%',

          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
        color="primary"
      >
        <Typography variant="caption" color="text.primary" sx={{ marginTop: '2px', whiteSpace: 'nowrap' }}>
          {t('base.InvertSelection')}
        </Typography>
      </Button>
    </Box>
  );
};

export default AccountingReportFiltersActionButtons;
