import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { changePassword } from 'auth';
import { useFormik } from 'formik';
import { useLoading, useUser } from 'hooks';

export const ChangePasswordForm = () => {
  const user = useUser();
  const { t } = useTranslation();
  const { loading, startLoading, endLoading } = useLoading();

  const handleAccept = useCallback(
    async ({ oldPassword, newPassword }: { oldPassword: string; newPassword: string }) => {
      startLoading('changePasswordForm');
      await changePassword(oldPassword, newPassword);
      endLoading('changePasswordForm');
    },
    [],
  );

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPassword2: '',
      isPasswordVisible: false,
    },
    onSubmit: handleAccept,
  });

  const pwd = formik.values.newPassword;
  const passwordCriteria = [
    { key: 'login.passwordCriteriaMinLength', valid: pwd.length >= 16 },
    { key: 'login.passwordCriteria2SmallLetters', valid: /[a-z].*[a-z]/.test(pwd) },
    { key: 'login.passwordCriteria2CapitalLetters', valid: /[A-Z].*[A-Z]/.test(pwd) },
    { key: 'login.passwordCriteria2Numbers', valid: /[0-9].*[0-9]/.test(pwd) },
    { key: 'login.passwordCriteria2SpecialSymbols', valid: /\W.*\W/.test(pwd) },
    { key: 'login.passwordCriteria2SamePasswords', valid: Boolean(pwd) && pwd === formik.values.newPassword2 },
    {
      key: 'login.passwordCriteriaNoMoreThan3SameChar',
      valid: !/(.).*\1.*\1.*\1/.test(pwd),
    },
    {
      key: 'login.passwordCriteriaNo2ConsecutiveSameChars',
      valid: !/(.)\1\1/.test(pwd),
    },
    { key: 'login.passwordCriteriaNotSameAsOld', valid: Boolean(pwd) && pwd !== formik.values.oldPassword },
  ];

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          autoFocus
          autoComplete="email"
          name="email"
          label={t('login.email')}
          fullWidth
          disabled
          value={user.email}
        />
        {[
          { value: formik.values.oldPassword, label: t('login.oldPassword'), key: 'oldPassword' },
          { value: formik.values.newPassword, label: t('login.newPassword'), key: 'newPassword' },
          { value: formik.values.newPassword2, label: t('login.repeatNewPassword'), key: 'newPassword2' },
        ].map(({ value, label, key }, index) => (
          <TextField
            key={key}
            name={key}
            label={label}
            placeholder={t('login.enterPassword')}
            disabled={loading}
            fullWidth
            required
            type={formik.values.isPasswordVisible ? 'text' : 'password'}
            value={value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: index === 0 && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => formik.setFieldValue('isPasswordVisible', !formik.values.isPasswordVisible)}
                  >
                    {formik.values.isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <Paper
          sx={{
            backgroundColor: 'action.hover',
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          variant="outlined"
          square
        >
          {passwordCriteria.map(({ key, valid }) => (
            <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {valid ? <CheckIcon color="success" fontSize="small" /> : <ClearIcon color="error" fontSize="small" />}
              <Typography variant="body2">{t(key)}</Typography>
            </Box>
          ))}
        </Paper>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={loading || passwordCriteria.some(({ valid }) => !valid)}
        >
          {t('base.accept')}
        </Button>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
