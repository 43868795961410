// todo add missing types

import type {
  IButtonInputsFormAppliedFilter,
  IMultiSelectButtonInputState,
  ISelectButtonInputState,
  IStringButtonInputState,
} from 'components';

export const validateStringButtonInput = (value: string, state: IStringButtonInputState) => {
  if (state.operator === 'contains') {
    return state.values.some((v) => value.includes(v));
  }
  if (state.operator === 'equal') {
    return state.values.includes(value);
  }
  if (state.operator === 'notEqual') {
    return !state.values.includes(value);
  }
  return true;
};

export const validateMultiselectButtonInput = (value: string[], state: IMultiSelectButtonInputState) => {
  if (state.operator === 'equal') {
    return value.some((v) => state.values.includes(v));
  }

  if (state.operator === 'notEqual') {
    return !value.some((v) => state.values.includes(v));
  }

  return true;
};

export const validateSelectButtonInput = (value: string, state: ISelectButtonInputState) => {
  return state.value === value;
};

export const validateButtonInput = (value: unknown, input: IButtonInputsFormAppliedFilter) => {
  if (input.type === 'string') {
    return validateStringButtonInput(value as string, input.state as IStringButtonInputState);
  }

  if (input.type === 'select') {
    return validateSelectButtonInput(value as string, input.state as ISelectButtonInputState);
  }

  if (input.type === 'multiselect') {
    return validateMultiselectButtonInput(value as string[], input.state as IMultiSelectButtonInputState);
  }

  throw new Error(`${input.type} button input not implemented`);
};
