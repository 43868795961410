import { useAtomValue } from 'jotai';
import { Box, Grow, Typography } from 'components';
import { type TFilterAtoms } from 'hooks/useAccountingFilters';

interface IProps {
  title: string;
  atoms: TFilterAtoms;
}

export const AccountingReportFilterTitle = ({ title, atoms }: IProps) => {
  const selected = useAtomValue(atoms.selected);
  const data = useAtomValue(atoms.data);

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Typography variant="button" fontWeight="bold">
        {title}
      </Typography>
      <Typography>
        <Grow in={data.length > 0} timeout={200}>
          <Box component="span" sx={{ color: 'primary.main' }}>{` (${selected.length}/${data.length})`}</Box>
        </Grow>
      </Typography>
    </Box>
  );
};

export default AccountingReportFilterTitle;
