import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridApiRef } from '@mui/x-data-grid';
import { useAtomValue, useStore } from 'jotai';
import { DataGridBox, DataGridToolbar, DefaultDataGrid } from 'components';
import { TableExport } from 'components/TableExport';
import {
  appliedFiltersAtom,
  ENormalizeGroups,
  parseAccountingFilters,
  useAccountingData,
  useLoading,
  usePerms,
} from 'hooks';
import { useComputedGridHeight } from 'hooks/useComputedGridHeight';
import { parseDateToUTC } from 'utils';
import { generateColumns } from './generateAccountingReportTableColumns';
import GridFooterSummary from './GridFooterSummary';
import { GET_REPORTS_CSV, GET_REPORTS_XLSX } from './queries';

export const AccountingReportTable = () => {
  const { i18n, t } = useTranslation();
  const apiRef = useGridApiRef();
  const { data, totalDataCount, page, setPage, setSort, sort, summary, groupBy } = useAccountingData();
  const { loading } = useLoading();
  const perms = usePerms();
  const columns = useMemo(() => generateColumns(t, groupBy, perms), [i18n.language, groupBy, perms]);
  const [summaryWithTitle, setSummaryWithTitle] = useState<Record<string, string> | undefined>(undefined);

  const store = useStore();

  const { height } = useComputedGridHeight({ apiRef, additionalRowCount: 1 });

  const appliedFilters = useAtomValue(appliedFiltersAtom);

  useEffect(() => {
    const addTitleToSummary = () => {
      const firstColumnField = apiRef.current?.getVisibleColumns?.()[0]?.field;
      setSummaryWithTitle(
        firstColumnField
          ? { [firstColumnField]: t('accounting.summary'), ...summary }
          : (summary as unknown as Record<string, string>),
      );
    };

    addTitleToSummary();
    return apiRef.current.subscribeEvent('columnVisibilityModelChange', addTitleToSummary);
  }, [summary]);

  return (
    <DataGridBox height={height}>
      <DefaultDataGrid
        rows={data}
        columns={columns}
        apiRef={apiRef}
        loading={loading}
        sortModel={sort}
        onSortModelChange={setSort}
        getCellClassName={(params) => {
          if (params.field === 'ggr' && params.value !== '0.00') {
            return params.value[0] === '-' ? 'ggr-negative' : 'ggr-positive';
          }
          return '';
        }}
        sx={{
          '& .ggr-positive': {
            color: 'success.main',
          },
          '& .ggr-negative': {
            color: 'error.main',
          },
        }}
        slots={{
          toolbar: () => (
            <DataGridToolbar
              localStorageKey="accounting-report"
              additionalColumnElements={summaryWithTitle}
              disableManageColumns={groupBy !== ENormalizeGroups.NONE}
              manageColumnsTooltip={
                groupBy !== ENormalizeGroups.NONE ? t('accounting.manageColumnsDisabledTooltip') : undefined
              }
              defaultHideColumns={['frbCount', 'frbBetTotal', 'frbWinTotal']}
            >
              <TableExport
                groupBy={
                  appliedFilters?.normalizeGroupBy === ENormalizeGroups.NONE
                    ? undefined
                    : appliedFilters?.normalizeGroupBy
                }
                normalizeCurrency={appliedFilters?.normalizeEnabled ? appliedFilters.normalizeCurrency : undefined}
                currencyDate={appliedFilters?.normalizeEnabled ? appliedFilters.currencyDate : undefined}
                filter={{
                  ...(appliedFilters && {
                    filter: parseAccountingFilters(appliedFilters, store),
                    normalized: appliedFilters.normalizeEnabled,
                    baseCurrency: appliedFilters.normalizeCurrency,
                    currencyDate: parseDateToUTC(appliedFilters.currencyDate, appliedFilters.timezone),
                    timezone: appliedFilters.timezone,
                  }),
                }}
                excludedKeys={['action', 'firstTransactionDate', 'lastTransactionDate']}
                authorized={perms.reportsAccountingExport}
                blockConditions={[
                  { if: !appliedFilters, message: t('accounting.applyFiltersBeforeExport') },
                  { if: totalDataCount === 0, message: t('reportExport.noDataToExport') },
                ]}
                queries={[
                  {
                    type: 'csv',
                    query: GET_REPORTS_CSV,
                    key: 'reportTransactionsCsv',
                    label: 'CSV',
                  },
                  {
                    type: 'xlsx',
                    query: GET_REPORTS_XLSX,
                    key: 'reportTransactionsXlsx',
                    label: 'XLSX',
                  },
                ]}
                columnMapperFn={(columns) => {
                  const normalizedColumnsLabelMap: Record<string, string> = {
                    ggr: 'accounting.exportNormalizedGgr',
                    debitTotal: 'accounting.exportNormalizedTotalBet',
                    creditTotal: 'accounting.exportNormalizedTotalWin',
                    refundTotal: 'accounting.exportNormalizedTotalRefund',
                    totalBuyBet: 'accounting.exportNormalizedTotalBuyBet',
                    frbBetTotal: 'accounting.exportNormalizedFrbBetTotal',
                    frbWinTotal: 'accounting.exportNormalizedFrbWinTotal',
                  };
                  const normalizedColumns = [
                    'ggr',
                    'debitTotal',
                    'refundTotal',
                    'creditTotal',
                    'totalBuyBet',
                    'frbBetTotal',
                    'frbWinTotal',
                  ];
                  const basePrefix = 'values.';
                  const normalizedPrefix = 'normalizedValues.';
                  const columnsMappers = columns.reduce(
                    (acc, column) => {
                      if (normalizedColumns.includes(column.field)) {
                        acc.push({
                          field: basePrefix + column.field,
                          label: column.headerName || column.field,
                        });
                        if (appliedFilters?.normalizeEnabled) {
                          acc.push({
                            field: normalizedPrefix + column.field,
                            label: t(normalizedColumnsLabelMap[column.field], {
                              currency: appliedFilters.normalizeCurrency,
                            }),
                          });
                        }
                      } else {
                        acc.push({
                          field: column.field,
                          label: column.headerName || column.field,
                        });
                      }
                      return acc;
                    },
                    [] as { field: string; label: string }[],
                  );
                  return columnsMappers;
                }}
              />
            </DataGridToolbar>
          ),
          footer: () => (
            <GridFooterSummary
              summary={summaryWithTitle}
              totalDataCount={totalDataCount}
              page={page}
              setPage={setPage}
            />
          ),
        }}
      />
    </DataGridBox>
  );
};

export default AccountingReportTable;
