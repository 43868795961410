export const exportTranslations = {
  'values.debitAmount': 'accounting.bet',
  'values.creditAmount': 'accounting.win',
  'values.refundAmount': 'accounting.refund',
  'values.debitTotal': 'accounting.totalBet',
  'values.creditTotal': 'accounting.totalWin',
  'values.refundTotal': 'accounting.totalRefund',
  'normalizedValues.debitAmount': 'accounting.normalizedBet',
  'normalizedValues.creditAmount': 'accounting.normalizedWin',
  'normalizedValues.refundAmount': 'accounting.normalizedRefund',
  'normalizedValues.debitTotal': 'accounting.exportNormalizedTotalBet',
  'normalizedValues.creditTotal': 'accounting.exportNormalizedTotalWin',
  'normalizedValues.refundTotal': 'accounting.exportNormalizedTotalRefund',
};

export const getFilterTableKey = (field: string, normalized: boolean) => {
  const prefix = normalized ? 'normalizedValues' : 'values';

  const modifiedKey = {
    debitAmount: `${prefix}.debitAmount`,
    creditAmount: `${prefix}.creditAmount`,
    refundAmount: `${prefix}.refundAmount`,
    ggr: `${prefix}.ggr`,
    debitTotal: `${prefix}.debitTotal`,
    creditTotal: `${prefix}.creditTotal`,
    refundTotal: `${prefix}.refundTotal`,
    totalBuyBet: `${prefix}.totalBuyBet`,
    frbWinTotal: `${prefix}.frbWinTotal`,
    frbBetTotal: `${prefix}.frbBetTotal`,
  };

  return field in modifiedKey ? modifiedKey[field as keyof typeof modifiedKey] : field;
};
