import { ContainerMenu } from './ContainerMenu';
import { MenuSection } from './MenuSection';
import { useMenuItems } from './useMenuItems';

export const MenuItems = () => {
  const { sections } = useMenuItems();

  return (
    <ContainerMenu>
      {sections.map((section) => {
        return <MenuSection key={section.title} {...section} />;
      })}
    </ContainerMenu>
  );
};

export default MenuItems;
