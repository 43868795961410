import { getDefaultStore } from 'jotai';
import { type TBuildClients, type TBuildClusters, type TBuildOperators } from './accessBuildGroups';
import { type TClientAtomReturn, type TClusterAtomReturn, type TOperatorAtomReturn } from './groupsAtom';
import { createScopes, type IScope } from './permissionMapping';

const store = getDefaultStore();

export type TScopeError = TScopeErrorClusterChild | TScopeErrorClientChild;

export type TScopeErrorClusterChild = { type: 'clusterChildNotSelected'; clusterId: string; clientIds: string[] };

export type TScopeErrorClientChild = { type: 'clientChildNotSelected'; clientId: string; operatorIds: string[] };

export type TScopeReturnState =
  | {
      validationPassed: true;
      value: IScope[];
    }
  | {
      validationPassed: false;
      errors: TScopeError[];
    };

export function getScopes(
  clusterAtoms: TClusterAtomReturn,
  clientAtoms: TClientAtomReturn,
  operatorAtoms: TOperatorAtomReturn,
): TScopeReturnState {
  const clusters = store.get(clusterAtoms.dataVisible);
  const clients = store.get(clientAtoms.dataVisible);
  const operators = store.get(operatorAtoms.dataVisible);

  const errors = validateScopes(clusters, clients, operators);

  if (errors.length > 0) {
    return {
      validationPassed: false,
      errors,
    };
  }

  return { validationPassed: true, value: createScopes({ clusters, clients, operators }) };
}

function validateScopes(clusters: TBuildClusters, clients: TBuildClients, operators: TBuildOperators) {
  const errors: TScopeError[] = [];

  for (const cluster of clusters) {
    if (cluster.checked && !cluster.value.selectAndAllParent) {
      const clusterClients = clients.filter((client) => client.value.clusterId === cluster.id);

      if (clusterClients.every((client) => !client.checked)) {
        errors.push({
          type: 'clusterChildNotSelected',
          clusterId: cluster.id,
          clientIds: clusterClients.filter((client) => !client.checked).map((client) => client.id),
        });
      }
    }
  }

  for (const client of clients) {
    if (client.checked && !client.value.selectAndAllParent) {
      const clientOperators = operators.filter((operator) => operator.value.clientId === client.id);
      if (clientOperators.every((operator) => !operator.checked)) {
        errors.push({
          type: 'clientChildNotSelected',
          clientId: client.id,
          operatorIds: clientOperators.filter((operator) => !operator.checked).map((operator) => operator.id),
        });
      }
    }
  }

  return errors;
}
