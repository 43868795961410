import { type FC, type PropsWithChildren } from 'react';
import { Card, type SxProps, type Theme } from '@mui/material';

interface IFilter {
  sx?: SxProps<Theme>;
}

export const FilterCard: FC<PropsWithChildren<IFilter>> = ({ children, sx }) => {
  return <Card sx={{ paddingInline: 1.5, paddingY: 2, width: '100%', ...sx }}>{children}</Card>;
};

export default FilterCard;
