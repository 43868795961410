import { useTheme } from 'hooks';
import { type ILogoProps } from './LogoCompany';

export const LogoIcon = ({ darkColor, lightColor, height = 18 }: ILogoProps) => {
  const { darkMode, theme } = useTheme();
  const fill = darkMode ? darkColor || theme.palette.primary.main : lightColor || theme.palette.primary.main;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 108.55" height={height}>
      <path
        fill={fill}
        d="m34.37,94.79c2.25,0,4.51-.13,6.76-.39,16.35-1.89,27.9-15.84,26-32.19-.2-1.74-.65-3.4-1.32-4.97,4.75,21.51-28.65,36.14-47.12,25.33,4.07,5.3,9.46,9.53,15.67,12.21Z"
      />
      <path
        fill={fill}
        d="m71.21,91.69c1.18-1.65,2.28-3.37,3.3-5.15,8.16-14.29,3.76-31.86-10.54-40.01-2.37-1.35-4.96-2.12-7.6-2.29,2.25.77,4.38,1.93,6.3,3.45,4.42,3.51,7.22,8.53,7.87,14.14,1.84,15.89-7.53,31.1-22.03,36.14.46.02.92.03,1.38.03,7.85,0,15.17-2.31,21.32-6.29Z"
      />
      <path
        fill={fill}
        d="m39.1,52.27c-.44,1.35.17,2.59,1.82,3.69-.26.85-.4,1.75-.4,2.69,0,4.07,2.61,7.55,6.25,8.84l3.12-4.33-2.33,5.24-.02.07c0,1.35,1.03,2.41,2.34,2.41s2.35-1.06,2.35-2.41l-.3-.67c4.19-.94,7.33-4.68,7.33-9.14,0-.93-.14-1.84-.39-2.69,1.65-1.1,2.26-2.34,1.82-3.69-.47-1.44-2.34-1.75-3.77-1.77,1.21,1.1,2,2.99.85,4.99-.36.62-.9,1.29-1.76,1.88,2.08-2.84,2.17-4.45-1.21-6.71h0c-1.43-.88-3.11-1.39-4.91-1.39s-3.48.51-4.91,1.39h0c-3.38,2.26-3.3,3.87-1.21,6.71-.86-.59-1.4-1.26-1.76-1.88-1.15-2-.36-3.89.85-4.99-1.43.02-3.3.33-3.77,1.77Z"
      />
      <path
        fill={fill}
        d="m48,19.36c-12.47.59-23.44,7.02-30.23,16.61-1.01,4.21-1.51,8.59-1.53,13.03-.06,16.46,12.42,29.57,28.88,29.63,4.73.02,9.18-1.81,12.54-5.14.54-.54,1.04-1.11,1.5-1.7-1.59,1.29-3.46,2.21-5.5,2.7-1.89.45-3.8.63-5.69.56-.77,0-1.54-.07-2.31-.21-2.79-.41-5.5-1.39-7.98-2.91-4.81-2.96-8.18-7.61-9.49-13.1-3.9-16.36,4.6-33.45,19.79-39.46Z"
      />
      <path
        fill={fill}
        d="m10.56,58.65c0,5.28,1.05,10.32,2.94,14.92.44.45.89.9,1.35,1.34,8.21,7.87,19.39,10.3,29.27,7.13-17.07-.55-31.63-13.65-32.92-30.46-.42,2.29-.64,4.66-.64,7.07Z"
      />
      <path
        fill={fill}
        d="m62.95,37.01c-.06.02-.24.09-.49.19,10.43.53,20.34,5.72,26.19,14.69-.86-4.91-2.63-9.52-5.13-13.63-3.85-1.76-11.63-4.26-20.56-1.25Z"
      />
      <path
        fill={fill}
        d="m88.9,63.72c-.75-2.52-1.71-4.96-2.9-7.27-6.21-12.07-18.95-18.19-31.44-15.73,3.8-.09,7.72.9,11.11,2.83,13.37,7.63,20.09,23.25,16.54,37.5,3.51-5.05,5.87-10.95,6.69-17.33Z"
      />
      <path
        fill={fill}
        d="m68.9,12.51c.31,1.01.53,2.02.67,3.01,16.35,7.49,27.73,24,27.73,43.12,0,26.14-21.27,47.4-47.4,47.4S2.49,84.79,2.49,58.65,23.76,11.25,49.9,11.25c2.95,0,5.84.28,8.65.8-.1.74-.23,1.47-.39,2.21-2.68-.5-5.44-.76-8.25-.76C25,13.49,4.74,33.75,4.74,58.65s20.26,45.16,45.16,45.16,45.16-20.26,45.16-45.16c0-17.78-10.33-33.19-25.3-40.55,0,.79-.06,1.57-.18,2.31,13.85,7.15,23.34,21.61,23.34,38.24,0,23.72-19.3,43.01-43.01,43.01S6.89,82.37,6.89,58.65,26.18,15.64,49.9,15.64c2.62,0,5.19.24,7.68.69-1.21,3.68-3.49,6.98-7.6,8.65-8.3,3.37-9.83,7.92-10.04,10.68-.73,3.04-2.02,3.03-4.1,5.52-3.42,3.37-3.54,8.01-3.19,10.02.6-1.35,2.66-4.2,5.08-5.32,0,0,4.1-2.3,8.82-2.55,0,0,5.75.05,6.78-4.55,2.85-.02,5.93-.56,8.26-3.59.43-.56.79-.66,1.28-.82,10.37-3.22,18.57.23,20.95,1.35-5.7-6.14-13.71-7.14-19.88-7.01,0,0,4.64-1.75,10.24-1.16-4.35-3.43-9.09-2.49-9.09-2.49,3.08-2.44,3.63-8.55,1.04-14.21-.94,11.3-4.91,12.19-4.91,12.19,5.6-11.79-3.08-23.02-3.08-23.02,0,0,.93,4.38.64,9.55-2.88-.52-5.85-.8-8.88-.8C22.38,8.75,0,31.14,0,58.65s22.38,49.9,49.9,49.9,49.9-22.38,49.9-49.9c0-20.79-12.78-38.65-30.9-46.14Z"
      />
    </svg>
  );
};

export default LogoIcon;
