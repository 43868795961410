import { type IGroupItem } from 'components/CheckboxGroup/GroupItem';

export type TBuildClusters = ReturnType<typeof buildClusters>;
export type TBuildClients = ReturnType<typeof buildClients>;
export type TBuildOperators = ReturnType<typeof buildOperators>;

export interface IGroupListData {
  clusters: {
    id: string;
    displayName: string;
    name: string;
  }[];

  clients: {
    id: string;
    displayName: string;
    name: string;
    clusterId: string;
  }[];

  operators: {
    id: string;
    displayName: string;
    name: string;
    clientId: string;
  }[];
}

export interface ITooltipDisplayName {
  tooltip?: {
    name: string;
    parentName?: string;
  };
}

export interface IGroupReturnValues {
  clusters: ({ id: string; name: string } & ITooltipDisplayName)[];
  clients: ({ id: string; name: string; clusterId: string } & ITooltipDisplayName)[];
  operators: ({ id: string; name: string; clusterId: string; clientId: string } & ITooltipDisplayName)[];
}

export function buildClusters<T extends { id: string; name: string }>(
  items: T[],
): IGroupItem<T & { selectAndAllParent: boolean }>[] {
  return items.map((item) => {
    return {
      id: item.id,
      value: { ...item, selectAndAllParent: false },
      checked: false,
      title: item.name,
    };
  });
}

export function buildClients<T extends { id: string; name: string; clusterId: string }>(
  items: T[],
  clusters: ReturnType<typeof buildClusters>,
): IGroupItem<T & { selectAndAllParent: boolean }>[] {
  return items.map((item) => {
    const cluster = clusters.find((cluster) => cluster.id === item.clusterId);

    return {
      id: item.id,
      value: { ...item, selectAndAllParent: false },
      checked: false,
      title: item.name,
      ...(cluster && { tooltip: { name: item.name, parentName: cluster.title } }),
    };
  });
}

export function buildOperators<T extends { id: string; name: string; clusterId: string; clientId: string }>(
  items: T[],
  clusters: ReturnType<typeof buildClusters>,
): IGroupItem<T>[] {
  return items.map((item) => {
    const cluster = clusters.find((cluster) => cluster.id === item.clusterId);

    return {
      id: item.id,
      value: item,
      checked: false,
      title: item.name,
      ...(cluster && { tooltip: { name: item.name, parentName: cluster.title } }),
    };
  });
}

export const buildGroupValues = (accessTree: IGroupListData): IGroupReturnValues => {
  return {
    clusters: accessTree.clusters.map((cluster) => ({
      id: cluster.id,
      name: cluster.displayName || cluster.name || cluster.id,
    })),
    clients: accessTree.clients.map((client) => {
      const name = getDisplayName(client);

      return {
        id: client.id,
        name,
        clusterId: client.clusterId,
      };
    }),
    operators: accessTree.operators.map((operator) => {
      const client = accessTree.clients.find((client) => client.id === operator.clientId)!;
      const name = getDisplayName(operator);

      return {
        id: operator.id,
        name,
        clientId: operator.clientId,
        clusterId: client.clusterId,
      };
    }),
  };
};

export const getDisplayName = ({ displayName, name, id }: { displayName?: string; name?: string; id: string }) => {
  return displayName || name || id;
};
