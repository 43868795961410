import { useTheme } from 'hooks';
import { type ILogoProps } from './LogoCompany';

export const LogoTitle = ({ darkColor, lightColor, height = 18 }: ILogoProps) => {
  const { darkMode, theme } = useTheme();
  const fill = darkMode ? darkColor || theme.palette.primary.main : lightColor || theme.palette.primary.main;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1004 108.55" height={height}>
      <path
        fill={fill}
        d="m1003.74,10.14h0c-.24-.48-.61-.83-1.1-1.07-.45-.22-1-.33-1.65-.33h-69.56l-2.57,23.84,5.98.64.3-1.75c.04-.22.1-.54.19-.94.09-.4.22-.88.39-1.41,1.14-3.59,3.6-7.88,8.84-7.88h42.56c-3.57,5.61-7.74,12.47-12.11,19.91-.57.97-1.14,1.95-1.72,2.93-4.08,6.99-8.21,14.29-11.95,21.11-1.1,2-2.17,3.98-3.21,5.92-1.05,1.94-2.06,3.86-3.04,5.73-.98,1.87-1.93,3.69-2.82,5.45-4.87,9.57-7.96,16.45-9.18,20.46l-.74,2.44h22.17l.17-1.7c.27-2.65,1.03-6.19,2.26-10.52,1.16-4.07,2.73-8.82,4.67-14.1.75-2.05,1.55-4.15,2.38-6.28.83-2.13,1.71-4.31,2.61-6.5,2.53-6.16,5.3-12.48,8.23-18.79,1.17-2.51,2.35-4.99,3.52-7.41,1.28-2.63,2.63-5.32,4.03-8.03.67-1.3,1.34-2.57,2-3.8.49-.92.98-1.81,1.46-2.69,2.47-4.49,4.74-8.27,6.73-11.22l.23-.33c.48-.66,1.14-1.55,1.16-2.58,0-.39-.08-.77-.26-1.12Z"
      />
      <path
        fill={fill}
        d="m209.27,13.91l-.44-.14c-.22-.07-.43-.14-.66-.2-2.26-.64-4.54-.97-6.79-.97h-40.16l-11.53-.16v2.49l.55.05c.07,0,7.45.66,9.61,3.67.51.71.76,1.5.76,2.36v76.52c0,2.07-1.47,3.71-4.37,4.88-2.26.91-4.56,1.15-4.59,1.16l-.53.05v2.48l10.09-.17h15.07l10.1.17v-2.48l-.54-.05s-.91-.1-2.12-.39c-2.55-.61-6.83-2.15-6.83-5.65V16.95h16.74c4.68,0,9.32,2.06,12.73,5.64,1.3,1.36,2.4,2.92,3.28,4.63.91,1.79,1.57,3.72,1.95,5.74.27,1.41.4,2.87.4,4.36,0,.71-.02,1.4-.07,2.07-.18,2.55-.71,4.94-1.57,7.1-.61,1.51-1.39,2.93-2.32,4.21-.59.82-1.25,1.61-1.98,2.34-2.56,2.61-5.97,4.72-10.13,6.27-.88.32-1.79.63-2.78.92-.47.13-.93.26-1.39.38-1.71.44-3.5.79-5.34,1.06-1.15.17-2.35.31-3.56.42-.73.06-1.42.11-2.05.15-.63.04-1.21.06-1.71.08l-.58.02v2.8h.6c22.43,0,34.92-2.92,41.75-9.77,4.41-4.41,6.45-10.47,6.43-19.06-.02-10.44-6.93-19.02-18.03-22.4Z"
      />
      <path
        fill={fill}
        d="m471.99,41.08c-7.95-11.11-20.74-17.23-36-17.23-2.29,0-4.58.16-6.8.47-.74.1-1.47.22-2.2.36-1.43.27-2.87.61-4.28,1.02-2.09.6-4.13,1.35-6.08,2.22-15.61,6.98-25.31,21.65-25.31,38.28,0,12.11,5.64,23.46,15.47,31.13,3.87,3.02,8.28,5.38,13.09,7,5.05,1.7,10.47,2.56,16.11,2.56,7.05,0,14.09-1.67,20.36-4.83,2.46-1.24,4.81-2.71,6.98-4.37,1.09-.83,2.14-1.72,3.13-2.65,1.5-1.39,2.91-2.91,4.19-4.5.86-1.07,1.67-2.19,2.41-3.33.37-.57.74-1.16,1.08-1.76,3.62-6.23,5.46-13.12,5.46-20.46,0-7.99-2.25-15.7-6.5-22.31-.36-.55-.72-1.1-1.11-1.63Zm-11.57,46.95h0c-.24.46-.48.9-.74,1.33-.54.91-1.13,1.79-1.81,2.69-.36.48-.74.93-1.12,1.37-.47.54-.95,1.06-1.45,1.55-.64.63-1.33,1.23-2.04,1.78-1.73,1.35-3.62,2.46-5.64,3.3-1.56.65-3.22,1.16-4.93,1.49-.85.16-1.71.29-2.56.37-.84.08-1.71.12-2.59.12-21.45,0-31.22-20.54-31.22-39.62,0-6.06.89-11.46,2.65-16.07,1-2.61,2.28-5,3.82-7.08.3-.4.61-.79.92-1.17,4.94-5.96,12.33-9.38,20.29-9.38,8.99,0,16.26,3.36,21.62,9.98,1.03,1.27,1.98,2.67,2.85,4.17,1.11,1.91,2.09,4.03,2.92,6.28.79,2.16,1.45,4.47,1.96,6.88.85,4.03,1.28,8.29,1.28,12.67,0,7.52-1.41,14.03-4.19,19.35Z"
      />
      <path
        fill={fill}
        d="m688.5,25.76l-11.43-.16v2.44l.54.05s1.05.09,2.44.37c2.93.59,7.84,2.08,7.84,5.42v47.22l-59.63-58.96v74.92c0,4.83-10.18,5.78-10.28,5.79l-.54.05v2.44l11.42-.16h7.11l11.44.16v-2.44l-.54-.05c-.1,0-10.29-.96-10.29-5.79v-47.2l38.77,36.82,21.22,21.87V33.87c0-1.24.66-2.3,2.03-3.24.46-.32.99-.61,1.58-.87.26-.11.53-.23.79-.33.43-.16.86-.31,1.29-.44l.55-.16c.49-.13.96-.24,1.41-.34,1.5-.31,2.63-.42,2.65-.42l.54-.05v-2.43l-11.29.15h-7.61Z"
      />
      <path
        fill={fill}
        d="m895.7,102.45c-1.99-.3-7.29-1.08-10.36-5.92l-25.21-34.18,21.97-28.18c4.62-5.23,12.95-6.21,13.04-6.22l.53-.06v-2.43l-8.44.16h-7.44l-12.6-.16v2.47s.57.02.57.02c.05,0,4.59.2,5.96,2.39.52.82.52,1.83-.01,3.08l-18.27,23.21-17.1-23.13c-.56-1.29-.57-2.32-.05-3.16,1.38-2.19,5.92-2.39,5.97-2.39l.57-.02v-2.47l-12.59.16h-15.5l-6.56-.17v2.3l.37.15c.15.06.35.09.81.16,1.99.3,7.29,1.08,10.36,5.92l24.14,32.7.83,1.05-22.64,28.77c-4.62,5.21-12.95,6.2-13.03,6.21l-.53.06v2.42l8.44-.16h6.37l13.68.16v-2.46l-.57-.02s-2.75-.12-4.64-1.2c-.66-.38-1.13-.82-1.41-1.33-.1-.18-.17-.37-.22-.57-.05-.22-.08-.44-.08-.68,0-.09.12-.63.69-1.47l18.59-23.84,17.39,23.38c.56,1.29.57,2.32.04,3.16-1.38,2.19-5.92,2.39-5.97,2.39l-.57.02v2.47l12.59-.16h15.5l6.56.17v-2.3l-.37-.15c-.15-.06-.35-.09-.82-.16Z"
      />
      <path
        fill={fill}
        d="m342.92,26.35l-.15-.59h-13.17l-9.75-.16v2.43l.53.05c.05,0,4.96.54,7.36,2.92.84.84,1.25,1.78,1.25,2.87v26.9h-41.1v-26.9c0-1.09.41-2.03,1.25-2.87,1.4-1.39,3.66-2.15,5.31-2.55,1.17-.28,2.04-.37,2.04-.37l.53-.05v-2.43l-9.74.16h-13.17l-9.75-.16v2.43l.53.05s.88.09,2.04.37c1.65.39,3.91,1.15,5.31,2.55.84.84,1.25,1.78,1.25,2.87v63.18c0,4.84-8.52,5.78-8.61,5.79l-.53.05v2.43l9.74-.16h13.17l9.75.16v-2.43l-.53-.05c-.09,0-8.61-.95-8.61-5.79v-29.98h41.1v29.98c0,1.78-1.14,3.23-3.38,4.32-2.4,1.17-5.2,1.47-5.23,1.47l-.53.05v2.43l9.74-.16h13.17l9.75.16v-2.43l-.53-.05s-1.71-.18-3.59-.81c-2.29-.76-5.02-2.25-5.02-4.98v-63.18c0-1.3.59-2.39,1.8-3.34.34-.26.73-.51,1.16-.75.26-.14.52-.27.78-.38,1.07-.48,2.13-.77,2.82-.94,1.17-.28,2.04-.37,2.04-.37l.53-.05v-2.43l-9.6.16v.59Z"
      />
      <path
        fill={fill}
        d="m580.34,87.03c-.27.81-1.32,3.68-3.44,6.6-1.85,2.55-4.88,5.59-8.83,5.59h-27.26v-31.83h16.13c.57,0,1.12.04,1.62.11.93.13,1.78.4,2.51.78.33.18.65.38.95.61,3.44,2.69,2.82,8.2,2.81,8.25l-.08.67h4.07s-.16-11.02-.16-11.02v-5.46l.16-11.02h-4.07l.08.67s.29,2.51-.55,4.94c-.1.3-.23.61-.37.91-.3.63-.68,1.19-1.11,1.68-.49.54-1.07.98-1.72,1.33-.73.38-1.58.65-2.51.78-.5.07-1.05.11-1.62.11h-16.13v-29.04h26.04c1.09,0,2.23.57,3.39,1.7,2.15,2.11,3.68,5.43,4.33,7.64.08.27.15.54.21.79.06.25.1.48.14.7l.09.57,3.18-.38-2.02-16.96h-49.09l-9.75-.16v2.43l.53.05s.87.09,2.04.37c1.65.39,3.91,1.16,5.31,2.55.84.84,1.25,1.78,1.25,2.87v63.18c0,4.84-8.52,5.78-8.61,5.79l-.53.05v2.43l9.74-.16h51.48l5.24-17.72-3.26-.96-.18.55Z"
      />
      <path
        fill={fill}
        d="m752.38,25.76l-9.75-.16v2.43l.53.05s.87.09,2.04.37c1.65.39,3.91,1.16,5.31,2.55.84.84,1.25,1.78,1.25,2.87v63.18c0,4.84-8.52,5.78-8.61,5.79l-.53.05v2.43l9.74-.16h13.14l9.75.16v-2.43l-.53-.05c-.09,0-8.61-.95-8.61-5.79v-63.18c0-1.09.41-2.03,1.25-2.87,1.4-1.39,3.66-2.15,5.31-2.55,1.17-.28,2.04-.37,2.04-.37l.53-.05v-2.43l-9.74.16h-13.14Z"
      />
    </svg>
  );
};

export default LogoTitle;
