import { type TFunction } from 'i18next';

export enum ERoundTypes {
  BuyFeature = 'BUY_FEATURE',
  FrbFreeSpin = 'FRB_FREE_SPIN',
  FrbRegular = 'FRB_REGULAR',
  FreeSpin = 'FREE_SPIN',
  Regular = 'REGULAR',
  Unknown = 'UNKNOWN',
  WonFeature = 'WON_FEATURE',
}

const roundTypes = [
  { translateKey: 'playerBrowser.buyFeatureSpin', id: ERoundTypes.BuyFeature },
  { translateKey: 'playerBrowser.frbFreeSpin', id: ERoundTypes.FrbFreeSpin },
  { translateKey: 'playerBrowser.frbRegular', id: ERoundTypes.FrbRegular },
  { translateKey: 'playerBrowser.freeSpin', id: ERoundTypes.FreeSpin },
  { translateKey: 'playerBrowser.regularSpin', id: ERoundTypes.Regular },
  { translateKey: 'playerBrowser.unknownSpin', id: ERoundTypes.Unknown },
  { translateKey: 'playerBrowser.wonFeatureSpin', id: ERoundTypes.WonFeature },
];

const roundTypeTranslationMap = new Map(roundTypes.map(({ id, translateKey }) => [id as unknown, translateKey]));

export const parseRoundType = (roundType: string, t: TFunction) => {
  const translationKey = roundTypeTranslationMap.get(roundType) as string;
  return t(translationKey);
};

export const getRoundTypeOptions = (t: TFunction) =>
  roundTypes.map(({ id, translateKey }) => ({ name: t(translateKey), id }));
