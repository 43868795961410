import { useTranslation } from 'react-i18next';
import { type TFilter } from 'components/ReportExport/types';
import { TableExport } from 'components/TableExport';
import { exportTranslations } from 'components/TableExport/translationMap';
import { buildNormalizedMappers } from 'components/TableExport/utils';
import { getLimitExportBlockCondition } from 'utils/limitExport';
import { GET_COMBINED_TRANSACTIONS_CSV, GET_COMBINED_TRANSACTIONS_XLSX } from './exportQueries';

export const PlayerBrowserExport = ({ filter, totalCount }: { filter: TFilter; totalCount: number }) => {
  const { t } = useTranslation();

  return (
    <TableExport
      filter={{ filter }}
      excludedKeys={['action']}
      blockConditions={[
        { if: !filter || totalCount === 0, message: t('reportExport.noDataToExport') },
        getLimitExportBlockCondition(totalCount, t),
      ]}
      columnMapperFn={buildNormalizedMappers(
        {
          normalizeCurrency: filter && (filter.baseCurrency as string),
          affectedFields: ['debitTotal', 'creditTotal', 'refundTotal'],
          prefixes: ['normalizedValues', 'values'],
          translations: exportTranslations,
        },
        t,
      )}
      queries={[
        {
          type: 'csv',
          query: GET_COMBINED_TRANSACTIONS_CSV,
          key: 'combinedTransactionsByPlayersCsv',
          label: 'CSV',
        },
        {
          type: 'xlsx',
          query: GET_COMBINED_TRANSACTIONS_XLSX,
          key: 'combinedTransactionsByPlayersXlsx',
          label: 'XLSX',
        },
      ]}
      normalizeCurrency={filter?.baseCurrency as string}
      currencyDate={filter?.currencyDate as string}
    />
  );
};
