import { type ReactNode } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { type TPerm } from 'auth';
import { type TMenuParentSection } from 'routing';
import { createNavigationItems } from 'routing/routing.utils';

export type TMenuItem = { route: string; menuSection: TMenuParentSection; title: string };

export type TMenuSection = {
  title: TMenuParentSection;
  icon: ReactNode;
  routes: TMenuItem[];
};

export const generateMenuData = (perms: TPerm): TMenuSection[] => {
  const items = createNavigationItems(perms);

  return [
    {
      title: 'menu.Reports',
      icon: <AssignmentIcon fontSize="small" />,
      routes: items.filter((item) => item.menuSection === 'menu.Reports'),
    },
    {
      title: 'menu.Management',
      icon: <ManageAccountsIcon fontSize="small" />,
      routes: items.filter((item) => item.menuSection === 'menu.Management'),
    },
  ];
};
