import { gql } from '@apollo/client';

export const GET_REPORTS_CSV = gql`
  query ReportTransactionsCsv(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $groupBy: String
    $columnsMappers: [ReportColumnMapperInput!]!
    $normalization: NormalizationInput
    $timezone: String
  ) {
    reportTransactionsCsv(
      columnsMappers: $columnsMappers
      filter: $filter
      groupBy: $groupBy
      normalization: $normalization
      orderBy: $orderBy
      orderDirection: $orderDirection
      timezone: $timezone
    ) {
      url
    }
  }
`;

export const GET_REPORTS_XLSX = gql`
  query ReportTransactionsXlsx(
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $groupBy: String
    $columnsMappers: [ReportColumnMapperInput!]!
    $normalization: NormalizationInput
    $timezone: String
  ) {
    reportTransactionsXlsx(
      columnsMappers: $columnsMappers
      filter: $filter
      groupBy: $groupBy
      normalization: $normalization
      orderBy: $orderBy
      orderDirection: $orderDirection
      timezone: $timezone
    ) {
      url
    }
  }
`;
