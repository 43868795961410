import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Box, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { type TBaseInput } from 'components/ButtonInputsForm';
import { PopperMenu } from 'components/PopperMenu';
import { PopperSelect } from 'components/PopperSelect';
import { ENormalizePossibleCurrencies } from 'hooks/index';

type TCurrency = 'EUR' | 'NONE';

export interface INormalizeInputState {
  selected: TCurrency;
  currencyDate?: string;
}

interface IProps {
  onToggle?: (open: boolean) => void;
}

const NormalizeCurrencyInput = ({ atom, label, disabled, onToggle }: TBaseInput<IProps, INormalizeInputState>) => {
  const [state, setState] = useAtom(atom);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [uiState, setUiState] = useState(state);

  const { t } = useTranslation();

  useEffect(() => {
    if (!open) {
      setUiState(state);
    }
    onToggle?.(open);
  }, [open, onToggle]);

  const options = useMemo(
    () => [
      {
        key: 'NONE',
        label: t('accounting.OriginalCurrencies'),
      },
      ...Object.values(ENormalizePossibleCurrencies).map((currency) => {
        return {
          key: currency,
          label: `${t('accounting.NormalizeTo')} ${currency}`,
        };
      }),
    ],
    [],
  );

  const handleClear = () => {
    const cleanState = {
      ...uiState,
      selected: 'NONE' as TCurrency,
      currencyDate: undefined,
    };
    setUiState(cleanState);
    setState(cleanState);
  };

  const handleAccept = () => {
    setState(uiState);
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={disabled}
        ref={buttonRef}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: state.selected && state.selected !== 'NONE' ? 'main.primary' : 'text.primary',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        onClick={() => setOpen(true)}
      >
        {label}
        {state.selected &&
          state.selected !== 'NONE' &&
          ` = ${options.find((currency) => currency.key === state.selected)?.key}`}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ marginLeft: 1 }} />
        )}
      </Button>
      <PopperMenu open={open} anchorEl={buttonRef.current} onClose={() => setOpen(false)} placement="bottom-start">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1 }}>
          <PopperSelect
            value={uiState.selected}
            size="small"
            onChange={(newValue) => {
              setUiState({ ...uiState, selected: newValue as TCurrency });
            }}
            options={options}
          ></PopperSelect>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box onClick={(e) => e.stopPropagation()}>
              <DatePicker
                label={label}
                slotProps={{ textField: { size: 'small' } }}
                value={uiState.currencyDate ? dayjs(uiState.currencyDate) : dayjs().subtract(1, 'day')}
                onChange={(date) => {
                  if (date) {
                    setUiState({ ...uiState, currencyDate: date.format('YYYY-MM-DD') });
                  }
                }}
                disabled={disabled}
                minDate={dayjs().subtract(1, 'year')}
                maxDate={dayjs()}
              />
            </Box>
          </LocalizationProvider>

          <Box sx={{ alignSelf: 'flex-end', display: 'flex', gap: 1 }}>
            {state.selected !== 'NONE' && (
              <Button onClick={handleClear} variant="outlined" size="small">
                {t('base.clear')}
              </Button>
            )}
            <Button onClick={handleAccept} variant="outlined" size="small">
              {t('base.accept')}
            </Button>
          </Box>
        </Box>
      </PopperMenu>
    </>
  );
};

export default NormalizeCurrencyInput;
