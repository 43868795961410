import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonInputForm, type IButtonInputsFormAppliedFilter, type IButtonInputsFormInputType } from 'components';
import { EUserRole, EUserStatus, useButtonInputsFormQueryParams, useDefaultFiltersData } from 'hooks';

const generateInputsData = () => {
  const data: { type: IButtonInputsFormInputType; key: string; translateKey: string }[] = [
    { type: 'string', key: 'email', translateKey: 'team.email' },
    { type: 'select', key: 'role', translateKey: 'team.role' },
    { type: 'select', key: 'status', translateKey: 'team.status' },
    { type: 'multiselect', key: 'clustersIds', translateKey: 'playerBrowser.clusters' },
    { type: 'multiselect', key: 'clientsIds', translateKey: 'playerBrowser.clients' },
    { type: 'multiselect', key: 'operatorsIds', translateKey: 'playerBrowser.operators' },
  ];

  return data;
};

const initialShowingInputs = ['email', 'role', 'status', 'clustersIds', 'clientsIds', 'operatorsIds'];

interface IProps {
  onAccept: (filters: IButtonInputsFormAppliedFilter[]) => void;
}

export const TeamMembersFilters = ({ onAccept }: IProps) => {
  const { filtersData, loading } = useDefaultFiltersData('fetchTeamMembersFilters');
  const { setQueryParams, initialState } = useButtonInputsFormQueryParams(window.location.search);
  const [showingInputs, setShowingInputs] = useState(() => {
    return [...initialShowingInputs, ...Object.keys(initialState).filter((key) => !initialShowingInputs.includes(key))];
  });
  const { t } = useTranslation();
  const inputsData = useMemo(() => generateInputsData(), []);

  const handleAccept = useCallback((filters: IButtonInputsFormAppliedFilter[]) => {
    setQueryParams(filters);
    onAccept(filters);
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
      <ButtonInputForm
        acceptOnChange
        acceptOnInit
        inputsData={inputsData}
        showingInputs={showingInputs}
        setShowingInputs={setShowingInputs}
        initialState={initialState}
        loading={loading}
        selectOptions={{
          role: [
            { name: t('team.areaAdmin'), id: EUserRole.AREA_ADMIN },
            { name: t('team.admin'), id: EUserRole.ADMIN },
            { name: t('team.accountManager'), id: EUserRole.ACCOUNT_MANAGER },
            { name: t('team.support'), id: EUserRole.SUPPORT },
          ],
          status: [
            { name: t('team.active'), id: EUserStatus.ACTIVE },
            { name: t('team.inactive'), id: EUserStatus.INACTIVE },
            { name: t('team.pending'), id: EUserStatus.PENDING },
          ],
          clustersIds: filtersData?.clusters || [],
          clientsIds: filtersData?.clients || [],
          operatorsIds: filtersData?.operators || [],
        }}
        onAccept={handleAccept}
      />
    </Box>
  );
};

export default TeamMembersFilters;
