import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  PopperMenu,
  type IButtonInputsFormInputData,
} from 'components';

interface IProps {
  inputsData: Readonly<IButtonInputsFormInputData[]>;
  showingInputs: string[];
  toggleInput: (inputKey: string) => void;
}

export const ButtonInputsFormMoreButton = ({ inputsData, showingInputs, toggleInput }: IProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button ref={buttonRef} variant="outlined" sx={{ minWidth: 36, width: 36 }} onClick={() => setOpen((o) => !o)}>
        <AddIcon />
      </Button>
      <PopperMenu open={open} anchorEl={buttonRef.current} onClose={() => setOpen(false)} placement="bottom-start">
        <List
          sx={{
            overflow: 'auto',
            maxHeight: 240,
            minHeight: 60,
          }}
        >
          {inputsData.map((input) => (
            <ListItem key={input.key} disablePadding>
              <ListItemButton dense onClick={() => toggleInput(input.key)}>
                <Checkbox checked={showingInputs.includes(input.key)} edge="start" size="small" />
                <ListItemText primary={t(input.translateKey)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </PopperMenu>
    </>
  );
};

export default ButtonInputsFormMoreButton;
