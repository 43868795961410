import { useTheme } from 'hooks';

export interface ILogoProps {
  darkColor?: string;
  lightColor?: string;
  height?: string | number;
}

export const LogoCompany = ({ darkColor, lightColor, height = 18 }: ILogoProps) => {
  const { darkMode, theme } = useTheme();
  const fill = darkMode ? darkColor || theme.palette.primary.main : lightColor || theme.palette.primary.main;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1004 108.55" height={height}>
      <path
        fill={fill}
        d="m34.37,94.79c2.25,0,4.51-.13,6.76-.39,16.35-1.89,27.9-15.84,26-32.19-.2-1.74-.65-3.4-1.32-4.97,4.75,21.51-28.65,36.14-47.12,25.33,4.07,5.3,9.46,9.53,15.67,12.21Z"
      />
      <path
        fill={fill}
        d="m71.21,91.69c1.18-1.65,2.28-3.37,3.3-5.15,8.16-14.29,3.76-31.86-10.54-40.01-2.37-1.35-4.96-2.12-7.6-2.29,2.25.77,4.38,1.93,6.3,3.45,4.42,3.51,7.22,8.53,7.87,14.14,1.84,15.89-7.53,31.1-22.03,36.14.46.02.92.03,1.38.03,7.85,0,15.17-2.31,21.32-6.29Z"
      />
      <path
        fill={fill}
        d="m39.1,52.27c-.44,1.35.17,2.59,1.82,3.69-.26.85-.4,1.75-.4,2.69,0,4.07,2.61,7.55,6.25,8.84l3.12-4.33-2.33,5.24-.02.07c0,1.35,1.03,2.41,2.34,2.41s2.35-1.06,2.35-2.41l-.3-.67c4.19-.94,7.33-4.68,7.33-9.14,0-.93-.14-1.84-.39-2.69,1.65-1.1,2.26-2.34,1.82-3.69-.47-1.44-2.34-1.75-3.77-1.77,1.21,1.1,2,2.99.85,4.99-.36.62-.9,1.29-1.76,1.88,2.08-2.84,2.17-4.45-1.21-6.71h0c-1.43-.88-3.11-1.39-4.91-1.39s-3.48.51-4.91,1.39h0c-3.38,2.26-3.3,3.87-1.21,6.71-.86-.59-1.4-1.26-1.76-1.88-1.15-2-.36-3.89.85-4.99-1.43.02-3.3.33-3.77,1.77Z"
      />
      <path
        fill={fill}
        d="m48,19.36c-12.47.59-23.44,7.02-30.23,16.61-1.01,4.21-1.51,8.59-1.53,13.03-.06,16.46,12.42,29.57,28.88,29.63,4.73.02,9.18-1.81,12.54-5.14.54-.54,1.04-1.11,1.5-1.7-1.59,1.29-3.46,2.21-5.5,2.7-1.89.45-3.8.63-5.69.56-.77,0-1.54-.07-2.31-.21-2.79-.41-5.5-1.39-7.98-2.91-4.81-2.96-8.18-7.61-9.49-13.1-3.9-16.36,4.6-33.45,19.79-39.46Z"
      />
      <path
        fill={fill}
        d="m10.56,58.65c0,5.28,1.05,10.32,2.94,14.92.44.45.89.9,1.35,1.34,8.21,7.87,19.39,10.3,29.27,7.13-17.07-.55-31.63-13.65-32.92-30.46-.42,2.29-.64,4.66-.64,7.07Z"
      />
      <path
        fill={fill}
        d="m62.95,37.01c-.06.02-.24.09-.49.19,10.43.53,20.34,5.72,26.19,14.69-.86-4.91-2.63-9.52-5.13-13.63-3.85-1.76-11.63-4.26-20.56-1.25Z"
      />
      <path
        fill={fill}
        d="m88.9,63.72c-.75-2.52-1.71-4.96-2.9-7.27-6.21-12.07-18.95-18.19-31.44-15.73,3.8-.09,7.72.9,11.11,2.83,13.37,7.63,20.09,23.25,16.54,37.5,3.51-5.05,5.87-10.95,6.69-17.33Z"
      />
      <path
        fill={fill}
        d="m68.9,12.51c.31,1.01.53,2.02.67,3.01,16.35,7.49,27.73,24,27.73,43.12,0,26.14-21.27,47.4-47.4,47.4S2.49,84.79,2.49,58.65,23.76,11.25,49.9,11.25c2.95,0,5.84.28,8.65.8-.1.74-.23,1.47-.39,2.21-2.68-.5-5.44-.76-8.25-.76C25,13.49,4.74,33.75,4.74,58.65s20.26,45.16,45.16,45.16,45.16-20.26,45.16-45.16c0-17.78-10.33-33.19-25.3-40.55,0,.79-.06,1.57-.18,2.31,13.85,7.15,23.34,21.61,23.34,38.24,0,23.72-19.3,43.01-43.01,43.01S6.89,82.37,6.89,58.65,26.18,15.64,49.9,15.64c2.62,0,5.19.24,7.68.69-1.21,3.68-3.49,6.98-7.6,8.65-8.3,3.37-9.83,7.92-10.04,10.68-.73,3.04-2.02,3.03-4.1,5.52-3.42,3.37-3.54,8.01-3.19,10.02.6-1.35,2.66-4.2,5.08-5.32,0,0,4.1-2.3,8.82-2.55,0,0,5.75.05,6.78-4.55,2.85-.02,5.93-.56,8.26-3.59.43-.56.79-.66,1.28-.82,10.37-3.22,18.57.23,20.95,1.35-5.7-6.14-13.71-7.14-19.88-7.01,0,0,4.64-1.75,10.24-1.16-4.35-3.43-9.09-2.49-9.09-2.49,3.08-2.44,3.63-8.55,1.04-14.21-.94,11.3-4.91,12.19-4.91,12.19,5.6-11.79-3.08-23.02-3.08-23.02,0,0,.93,4.38.64,9.55-2.88-.52-5.85-.8-8.88-.8C22.38,8.75,0,31.14,0,58.65s22.38,49.9,49.9,49.9,49.9-22.38,49.9-49.9c0-20.79-12.78-38.65-30.9-46.14Z"
      />
      <path
        fill={fill}
        d="m1003.74,10.14h0c-.24-.48-.61-.83-1.1-1.07-.45-.22-1-.33-1.65-.33h-69.56l-2.57,23.84,5.98.64.3-1.75c.04-.22.1-.54.19-.94.09-.4.22-.88.39-1.41,1.14-3.59,3.6-7.88,8.84-7.88h42.56c-3.57,5.61-7.74,12.47-12.11,19.91-.57.97-1.14,1.95-1.72,2.93-4.08,6.99-8.21,14.29-11.95,21.11-1.1,2-2.17,3.98-3.21,5.92-1.05,1.94-2.06,3.86-3.04,5.73-.98,1.87-1.93,3.69-2.82,5.45-4.87,9.57-7.96,16.45-9.18,20.46l-.74,2.44h22.17l.17-1.7c.27-2.65,1.03-6.19,2.26-10.52,1.16-4.07,2.73-8.82,4.67-14.1.75-2.05,1.55-4.15,2.38-6.28.83-2.13,1.71-4.31,2.61-6.5,2.53-6.16,5.3-12.48,8.23-18.79,1.17-2.51,2.35-4.99,3.52-7.41,1.28-2.63,2.63-5.32,4.03-8.03.67-1.3,1.34-2.57,2-3.8.49-.92.98-1.81,1.46-2.69,2.47-4.49,4.74-8.27,6.73-11.22l.23-.33c.48-.66,1.14-1.55,1.16-2.58,0-.39-.08-.77-.26-1.12Z"
      />
      <path
        fill={fill}
        d="m209.27,13.91l-.44-.14c-.22-.07-.43-.14-.66-.2-2.26-.64-4.54-.97-6.79-.97h-40.16l-11.53-.16v2.49l.55.05c.07,0,7.45.66,9.61,3.67.51.71.76,1.5.76,2.36v76.52c0,2.07-1.47,3.71-4.37,4.88-2.26.91-4.56,1.15-4.59,1.16l-.53.05v2.48l10.09-.17h15.07l10.1.17v-2.48l-.54-.05s-.91-.1-2.12-.39c-2.55-.61-6.83-2.15-6.83-5.65V16.95h16.74c4.68,0,9.32,2.06,12.73,5.64,1.3,1.36,2.4,2.92,3.28,4.63.91,1.79,1.57,3.72,1.95,5.74.27,1.41.4,2.87.4,4.36,0,.71-.02,1.4-.07,2.07-.18,2.55-.71,4.94-1.57,7.1-.61,1.51-1.39,2.93-2.32,4.21-.59.82-1.25,1.61-1.98,2.34-2.56,2.61-5.97,4.72-10.13,6.27-.88.32-1.79.63-2.78.92-.47.13-.93.26-1.39.38-1.71.44-3.5.79-5.34,1.06-1.15.17-2.35.31-3.56.42-.73.06-1.42.11-2.05.15-.63.04-1.21.06-1.71.08l-.58.02v2.8h.6c22.43,0,34.92-2.92,41.75-9.77,4.41-4.41,6.45-10.47,6.43-19.06-.02-10.44-6.93-19.02-18.03-22.4Z"
      />
      <path
        fill={fill}
        d="m471.99,41.08c-7.95-11.11-20.74-17.23-36-17.23-2.29,0-4.58.16-6.8.47-.74.1-1.47.22-2.2.36-1.43.27-2.87.61-4.28,1.02-2.09.6-4.13,1.35-6.08,2.22-15.61,6.98-25.31,21.65-25.31,38.28,0,12.11,5.64,23.46,15.47,31.13,3.87,3.02,8.28,5.38,13.09,7,5.05,1.7,10.47,2.56,16.11,2.56,7.05,0,14.09-1.67,20.36-4.83,2.46-1.24,4.81-2.71,6.98-4.37,1.09-.83,2.14-1.72,3.13-2.65,1.5-1.39,2.91-2.91,4.19-4.5.86-1.07,1.67-2.19,2.41-3.33.37-.57.74-1.16,1.08-1.76,3.62-6.23,5.46-13.12,5.46-20.46,0-7.99-2.25-15.7-6.5-22.31-.36-.55-.72-1.1-1.11-1.63Zm-11.57,46.95h0c-.24.46-.48.9-.74,1.33-.54.91-1.13,1.79-1.81,2.69-.36.48-.74.93-1.12,1.37-.47.54-.95,1.06-1.45,1.55-.64.63-1.33,1.23-2.04,1.78-1.73,1.35-3.62,2.46-5.64,3.3-1.56.65-3.22,1.16-4.93,1.49-.85.16-1.71.29-2.56.37-.84.08-1.71.12-2.59.12-21.45,0-31.22-20.54-31.22-39.62,0-6.06.89-11.46,2.65-16.07,1-2.61,2.28-5,3.82-7.08.3-.4.61-.79.92-1.17,4.94-5.96,12.33-9.38,20.29-9.38,8.99,0,16.26,3.36,21.62,9.98,1.03,1.27,1.98,2.67,2.85,4.17,1.11,1.91,2.09,4.03,2.92,6.28.79,2.16,1.45,4.47,1.96,6.88.85,4.03,1.28,8.29,1.28,12.67,0,7.52-1.41,14.03-4.19,19.35Z"
      />
      <path
        fill={fill}
        d="m688.5,25.76l-11.43-.16v2.44l.54.05s1.05.09,2.44.37c2.93.59,7.84,2.08,7.84,5.42v47.22l-59.63-58.96v74.92c0,4.83-10.18,5.78-10.28,5.79l-.54.05v2.44l11.42-.16h7.11l11.44.16v-2.44l-.54-.05c-.1,0-10.29-.96-10.29-5.79v-47.2l38.77,36.82,21.22,21.87V33.87c0-1.24.66-2.3,2.03-3.24.46-.32.99-.61,1.58-.87.26-.11.53-.23.79-.33.43-.16.86-.31,1.29-.44l.55-.16c.49-.13.96-.24,1.41-.34,1.5-.31,2.63-.42,2.65-.42l.54-.05v-2.43l-11.29.15h-7.61Z"
      />
      <path
        fill={fill}
        d="m895.7,102.45c-1.99-.3-7.29-1.08-10.36-5.92l-25.21-34.18,21.97-28.18c4.62-5.23,12.95-6.21,13.04-6.22l.53-.06v-2.43l-8.44.16h-7.44l-12.6-.16v2.47s.57.02.57.02c.05,0,4.59.2,5.96,2.39.52.82.52,1.83-.01,3.08l-18.27,23.21-17.1-23.13c-.56-1.29-.57-2.32-.05-3.16,1.38-2.19,5.92-2.39,5.97-2.39l.57-.02v-2.47l-12.59.16h-15.5l-6.56-.17v2.3l.37.15c.15.06.35.09.81.16,1.99.3,7.29,1.08,10.36,5.92l24.14,32.7.83,1.05-22.64,28.77c-4.62,5.21-12.95,6.2-13.03,6.21l-.53.06v2.42l8.44-.16h6.37l13.68.16v-2.46l-.57-.02s-2.75-.12-4.64-1.2c-.66-.38-1.13-.82-1.41-1.33-.1-.18-.17-.37-.22-.57-.05-.22-.08-.44-.08-.68,0-.09.12-.63.69-1.47l18.59-23.84,17.39,23.38c.56,1.29.57,2.32.04,3.16-1.38,2.19-5.92,2.39-5.97,2.39l-.57.02v2.47l12.59-.16h15.5l6.56.17v-2.3l-.37-.15c-.15-.06-.35-.09-.82-.16Z"
      />
      <path
        fill={fill}
        d="m342.92,26.35l-.15-.59h-13.17l-9.75-.16v2.43l.53.05c.05,0,4.96.54,7.36,2.92.84.84,1.25,1.78,1.25,2.87v26.9h-41.1v-26.9c0-1.09.41-2.03,1.25-2.87,1.4-1.39,3.66-2.15,5.31-2.55,1.17-.28,2.04-.37,2.04-.37l.53-.05v-2.43l-9.74.16h-13.17l-9.75-.16v2.43l.53.05s.88.09,2.04.37c1.65.39,3.91,1.15,5.31,2.55.84.84,1.25,1.78,1.25,2.87v63.18c0,4.84-8.52,5.78-8.61,5.79l-.53.05v2.43l9.74-.16h13.17l9.75.16v-2.43l-.53-.05c-.09,0-8.61-.95-8.61-5.79v-29.98h41.1v29.98c0,1.78-1.14,3.23-3.38,4.32-2.4,1.17-5.2,1.47-5.23,1.47l-.53.05v2.43l9.74-.16h13.17l9.75.16v-2.43l-.53-.05s-1.71-.18-3.59-.81c-2.29-.76-5.02-2.25-5.02-4.98v-63.18c0-1.3.59-2.39,1.8-3.34.34-.26.73-.51,1.16-.75.26-.14.52-.27.78-.38,1.07-.48,2.13-.77,2.82-.94,1.17-.28,2.04-.37,2.04-.37l.53-.05v-2.43l-9.6.16v.59Z"
      />
      <path
        fill={fill}
        d="m580.34,87.03c-.27.81-1.32,3.68-3.44,6.6-1.85,2.55-4.88,5.59-8.83,5.59h-27.26v-31.83h16.13c.57,0,1.12.04,1.62.11.93.13,1.78.4,2.51.78.33.18.65.38.95.61,3.44,2.69,2.82,8.2,2.81,8.25l-.08.67h4.07s-.16-11.02-.16-11.02v-5.46l.16-11.02h-4.07l.08.67s.29,2.51-.55,4.94c-.1.3-.23.61-.37.91-.3.63-.68,1.19-1.11,1.68-.49.54-1.07.98-1.72,1.33-.73.38-1.58.65-2.51.78-.5.07-1.05.11-1.62.11h-16.13v-29.04h26.04c1.09,0,2.23.57,3.39,1.7,2.15,2.11,3.68,5.43,4.33,7.64.08.27.15.54.21.79.06.25.1.48.14.7l.09.57,3.18-.38-2.02-16.96h-49.09l-9.75-.16v2.43l.53.05s.87.09,2.04.37c1.65.39,3.91,1.16,5.31,2.55.84.84,1.25,1.78,1.25,2.87v63.18c0,4.84-8.52,5.78-8.61,5.79l-.53.05v2.43l9.74-.16h51.48l5.24-17.72-3.26-.96-.18.55Z"
      />
      <path
        fill={fill}
        d="m752.38,25.76l-9.75-.16v2.43l.53.05s.87.09,2.04.37c1.65.39,3.91,1.16,5.31,2.55.84.84,1.25,1.78,1.25,2.87v63.18c0,4.84-8.52,5.78-8.61,5.79l-.53.05v2.43l9.74-.16h13.14l9.75.16v-2.43l-.53-.05c-.09,0-8.61-.95-8.61-5.79v-63.18c0-1.09.41-2.03,1.25-2.87,1.4-1.39,3.66-2.15,5.31-2.55,1.17-.28,2.04-.37,2.04-.37l.53-.05v-2.43l-9.74.16h-13.14Z"
      />
    </svg>
  );
};

export default LogoCompany;
