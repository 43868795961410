import { useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, ListItem, ListItemButton, ListItemIcon, Typography } from 'components';
import { type IAccountingFilterData } from 'hooks/useAccountingFilters';
import { FILTER_ITEM_HEIGHT } from './AccountingReportFiltersList';

interface IListItemProps {
  item: IAccountingFilterData & { optionalName?: string };
  onClick: () => void;
  initialChecked: boolean;
  shouldSkipAnimation: (item: IAccountingFilterData) => boolean;
  itemsVisibleCount?: number;
}

export const AccountingReportListItem = ({ item, onClick, initialChecked, shouldSkipAnimation }: IListItemProps) => {
  const [touched, setTouched] = useState(false);

  const withAnimationClick = (cb: () => void) => {
    if (shouldSkipAnimation(item)) {
      cb();
    } else {
      setTouched(true);
      setTimeout(cb, 500);
    }
  };

  return (
    <ListItem
      disablePadding
      sx={{
        p: '0 6px',
        height: touched ? 0 : FILTER_ITEM_HEIGHT,
        transition: 'transform 1000ms, opacity 1000ms, height 600ms, background-color 600ms',
        opacity: touched ? 0 : 1,
        transform: `translateY(${touched ? 50 * (initialChecked ? 1 : -1) : 0}px)`,
        userSelect: 'none',
      }}
      onClick={() => {
        withAnimationClick(onClick);
      }}
    >
      <ListItemButton
        sx={{ p: 0 }}
        title={item?.tooltip ? `${item.tooltip.name} (${item.tooltip.parentName})` : item.name}
      >
        <ListItemIcon sx={{ minWidth: 24 }}>
          {((!initialChecked && !touched) || (initialChecked && touched)) && (
            <CheckBoxOutlineBlankIcon color="action" fontSize="small" />
          )}
          {((initialChecked && !touched) || (!initialChecked && touched)) && (
            <CheckBoxIcon color="primary" fontSize="small" />
          )}
        </ListItemIcon>

        <Box sx={{ height: FILTER_ITEM_HEIGHT, overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex', pt: 0.25 }}>
          <Typography variant={'body2'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {item.name}
          </Typography>

          {item.optionalName && (
            <Typography
              variant={'caption'}
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', ml: 1 }}
              color="grey.400"
            >
              ({item.optionalName})
            </Typography>
          )}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default AccountingReportListItem;
