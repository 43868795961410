import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { ListItemIcon, ListItemText, MenuItem } from 'components';

interface IProps {
  onActivate: () => void;
  onDeactive: () => void;
  onDelete: () => void;
}

export const TeamMembersBulkActions = ({ onActivate, onDeactive, onDelete }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      {(
        [
          {
            key: 'activate',
            color: 'success',
            text: t('team.activateSelected'),
            onClick: onActivate,
            Icon: PersonIcon,
          },
          {
            key: 'deactivate',
            color: 'info',
            text: t('team.deactivateSelected'),
            onClick: onDeactive,
            Icon: PersonOffIcon,
          },
          {
            key: 'delete',
            color: 'error',
            text: t('team.deleteSelected'),
            onClick: onDelete,
            Icon: DeleteIcon,
          },
        ] as const
      ).map(({ key, color, text, onClick, Icon }) => (
        <MenuItem key={key} onClick={() => onClick()}>
          <ListItemIcon>
            <Icon color={color} />
          </ListItemIcon>
          <ListItemText>{text}</ListItemText>
        </MenuItem>
      ))}
    </>
  );
};

export default TeamMembersBulkActions;
