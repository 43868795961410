import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Group } from 'components/CheckboxGroup/Group';
import { useTheme } from 'hooks/useTheme';
import { type TOperatorAtomReturn } from './accessesFeature/groupsAtom';

export const TeamOperatorsGroup: FC<{
  loading: boolean;
  atoms: TOperatorAtomReturn;
  failedValidationIds?: string[];
}> = ({ loading, atoms, failedValidationIds = [] }) => {
  const { t } = useTranslation();

  const { theme } = useTheme();

  const operators = useAtomValue(atoms.dataVisible);

  const select = useSetAtom(atoms.select);
  const invert = useSetAtom(atoms.invert);
  const clear = useSetAtom(atoms.clear);

  return (
    <Group
      title={t('accounting.operators')}
      items={operators}
      onClear={clear}
      onInvert={invert}
      onSelect={select}
      loading={loading}
      itemProps={(item) => {
        if (failedValidationIds.includes(item.id) && !item.checked) {
          return {
            sx: {
              color: theme.palette.error.light,
            },
          };
        }
      }}
    />
  );
};

export default TeamOperatorsGroup;
