import { gql } from '@apollo/client';

export const GET_COMBINED_TRANSACTIONS_CSV = gql`
  query GetCombinedTransactionsCsv(
    $columnsMappers: [ReportColumnMapperInput!]!
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $timezone: String
    $normalization: NormalizationInput
  ) {
    combinedTransactionsByPlayersCsv(
      columnsMappers: $columnsMappers
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      timezone: $timezone
      normalization: $normalization
    ) {
      url
    }
  }
`;

export const GET_COMBINED_TRANSACTIONS_XLSX = gql`
  query GetCombinedTransactionsXlsx(
    $columnsMappers: [ReportColumnMapperInput!]!
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $timezone: String
    $normalization: NormalizationInput
  ) {
    combinedTransactionsByPlayersXlsx(
      columnsMappers: $columnsMappers
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      timezone: $timezone
      normalization: $normalization
    ) {
      url
    }
  }
`;

export const GET_BETS_CSV = gql`
  query GetBetsCsv(
    $columnsMappers: [ReportColumnMapperInput!]!
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $timezone: String
    $normalization: NormalizationInput
  ) {
    betsCsv(
      columnsMappers: $columnsMappers
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      timezone: $timezone
      normalization: $normalization
    ) {
      url
    }
  }
`;

export const GET_BETS_XLSX = gql`
  query GetBetsXlsx(
    $columnsMappers: [ReportColumnMapperInput!]!
    $filter: JSONObject
    $orderBy: String
    $orderDirection: String
    $timezone: String
    $normalization: NormalizationInput
  ) {
    betsXlsx(
      columnsMappers: $columnsMappers
      filter: $filter
      orderBy: $orderBy
      orderDirection: $orderDirection
      timezone: $timezone
      normalization: $normalization
    ) {
      url
    }
  }
`;
