import { gql } from '@apollo/client';

export const GET_TRANSACTIONS_QUERY = gql`
  query ReportTransactions(
    $filter: JSONObject
    $groupBy: String
    $orderBy: String
    $orderDirection: String
    $skip: Int
    $take: Int
    $normalization: NormalizationInput
    $normalized: Boolean!
  ) {
    reportTransactions(
      filter: $filter
      groupBy: $groupBy
      normalization: $normalization
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      take: $take
    ) {
      items {
        transactionsCount
        country
        currency
        slotLabel
        operatorLabel
        clusterLabel
        clientLabel
        totalBuyCount
        day
        frbCount
        normalizedValues @include(if: $normalized) {
          ggr
          creditTotal
          debitTotal
          refundTotal
          totalBuyBet
          frbBetTotal
          frbWinTotal
        }
        values @skip(if: $normalized) {
          ggr
          creditTotal
          debitTotal
          refundTotal
          totalBuyBet
          frbBetTotal
          frbWinTotal
        }
      }
      summary @include(if: $normalized) {
        ggrSummary
        debitTotalSummary
        creditTotalSummary
        refundTotalSummary
        totalBuyBetSummary
        totalBuyCountSummary
        transactionsCountSummary
        frbBetTotalSummary
        frbCountSummary
        frbWinTotalSummary
      }
      totalCount
      skip
      take
      groupBy
      normalization {
        baseCurrency
        currencyDate
      }
    }
  }
`;
