import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOffTwoTone } from '@mui/icons-material';
import { useSetAtom } from 'jotai';
import { Button } from 'components';
import * as atoms from 'hooks/useAccountingFilters/accountingFiltersAtoms';

export const AccountingReportFiltersClearButton = () => {
  const { t } = useTranslation();

  const setClusters = useSetAtom(atoms.clustersAtoms.selected);
  const setClients = useSetAtom(atoms.clientsAtoms.selected);
  const setOperators = useSetAtom(atoms.operatorsAtoms.selected);
  const setGames = useSetAtom(atoms.gamesAtoms.selected);
  const setCurrencies = useSetAtom(atoms.currenciesAtoms.selected);
  const setCountries = useSetAtom(atoms.countriesAtoms.selected);

  const clear = useCallback(() => {
    setClusters([]);
    setClients([]);
    setOperators([]);
    setGames([]);
    setCurrencies([]);
    setCountries([]);
  }, []);

  return (
    <Button onClick={clear} variant="outlined" endIcon={<SearchOffTwoTone />} fullWidth>
      {t('base.clear')}
    </Button>
  );
};

export default AccountingReportFiltersClearButton;
