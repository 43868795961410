import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useAtom } from 'jotai';
import {
  Box,
  Button,
  Chip,
  LogicOperatorButton,
  PopperMenu,
  PopperSelect,
  TextField,
  Typography,
  type ILogicOperator,
  type TBaseInput,
} from 'components';
import { isUuid } from 'utils';

type Operator = 'equal' | 'notEqual' | 'contains';

export interface IStringButtonInputState {
  operator: Operator;
  values: string[];
}

enum EErrors {
  UUID,
}

interface IProps {
  onToggle?: (open: boolean) => void;
  uuidValue?: boolean;
}

export const StringButtonInput = ({
  label,
  onToggle,
  atom,
  disabled,
  uuidValue,
}: TBaseInput<IProps, IStringButtonInputState>) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useAtom(atom);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<EErrors | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const logicOperators = useMemo(() => {
    const operators = [
      { sign: '=', label: t('inputButtons.equal'), key: 'equal' },
      { sign: '≠', label: t('inputButtons.notEqual'), key: 'notEqual' },
    ];
    if (!uuidValue) {
      operators.push({ sign: '∈', label: t('inputButtons.contains'), key: 'contains' });
    }
    return operators as ILogicOperator<Operator>[];
  }, [uuidValue, i18n.language]);

  useEffect(() => {
    if (open) {
      setTimeout(() => inputRef.current?.focus());
      setInputValue('');
    } else {
      setError(null);
    }
    onToggle?.(open);
  }, [open, onToggle]);

  const handleAdd = useCallback(() => {
    const value = inputValue.trim();
    if (uuidValue && !isUuid(value)) {
      setError(EErrors.UUID);
      return;
    }
    setState((prevState) => {
      if (prevState.values.includes(value)) {
        return prevState;
      }
      return { ...prevState, values: [...prevState.values, value] };
    });
    setError(null);
    setInputValue('');
  }, [inputValue]);

  const handleClear = useCallback(() => {
    setInputValue('');
    setState((prevState) => ({ ...prevState, values: [], operator: 'equal' }));
  }, []);

  const handleDelete = useCallback((value: string) => {
    setState((prevState) => ({ ...prevState, values: prevState.values.filter((el) => el !== value) }));
  }, []);

  return (
    <>
      <Button
        ref={buttonRef}
        disabled={disabled}
        variant="outlined"
        sx={{
          textTransform: 'none',
          color: state.values.length ? 'main.primary' : 'text.primary',
          paddingLeft: 1,
          paddingRight: 1,
        }}
        onClick={() => setOpen(true)}
      >
        {label}
        {state.values.length > 0 && (
          <>
            <LogicOperatorButton
              operators={logicOperators}
              value={state.operator}
              onChange={(operator) => setState((prevState) => ({ ...prevState, operator }))}
            />
            <Typography noWrap variant="inherit" sx={{ maxWidth: 150 }}>
              {state.values[0]}
            </Typography>
            {state.values.length > 1 && state.operator !== 'contains' && (
              <Chip
                size="small"
                color="primary"
                label={'+' + (state.values.length - 1)}
                sx={{ height: 18, marginLeft: 0.5 }}
              />
            )}
          </>
        )}
        {open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ marginLeft: 1 }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ marginLeft: 1 }} />
        )}
      </Button>

      <PopperMenu
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        placement="bottom-start"
        paperProps={{ sx: { padding: 1, display: 'flex', flexDirection: 'column', gap: 1, width: 300 } }}
      >
        <PopperSelect
          value={state.operator}
          options={logicOperators.map((operator) => ({
            key: operator.key,
            label: `${label} ${operator.sign} (${operator.label})`,
          }))}
          onChange={(value) => setState((prevState) => ({ ...prevState, operator: value as Operator }))}
        />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <TextField
            error={error === EErrors.UUID}
            multiline
            size="small"
            value={inputValue}
            onChange={(evt) => setInputValue(evt.target.value)}
            inputRef={inputRef}
            fullWidth
            placeholder={t('inputButtons.typeValue')}
            helperText={
              (error === EErrors.UUID && t('inputButtons.notUuid')) ||
              (state.operator === 'contains' && state.values.length > 1 && t('inputButtons.containsOnlyFirstValue'))
            }
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
          {state.values.map((value, index) => (
            <Chip
              key={value}
              label={value}
              size="small"
              onDelete={() => handleDelete(value)}
              color={state.operator === 'contains' && index === 0 ? 'primary' : 'default'}
            />
          ))}
        </Box>
        <Box sx={{ alignSelf: 'flex-end', display: 'flex', gap: 1 }}>
          {state.values.length > 0 && (
            <Button onClick={handleClear} variant="outlined" size="small">
              {t('base.clear')}
            </Button>
          )}
          <Button onClick={handleAdd} variant="outlined" size="small">
            {t('base.add')}
          </Button>
        </Box>
      </PopperMenu>
    </>
  );
};

export default StringButtonInput;
