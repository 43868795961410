import { useTranslation } from 'react-i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  checked?: boolean;
  onClick?: () => void;
}

export const FilterButton = ({ checked, onClick }: IProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('base.toggleFilters')}>
      <ToggleButtonGroup value={checked} onClick={onClick} color="primary" exclusive size="small">
        <ToggleButton value={true}>
          <FilterListIcon sx={{ mr: 1 }} />
          {t('base.filters')}
        </ToggleButton>
      </ToggleButtonGroup>
    </Tooltip>
  );
};

export default FilterButton;
