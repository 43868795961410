import { getDefaultStore } from 'jotai';
import type {
  IButtonInputsFormAppliedFilter,
  IDateButtonInputState,
  IMultiSelectButtonInputState,
  INumberButtonInputState,
  ISelectButtonInputState,
  IStringButtonInputState,
} from 'components';
import { type INormalizeInputState } from 'components/NormalizeCurrencyInput';
import { userPreferencesAtom } from 'hooks/useUserPreferences';
import { parseDateToUTC } from './parseDateToUTC';

const operatorMaps: Record<string, string> = {
  greater: 'gt',
  less: 'lt',
  greaterOrEqual: 'gte',
  lessOrEqual: 'lte',
};

export const parseButtonInputsFormToWhere = (
  filters: IButtonInputsFormAppliedFilter[],
  keysMap: Record<string, string> = {},
) => {
  const timezone = getDefaultStore().get(userPreferencesAtom).timezone;

  return filters.reduce(
    (acc, filter) => {
      const key = keysMap[filter.key] || filter.key;

      if (filter.type === 'select') {
        const state = filter.state as ISelectButtonInputState;
        acc[key] = state.value;
      } else if (filter.type === 'multiselect') {
        const state = filter.state as IMultiSelectButtonInputState;
        if (state.operator === 'equal') {
          acc[key] = { in: state.values };
        } else if (state.operator === 'notEqual') {
          acc[key] = { notIn: state.values };
        }
      } else if (filter.type === 'string' || filter.type === 'uuid') {
        const state = filter.state as IStringButtonInputState;
        if (state.operator === 'equal') {
          acc[key] = { in: state.values };
        } else if (state.operator === 'notEqual') {
          acc[key] = { notIn: state.values };
        } else if (state.operator === 'contains') {
          acc[key] = { contains: state.values[0] };
        }
      } else if (filter.type === 'number') {
        const state = filter.state as INumberButtonInputState;
        const mappedOperator = operatorMaps[state.operator];
        if (mappedOperator) {
          acc[key] = { [mappedOperator]: state.value };
        } else if (state.operator === 'equal') {
          acc[key] = state.value;
        } else if (state.operator === 'between') {
          acc[key] = { gte: state.value, lte: state.value2 };
        }
      } else if (filter.type === 'date') {
        const state = filter.state as IDateButtonInputState;
        const mappedOperator = operatorMaps[state.operator];
        const valueWithTimezone = state.value && parseDateToUTC(state.value, timezone);
        const value2WithTimezone = state.value2 && parseDateToUTC(state.value2, timezone, true);
        if (mappedOperator) {
          acc[key] = { [mappedOperator]: valueWithTimezone };
        } else if (state.operator === 'between') {
          acc[key] = { gte: valueWithTimezone, lte: value2WithTimezone };
        }
      } else if (filter.type === 'currencyNormalization') {
        const state = filter.state as INormalizeInputState;
        acc['currencyDate'] = state.currencyDate;
        acc['baseCurrency'] = state.selected;
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );
};
