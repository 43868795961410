import { gql } from '@apollo/client';
import { t } from 'i18next';
import { parseBigNumber, parseCountryCode, parseCurrencyNumber, parseDateStringTz } from 'utils';
import { type ICombinedTransactionsByPlayersSummary } from './PlayerBrowserTable';

interface ICombinedTransaction {
  creditTotal?: number;
  debitTotal?: number;
  refundTotal?: number;
  totalBuyBet?: number;
  valuesCurrency?: number;
}

interface IRawRecord {
  id: string;
  externalId: string;
  playerId: string;
  playerInternalId: string;
  playerExternalId: string;
  operatorLabel: string;
  clusterLabel?: string;
  clientLabel?: string;
  currency: string;
  country: string;
  tester: boolean;
  totalBuyCount: number;
  totalSpinCount: number;
  firstTransactionDate: string;
  lastTransactionDate: string;
  values: ICombinedTransaction;
  normalizedValues: ICombinedTransaction;
}

export interface IPlayerBrowserRawData {
  combinedTransactionsByPlayers?: {
    items: IRawRecord[];
    skip: number;
    take: number;
    totalCount: number;
    summary: ICombinedTransactionsByPlayersSummary;
  };
}

export const GET_PLAYERS = gql`
  query GetPlayers(
    $where: JSONObject
    $skip: Int
    $take: Int
    $orderBy: String
    $orderDirection: String
    $normalization: NormalizationInput
    $normalized: Boolean!
  ) {
    combinedTransactionsByPlayers(
      filter: $where
      normalization: $normalization
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      take: $take
    ) {
      totalCount
      skip
      take
      summary @include(if: $normalized) {
        creditTotalSummary
        debitTotalSummary
        refundTotalSummary
        totalBuyCountSummary
        totalSpinCountSummary
      }
      items {
        playerId
        playerExternalId
        playerInternalId
        operatorLabel
        clusterLabel
        clientLabel
        currency
        country
        tester
        firstTransactionDate
        lastTransactionDate
        totalBuyCount
        totalSpinCount
        values @skip(if: $normalized) {
          debitTotal
          creditTotal
          refundTotal
        }
        normalizedValues @include(if: $normalized) {
          debitTotal
          creditTotal
          refundTotal
        }
      }
    }
  }
`;

export type TParsedPlayerBrowserData = ReturnType<typeof parsePlayerBrowserData>;

export const parsePlayerBrowserData = (
  rawData: IPlayerBrowserRawData,
  lang: string,
  timezone: string,
  baseCurrency: string | null | unknown,
) => {
  if (!rawData.combinedTransactionsByPlayers) {
    return { totalCount: 0, data: [], summary: {} };
  }

  return {
    totalCount: rawData.combinedTransactionsByPlayers.totalCount,
    summary: rawData.combinedTransactionsByPlayers.summary,
    data: rawData.combinedTransactionsByPlayers.items.map((item) => {
      return {
        id: crypto.randomUUID(),
        playerId: item.playerId,
        playerInternalId: item.playerInternalId,
        playerExternalId: item.playerExternalId,
        clusterLabel: item.clusterLabel,
        operatorLabel: item.operatorLabel,
        clientLabel: item.clientLabel,
        currency: item.currency,
        country: parseCountryCode(item.country, lang),
        totalBuyCount: parseBigNumber(item.totalBuyCount),
        totalSpinCount: parseBigNumber(item.totalSpinCount),
        firstTransactionDate: parseDateStringTz(item.firstTransactionDate, timezone),
        lastTransactionDate: parseDateStringTz(item.lastTransactionDate, timezone),
        tester: item.tester ? t('base.Yes') : t('base.No'),
        ...(typeof baseCurrency === 'string'
          ? {
              debitTotal: parseCurrencyNumber(item.normalizedValues?.debitTotal, baseCurrency),
              creditTotal: parseCurrencyNumber(item.normalizedValues?.creditTotal, baseCurrency),
              refundTotal: parseCurrencyNumber(item.normalizedValues?.refundTotal, baseCurrency),
            }
          : {
              debitTotal: parseCurrencyNumber(item.values?.debitTotal),
              creditTotal: parseCurrencyNumber(item.values?.creditTotal),
              refundTotal: parseCurrencyNumber(item.values?.refundTotal),
            }),
      };
    }),
  };
};
