import { getDisplayName } from 'views/TeamMembersUser/accessesFeature/accessBuildGroups';
import { type IRawFilterData } from './useDefaultFiltersData';

const getDefaultFilterItem = ({ id, displayName, name }: { id: string; displayName?: string; name?: string }) => {
  return {
    id,
    name: displayName || name || id,
  };
};

export const mapClients = (clients: IRawFilterData['clients'], clusters: IRawFilterData['clusters']) => {
  return (clients || []).map((client) => {
    const cluster = clusters?.find((cluster) => cluster.id === client.clusterId);
    const c = getDefaultFilterItem(client);
    return { ...c, parentId: cluster?.id, tooltip: { name: c.name, parentName: getDisplayName(cluster as never) } };
  });
};

export const mapOperators = (
  operators: IRawFilterData['operators'],
  clients: IRawFilterData['clients'],
  clusters: IRawFilterData['clusters'],
) => {
  return operators.map((operator) => {
    const client = clients?.find((client) => client.id === operator.clientId);
    const cluster = clusters?.find((cluster) => cluster.id === client?.clusterId);

    const o = getDefaultFilterItem(operator);
    return { ...o, parentId: client?.id, tooltip: { name: o.name, parentName: getDisplayName(cluster as never) } };
  });
};

export const defaultMap = (el: IRawFilterData) => getDefaultFilterItem(el);
