import { atom, type Atom, type Getter, type Setter, type WritableAtom } from 'jotai';
import { type IGroupItem } from 'components/CheckboxGroup/GroupItem';
import { EUserRole, type IUser } from 'hooks/useUser';
import { type TBuildClients, type TBuildClusters, type TBuildOperators } from './accessBuildGroups';
import { type IScope } from './permissionMapping';

interface ICheckedAtom {
  action: 'select' | 'invert' | 'clear';
  onCompleted?: (get: Getter, set: Setter, ids: string[]) => void;
}

const parentKey = 'selectAndAllParent';

export function createGroup<T extends { id: string; name: string }>(config: {
  dataAtom: WritableAtom<
    IGroupItem<T & { selectAndAllParent?: boolean }>[],
    [IGroupItem<T & { selectAndAllParent?: boolean }>[]],
    void
  >;
  buildFn: (items: T[], permissions: IScope[], get: Getter) => IGroupItem<T & { selectAndAllParent?: boolean }>[];
  dataVisibleFn: (get: Getter) => IGroupItem<T>[];
  selectAllAndNew?: (get: Getter, set: Setter, id: string, shouldCheck: boolean) => void;
  onItemsClear?: (get: Getter, set: Setter, ids: string[]) => void;
  canUserSelectAllAndNew?: (get: Getter, user: IUser, id: string) => boolean;
}) {
  const { dataAtom, buildFn, dataVisibleFn, selectAllAndNew, onItemsClear, canUserSelectAllAndNew } = config;

  return {
    data: dataAtom,
    set: atom(null, (get, set, items: T[], permissions: IScope[]) => set(dataAtom, buildFn(items, permissions, get))),
    invert: atomSetCheckedState(dataAtom, { action: 'invert', onCompleted: onItemsClear }),
    clear: atomSetCheckedState(dataAtom, { action: 'clear', onCompleted: onItemsClear }),
    select: atomSetCheckedState(dataAtom, { action: 'select' }),
    dataVisible: atom((get) => dataVisibleFn(get).sort(compareFnGroup)),

    selectAllAndNew: selectAllAndNew
      ? atom(null, (get, set, id: string, shouldCheck: boolean) => {
          selectAllAndNew(get, set, id, shouldCheck);
        })
      : undefined,
    canUserSelectAllAndNew: canUserSelectAllAndNew
      ? atom(null, (get, _, user: IUser, id: string) => {
          return canUserSelectAllAndNew(get, user, id);
        })
      : undefined,
  };
}

export function atomSetCheckedState<T>(
  atomToUpdate: Atom<IGroupItem<{ [parentKey]?: boolean } | object>[]>,
  { action, onCompleted }: ICheckedAtom,
) {
  return atom(null, (get, set, ids: string[]) => {
    const items = get(atomToUpdate);
    const updatedItems = items.map((item) => (ids.includes(item.id) ? updateItemBasedOnAction(item, action) : item));
    set(atomToUpdate as WritableAtom<T, unknown[], unknown>, updatedItems);

    onCompleted?.(get, set, ids);
  });
}

function updateItemBasedOnAction(
  item: IGroupItem<
    | object
    | {
        [parentKey]?: boolean;
      }
  >,
  action: ICheckedAtom['action'],
) {
  switch (action) {
    case 'select':
      return {
        ...item,
        checked: item.disabled ? item.checked : true,
      };
    case 'invert':
      return {
        ...item,
        checked: item.disabled ? item.checked : !item.checked,
        value: {
          ...item.value,
          ...(parentKey in item.value && item.checked && { [parentKey]: false }),
        },
      };
    case 'clear':
      return {
        ...item,
        checked: item.disabled ? item.checked : false,
        value: {
          ...item.value,
          ...(parentKey in item.value && { [parentKey]: false }),
        },
      };
  }
}

export function compareFnGroup(a: IGroupItem<object>, b: IGroupItem<object>) {
  if (a.disabled !== b.disabled) {
    return a.disabled ? -1 : 1;
  }
  if (a.checked !== b.checked) {
    return a.checked ? -1 : 1;
  }
  return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
}

export function getUncheckedChildGroups(clusters: TBuildClusters, clients: TBuildClients, operators: TBuildOperators) {
  const clusterMap = new Map(clusters.map((cluster) => [cluster.id, cluster]));
  const clientMap = new Map(clients.map((client) => [client.id, client]));

  const updatedClients = clients.map((client) => {
    const clusterParent = clusterMap.get(client.value.clusterId);
    if (!clusterParent?.checked) {
      return {
        ...client,
        checked: false,
        disabled: false,
        value: {
          ...client.value,
          selectAndAllParent: false,
        },
      };
    }
    return client;
  });

  const updatedOperators = operators.map((operator) => {
    const clusterParent = clusterMap.get(operator.value.clusterId);
    const clientParent = clientMap.get(operator.value.clientId);
    if (!clusterParent?.checked || !clientParent?.checked) {
      return {
        ...operator,
        checked: false,
        disabled: false,
      };
    }
    return operator;
  });

  return { updatedClients, updatedOperators };
}

export function getFilteredClients(clusters: TBuildClusters, clients: TBuildClients) {
  const clusterMap = new Map(clusters.map((cluster) => [cluster.id, cluster]));

  return clients
    .filter((client) => {
      const clusterParent = clusterMap.get(client.value.clusterId);
      return clusterParent?.checked;
    })
    .map((client) => {
      const clusterParent = clusterMap.get(client.value.clusterId);
      return clusterParent?.value.selectAndAllParent ? { ...client, disabled: true, checked: true } : client;
    });
}

export function getFilteredOperators(clusters: TBuildClusters, clients: TBuildClients, operators: TBuildOperators) {
  const clusterMap = new Map(clusters.map((cluster) => [cluster.id, cluster]));
  const clientMap = new Map(clients.map((client) => [client.id, client]));

  return operators
    .filter((operator) => {
      const clientParent = clientMap.get(operator.value.clientId);
      const clusterParent = clusterMap.get(operator.value.clusterId);
      if (!clusterParent?.checked) {
        return false;
      }
      return clientParent?.checked || clusterParent?.value.selectAndAllParent;
    })
    .map((operator) => {
      const clientParent = clientMap.get(operator.value.clientId);
      const clusterParent = clusterMap.get(operator.value.clusterId);

      return clusterParent?.value.selectAndAllParent || clientParent?.value.selectAndAllParent
        ? { ...operator, checked: true, disabled: true }
        : operator;
    });
}

export function getUpdatedClientsSelectAll(clients: TBuildClients, clientId: string, shouldCheck: boolean) {
  const updatedClients = clients.map((client) =>
    client.id === clientId
      ? { ...client, checked: shouldCheck, value: { ...client.value, selectAndAllParent: shouldCheck } }
      : client,
  );
  return updatedClients;
}

export function getUpdatedClustersSelectAll(clusters: TBuildClusters, clusterId: string, shouldCheck: boolean) {
  const updatedClusters = clusters.map((cluster) =>
    cluster.id === clusterId
      ? { ...cluster, checked: shouldCheck, value: { ...cluster.value, selectAndAllParent: shouldCheck } }
      : cluster,
  );
  return updatedClusters;
}

export function uncheckClusterClients(clusterId: string, clients: TBuildClients) {
  return clients.map((client) =>
    client.value.clusterId === clusterId
      ? { ...client, checked: false, value: { ...client.value, selectAndAllParent: false } }
      : client,
  );
}

export function uncheckClusterOperators(clusterId: string, operators: TBuildOperators) {
  return operators.map((operator) =>
    operator.value.clusterId === clusterId ? { ...operator, checked: false } : operator,
  );
}

export function uncheckClientOperators(clientId: string, operators: TBuildOperators) {
  return operators.map((operator) =>
    operator.value.clientId === clientId ? { ...operator, checked: false } : operator,
  );
}

export function canSelectAndAllCluster(user: IUser, clusterId: string) {
  if (user.role === EUserRole.ADMIN) {
    return true;
  }

  if (user.role === EUserRole.AREA_ADMIN) {
    const scope = user.scopes.find((scope) => scope.clusterId === clusterId && !scope.clientId && !scope.operatorId);
    if (!scope) {
      return false;
    } else {
      return true;
    }
  }

  return false;
}

export function canSelectAndAllClient(user: IUser, clientId: string, clusterId: string) {
  if (user.role === EUserRole.ADMIN || canSelectAndAllCluster(user, clusterId)) {
    return true;
  }

  if (user.role === EUserRole.AREA_ADMIN) {
    const scope = user.scopes.find((scope) => scope.clientId === clientId && !scope.operatorId);
    if (!scope) {
      return false;
    } else {
      return true;
    }
  }

  return false;
}

export function mapScopesToLists(scopes: IScope[]): {
  clustersIds: string[];
  clientsIds: string[];
  operatorsIds: string[];
} {
  return {
    clustersIds: [...new Set(scopes.map((scope) => scope.clusterId as string).flat())],
    clientsIds: [
      ...new Set(
        scopes
          .map((scope) => scope.clientId as string)
          .filter(Boolean)
          .flat(),
      ),
    ],
    operatorsIds: [
      ...new Set(
        scopes
          .map((scope) => scope.operatorId as string)
          .filter(Boolean)
          .flat(),
      ),
    ],
  };
}
