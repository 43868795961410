import { type GridColDef } from '@mui/x-data-grid';
import { type TFunction } from 'i18next';
import { type IColumnMapper } from 'components/ReportExport/types';

export type TColumnMapperTransformFn = (columns: GridColDef[]) => IColumnMapper[];

type TNormalizedMapperBuilder = {
  normalizeCurrency: string | null;
  prefixes: [string, string];
  affectedFields: string[];
  translations: Record<string, string>;
  keyMap?: Record<string, string>;
};

export const getColumnMappers: TColumnMapperTransformFn = (columns) => {
  const columnMappers = columns.map((column) => ({ field: column.field, label: column.headerName || column.field }));
  return columnMappers;
};

export const buildNormalizedMappers = (
  { normalizeCurrency, prefixes, affectedFields, translations, keyMap = {} }: TNormalizedMapperBuilder,
  t: TFunction,
): TColumnMapperTransformFn => {
  return (columns) => {
    const mappers: IColumnMapper[] = [];
    for (const column of columns) {
      if (affectedFields.includes(column.field)) {
        for (const prefix of prefixes) {
          const translationKey = `${prefix}.${column.field}` as keyof typeof translations;
          if (!(prefix === 'normalizedValues') || (prefix === 'normalizedValues' && normalizeCurrency)) {
            mappers.push({
              field: translationKey,
              label: t(translations[translationKey], { currency: normalizeCurrency }),
            });
          }
        }
      } else {
        mappers.push({
          field: column.field in keyMap ? keyMap[column.field] : column.field,
          label: column.headerName || column.field,
        });
      }
    }

    return mappers;
  };
};
