import { useMemo, useRef, useState, type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Fade, Grow, Skeleton, Typography } from '@mui/material';
import { type ListItem } from 'components';
import SearchInput from 'components/SearchInput';
import { GroupButton } from './GroupButton';
import { GROUP_ITEM_HEIGHT, GroupItem, type IGroupItem } from './GroupItem';
import { findTargetIndexAlphabetically, getAbsoluteTargetPosition } from './helpers';

export interface IGroupCallbacks {
  onInvert: (ids: string[]) => void;
}

export interface IGroupSharedCallbacks {
  onSelect: (ids: string[]) => void;
  onClear: (ids: string[]) => void;
}

export interface IGroupItemStateCallbacks {
  additionalRendered?: (props: IGroupItem<{ selectAndAllParent: boolean }>, hovered: boolean) => JSX.Element | null;
  itemProps?: (props: IGroupItem<object>) => ComponentProps<typeof ListItem> | void;
}

export interface IGroupProps<T> extends IGroupCallbacks, IGroupItemStateCallbacks, IGroupSharedCallbacks {
  title: string;
  items: IGroupItem<T>[];
  loading?: boolean;
}

export function Group<T>({
  title,
  items,
  onClear,
  onInvert,
  onSelect,
  additionalRendered,
  loading,
  itemProps,
}: IGroupProps<T>) {
  const ids = useMemo(() => items.map((item) => item.id), [items]);

  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const listRef = useRef<HTMLDivElement>(null);

  const shouldButtonsBeDisabled = items.every((item) => item.disabled) || items.length === 0;

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Box
      sx={{
        'display': 'flex',
        'flexDirection': 'column',
        'width': 220,
        'position': 'relative',
        ':not(:last-child)::after': {
          position: 'absolute',
          height: '100%',
          width: '1px',
          content: '""',
          backgroundColor: 'grey.100',
          right: -8,
          top: 0,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Typography variant="button" fontWeight="bold">
          {title}
        </Typography>

        <Grow in={items.length > 0}>
          <Typography variant="overline" fontSize={14} color="primary.main">
            ({items.filter((item) => item.checked).length}/{items.length})
          </Typography>
        </Grow>
      </Box>

      <Box sx={{ mb: 1 }}>
        <SearchInput value={search} onValueChange={onSearch} disabled={items.length === 0} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          opacity: shouldButtonsBeDisabled ? 0.25 : 1,
          pointerEvents: shouldButtonsBeDisabled ? 'none' : 'default',
        }}
      >
        {items.every((item) => item.checked) && items.length > 0 ? (
          <GroupButton onClick={() => onClear(ids)}>
            <CheckBoxIcon color="primary" fontSize="small" />

            <Typography variant="caption" color="text.primary" sx={{ marginTop: '2px', whiteSpace: 'nowrap' }}>
              {t('base.ClearAll')}
            </Typography>
          </GroupButton>
        ) : (
          <GroupButton onClick={() => onSelect(ids)}>
            <CheckBoxOutlineBlankIcon color="action" fontSize="small" />

            <Typography variant="caption" color="text.primary" sx={{ marginTop: '2px', whiteSpace: 'nowrap' }}>
              {t('base.SelectAll')}
            </Typography>
          </GroupButton>
        )}

        <GroupButton onClick={() => onInvert(ids)}>{t('base.InvertSelection')}</GroupButton>
      </Box>

      <Box
        sx={{
          py: 0.5,
          border: '1px solid',
          borderColor: 'divider',
          height: 240,
          overflowY: 'scroll',
          overflowX: 'hidden',
          px: 0.5,
          position: 'relative',
          mt: 1.5,
        }}
        ref={listRef}
      >
        <Fade in={loading}>
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              flexDirection: 'column',
              position: 'absolute',
              width: '96%',
              height: '90%',
              top: 7.5,
            }}
          >
            {Array.from({ length: 7 }).map((_, i) => (
              <Skeleton variant="rectangular" key={i} height={17.5} />
            ))}
          </Box>
        </Fade>

        {items && items.length === 0 && !loading && (
          <Fade in={true}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="overline"
                color="divider"
                textAlign="center"
                sx={{ justifyContent: 'center', width: 1 }}
              >
                {t('base.NoData')}
              </Typography>
            </Box>
          </Fade>
        )}

        {items
          .filter((item) => !search || (item.title && item.title.toLowerCase().includes(search.toLowerCase())))
          .map((item, index) => {
            return (
              <GroupItem
                key={`${item.id}${index}`}
                onClear={onClear}
                onSelect={onSelect}
                additionalRendered={additionalRendered}
                itemProps={itemProps}
                containerRef={listRef}
                index={index}
                itemCount={items.length}
                getTransitionConfig={(affectedItem) => {
                  const list = listRef.current;
                  const checkedItems = items.filter((item) => item.checked && !item.disabled);
                  const uncheckedItems = items.filter((item) => !item.checked);
                  const disabledItems = items.filter((item) => item.checked && item.disabled);

                  if (!list) {
                    return { animate: false, dir: null };
                  }

                  const targetIndex = findTargetIndexAlphabetically(
                    affectedItem.checked ? uncheckedItems : checkedItems,
                    item,
                  );

                  const absolutePosition = getAbsoluteTargetPosition(
                    targetIndex * GROUP_ITEM_HEIGHT,
                    checkedItems.length,
                    disabledItems.length,
                    GROUP_ITEM_HEIGHT,
                    item.checked,
                  );

                  const isWithinVisibleBounds =
                    absolutePosition >= list.scrollTop &&
                    absolutePosition <= list.scrollTop + list.getBoundingClientRect().height;

                  if (isWithinVisibleBounds) {
                    return {
                      animate: false,
                    };
                  }

                  return {
                    animate: true,
                    dir: item.checked ? 'down' : 'up',
                  };
                }}
                {...item}
                {...(item.tooltip &&
                  items.filter((groupItem) => groupItem.title === item.title).length > 1 && {
                    optionalName: item.tooltip.parentName,
                  })}
              />
            );
          })}
      </Box>
    </Box>
  );
}
