import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom, type WritableAtom } from 'jotai';
import { Group } from 'components/CheckboxGroup/Group';
import { type IGroupItem } from 'components/CheckboxGroup/GroupItem';
import { useTheme, useUser } from 'hooks/index';
import { type TClientAtomReturn } from './accessesFeature/groupsAtom';
import { SelectAllAndNew } from './SelectAllAndNew';

export const TeamClientsGroup: FC<{
  loading: boolean;
  atoms: TClientAtomReturn;
  failedValidationIds?: string[];
}> = ({ loading, atoms, failedValidationIds = [] }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const currentUser = useUser();

  const clients = useAtomValue(atoms.dataVisible);

  const select = useSetAtom(atoms.select);
  const invert = useSetAtom(atoms.invert);
  const clear = useSetAtom(atoms.clear);
  const selectAllAndNew = useSetAtom(atoms.selectAllAndNew as WritableAtom<null, unknown[], void>);
  const canSelectAllAndNew = useSetAtom(atoms.canUserSelectAllAndNew as WritableAtom<null, unknown[], boolean>);

  const renderAdditional = useCallback(
    (client: IGroupItem<{ selectAndAllParent: boolean }>, hovered: boolean) => {
      if (canSelectAllAndNew(currentUser, client.id)) {
        return (
          <SelectAllAndNew
            item={client}
            selectAllAndNew={selectAllAndNew}
            hovered={hovered}
            selectText={t('base.selectAllAndNewClients')}
            unselectText={t('base.excludeClients')}
          />
        );
      } else {
        return null;
      }
    },
    [selectAllAndNew],
  );

  return (
    <Group
      title={t('accounting.clients')}
      items={clients}
      onSelect={select}
      onClear={clear}
      onInvert={invert}
      additionalRendered={renderAdditional}
      loading={loading}
      itemProps={(item) => {
        if (failedValidationIds.includes(item.id) && !item.checked) {
          return {
            sx: {
              color: theme.palette.error.light,
            },
          };
        }
      }}
    />
  );
};

export default TeamClientsGroup;
