import { getFilterTableKey } from 'components/TableExport/translationMap';

type TParseFilters = {
  normalized: boolean;
  removedKeys?: string[];
};

export function removeKeys(o: Record<string, unknown>, keysToRemove?: string[]) {
  const result = { ...o };

  if (!keysToRemove) {
    return result;
  }

  for (const k of keysToRemove) {
    delete result[k];
  }
  return result;
}

export function parseWhereFilter(filter: Record<string, unknown>, cfg: TParseFilters) {
  const parsedFilters = removeKeys(filter, cfg.removedKeys);

  const parsedEntries = Object.entries(parsedFilters);

  const modifiedFilters = Object.fromEntries(
    parsedEntries.map(([k, v]) => {
      return [getFilterTableKey(k, cfg.normalized), v];
    }),
  );

  return modifiedFilters;
}
