import { type ComponentProps, type ReactNode } from 'react';
import { Button } from '@mui/material';

interface IGroupButtonProps {
  onClick: ComponentProps<typeof Button>['onClick'];
  children: ReactNode;
}

export function GroupButton({ onClick, children }: IGroupButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant="text"
      size="small"
      sx={{
        textTransform: 'none',
        padding: '2px 4px',
        borderBottom: '1px solid',
        borderColor: 'divider',
        borderRadius: 0,
        color: 'text.primary',
        display: 'flex',
        gap: 0.5,
      }}
    >
      {children}
    </Button>
  );
}
