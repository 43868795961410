import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { isDev } from 'utils/isDev';

i18n
  .use(LanguageDetector)
  .use(
    resourcesToBackend((language: string) => {
      return import(`./resources.${language.toLowerCase().slice(0, 2)}.json`);
    }),
  )
  .use(initReactI18next)
  .init({
    debug: isDev(),
    fallbackLng: 'en-US',
    preload: ['en-US'],
    load: 'languageOnly',
    supportedLngs: ['en', 'ja'],
  });

export default i18n;
