import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useMenuItems } from 'components/LeftMenu/useMenuItems';
import { useCurrentPath } from 'hooks/useCurrentPath';

const buildTranslationKeys = (currentPath: string) => {
  const splitPath = currentPath.split('/').filter(Boolean);

  return splitPath.map((path, i) => {
    const fullPath = `/${splitPath.slice(0, i + 1).join('/')}`;

    const key = path.startsWith(':') ? path : `navigation.${splitPath.slice(0, i + 1).join('.')}.title`;

    return { path: fullPath, key };
  });
};

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const params = useParams();
  const currentPath = useCurrentPath();
  const builtBreadcrumbs = useMemo(() => buildTranslationKeys(currentPath || ''), [currentPath]);

  const { menuRoutes } = useMenuItems();

  if (!currentPath) {
    return null;
  }

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      sx={(theme) => ({
        background: theme.palette.background.default,
        color: 'black',
        pl: 8,
        pt: 6,
      })}
    >
      <Toolbar variant="dense">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {builtBreadcrumbs.map((breadcrumb, i) => {
            const displayText = breadcrumb.key.startsWith(':')
              ? params[breadcrumb.key.replace(':', '')]
              : t(breadcrumb.key);
            const isLastElement = i === builtBreadcrumbs.length - 1;

            return (
              <Box key={`${displayText}${i}`} sx={{ display: 'flex', alignItems: 'center' }}>
                {menuRoutes.includes(breadcrumb.path) ? (
                  <Link to={breadcrumb.path} style={{ textDecoration: 'none' }}>
                    <Typography
                      color="ButtonText"
                      fontSize={12}
                      fontWeight={isLastElement ? 700 : 500}
                      sx={{
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {displayText}
                    </Typography>
                  </Link>
                ) : (
                  <Typography color="ButtonText" fontSize={12} fontWeight={isLastElement ? 700 : 500}>
                    {displayText}
                  </Typography>
                )}

                <Typography color="ButtonText" sx={{ ml: 1 }} fontSize={12}>
                  {!isLastElement && '/'}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
