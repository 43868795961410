import { useCallback, useEffect, type MutableRefObject } from 'react';
import { type GridApiCommunity } from '@mui/x-data-grid/internals';

interface IProps {
  localStorageKey: string;
  apiRef: MutableRefObject<GridApiCommunity>;
  disableManageColumns?: boolean;
  defaultHideColumns?: string[];
}

const LOCAL_STORAGE_KEY_PREFIX = 'x-data-grid-coolumns-visibility--';
export const useSaveDataGridColumnsVisibility = ({
  localStorageKey,
  apiRef,
  disableManageColumns,
  defaultHideColumns,
}: IProps) => {
  const loadColumnsVisibility = useCallback(() => {
    try {
      const columnsVisibilityJson = localStorage.getItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey);
      const columns = apiRef.current.getAllColumns();
      if (columnsVisibilityJson) {
        const columnsVisibility = JSON.parse(columnsVisibilityJson);
        columns.forEach((column) => {
          const savedVisibility = columnsVisibility?.[column.field];
          if (typeof savedVisibility === 'boolean') {
            apiRef.current.setColumnVisibility(column.field, savedVisibility);
          }
        });
      } else {
        columns.forEach((column) => {
          if (defaultHideColumns?.includes(column.field)) {
            apiRef.current.setColumnVisibility(column.field, false);
          }
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, [apiRef.current, localStorageKey]);

  const showAllColumns = useCallback(() => {
    const columns = apiRef.current.getAllColumns();
    columns.forEach((column) => apiRef.current.setColumnVisibility(column.field, true));
  }, [apiRef.current]);

  useEffect(() => {
    if (disableManageColumns) {
      showAllColumns();
    } else {
      loadColumnsVisibility();
    }
  }, [disableManageColumns]);

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnVisibilityModelChange', (columnsVisibility) => {
      if (!disableManageColumns) {
        localStorage.setItem(LOCAL_STORAGE_KEY_PREFIX + localStorageKey, JSON.stringify(columnsVisibility));
      }
    });
  }, [apiRef.current, disableManageColumns]);

  return {
    loadColumnsVisibility,
    showAllColumns,
  };
};
