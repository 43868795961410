import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, MenuItem, PopperMenu, Typography } from 'components';
import { useLoading } from 'hooks/useLoading';
import type { IUser } from 'hooks/useUser';
import { showError } from 'utils';
import TeamMembersApproveDelete from 'views/TeamMembers/TeamMembersApproveDelete';
import TeamMemberUserPasswordPopup from './TeamMemberUserPasswordPopup';

interface IProps {
  user?: IUser;
  disabled?: boolean;
  deleteUser: () => void;
}

const RESET_PASSWORD = gql`
  mutation ResetPassword($id: String!) {
    resetUserPassword(id: $id) {
      password
    }
  }
`;

export const TeamMemberUserMoreButton = ({ disabled, user, deleteUser }: IProps) => {
  const { t } = useTranslation();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [userData, setUserData] = useState<{ password: string; email: string } | null>(null);
  const [resetPassword, { loading, data, error }] = useMutation<{ resetUserPassword: { password: string } }>(
    RESET_PASSWORD,
  );
  const [openDelete, setOpenDelete] = useState(false);
  const { startLoading, endLoading } = useLoading();
  const [openMenu, setOpenMenu] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    loading ? startLoading('resetUserPassword') : endLoading('resetUserPassword');
  }, [loading]);

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data && user) {
      setUserData({ password: data.resetUserPassword.password, email: user.email });
    }
  }, [data]);

  return (
    <>
      <Button disabled={disabled} ref={moreButtonRef} variant="outlined" onClick={() => setOpenMenu(true)}>
        {t('base.more')}
      </Button>

      <PopperMenu
        open={openMenu}
        anchorEl={moreButtonRef.current}
        onClose={() => setOpenMenu(false)}
        paperProps={{ sx: { p: 0.5 } }}
      >
        <MenuItem
          onClick={() => {
            setOpenConfirmation(true);
            setOpenMenu(false);
          }}
        >
          {t('team.resetPassword')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDelete(true);
            setOpenMenu(false);
          }}
        >
          {t('base.Delete')}
        </MenuItem>
      </PopperMenu>

      {user && (
        <>
          <Dialog open={openConfirmation} onClose={() => setOpenConfirmation(false)} PaperProps={{ sx: { p: 2 } }}>
            <DialogContent>
              <Typography textAlign="center" variant="h6" sx={{ mb: 2 }}>
                {t('team.resetPasswordHelperText1')}
              </Typography>

              <Typography textAlign="center" variant="h6" sx={{ wordBreak: 'break-all', fontStyle: 'italic', mb: 2 }}>
                {user.email}
              </Typography>

              <Typography textAlign="center" variant="h6">
                {t('team.resetPasswordHelperText2')}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenConfirmation(false);
                  resetPassword({ variables: { id: user.id }, fetchPolicy: 'network-only' });
                }}
              >
                {t('base.Reset')}
              </Button>
              <Button onClick={() => setOpenConfirmation(false)}>{t('base.Cancel')}</Button>
            </DialogActions>
          </Dialog>
          <TeamMemberUserPasswordPopup
            userData={userData}
            onClose={() => setUserData(null)}
            title={t('team.userUpdated')}
          />

          <TeamMembersApproveDelete
            usersToDelete={[user.id]}
            open={openDelete}
            onConfirm={() => {
              deleteUser();
              setOpenDelete(false);
            }}
            onClose={() => setOpenDelete(false)}
            data={[user]}
          />
        </>
      )}
    </>
  );
};

export default TeamMemberUserMoreButton;
