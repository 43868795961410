import { useTranslation } from 'react-i18next';
import { Box, HeaderText } from 'components';
import AccountingReportFilters from './AccountingReportFilters';
import AccountingReportTable from './AccountingReportTable';

export const AccountingReport = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <HeaderText>{t('accounting.title')}</HeaderText>
      <AccountingReportFilters />
      <AccountingReportTable />
    </Box>
  );
};

export default AccountingReport;
