import { useEffect } from 'react';
import { Box } from 'components';
import { useSidemenu } from 'hooks/useSideMenu';
import DashboardPath from '../../../public/dashboard.png';
import FirstLoginChangePassword from './FirstLoginChangePassword';

export const Dashboard = () => {
  const { setMenuOpen } = useSidemenu();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMenuOpen(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <FirstLoginChangePassword />

      <Box
        component="img"
        src={DashboardPath}
        sx={{ width: '50%', height: 'calc(100vh - 160px)', objectFit: 'contain', maxWidth: 1400 }}
      />
    </Box>
  );
};

export default Dashboard;
