import { t } from 'i18next';
import { showToast } from './showToast';

export const showError = (error?: Error) => {
  showToast({
    title: t('base.baseError'),
    text: error?.message,
    severity: 'error',
    autoClose: error?.message ? false : undefined,
  });
};
