import { memo, useMemo, useState, type FC, type RefObject } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckbox from '@mui/icons-material/IndeterminateCheckBox';
import { Box, ListItem, ListItemButton, ListItemIcon, Typography } from 'components';
import { type ITooltipDisplayName } from 'views/TeamMembersUser/accessesFeature/accessBuildGroups';
import { type IGroupItemStateCallbacks, type IGroupSharedCallbacks } from './Group';

enum ECheckboxState {
  CHECKED,
  UNCHECKED,
  DISABLED,
}

type TAnimationState = { dir: 'up' | 'down'; animate: true } | { animate: false };

export interface IGroupItem<T> extends ITooltipDisplayName {
  id: string;
  title: string;
  checked: boolean;
  value: T & { id: string };
  disabled?: boolean;
}

export interface IGroupAnimationProps<T> {
  containerRef: RefObject<HTMLDivElement>;
  getTransitionConfig: (item: IGroupItem<T>) => TAnimationState;
  index: number;
  itemCount: number;
}

export const GROUP_ITEM_HEIGHT = 24;

export interface IGroupItemProps<T>
  extends IGroupItem<T>,
    IGroupSharedCallbacks,
    IGroupItemStateCallbacks,
    IGroupAnimationProps<T> {
  optionalName?: string;
}

const getCheckboxState = (checked: boolean, shouldDisableItem: boolean): ECheckboxState => {
  if (shouldDisableItem) {
    return ECheckboxState.DISABLED;
  }
  if (checked) {
    return ECheckboxState.CHECKED;
  }

  return ECheckboxState.UNCHECKED;
};

export const GroupItem = memo(function GroupItem<T>(props: IGroupItemProps<T>) {
  const {
    onSelect,
    checked,
    title,
    value,
    id,
    onClear,
    disabled,
    additionalRendered,
    getTransitionConfig,
    itemProps,
    optionalName,
  } = props;

  const checkboxState = useMemo(() => getCheckboxState(checked, !!disabled), [checked, disabled]);

  const [transition, setTransition] = useState<TAnimationState>({
    animate: false,
  });

  const [hovered, setHovered] = useState(false);

  const toggleItem = () => {
    const transition = getTransitionConfig({ checked, id, title, value, disabled });

    setTransition(transition);

    setTimeout(
      () => {
        if (checkboxState === ECheckboxState.CHECKED) {
          onClear([value.id]);
        }

        if (checkboxState === ECheckboxState.UNCHECKED) {
          onSelect([value.id]);
        }

        setTransition({ animate: false });
      },
      transition.animate ? 500 : 0,
    );
  };

  return (
    <ListItem
      disablePadding
      sx={{
        height: transition && transition.animate ? 0 : GROUP_ITEM_HEIGHT,
        opacity: transition && transition.animate ? 0 : 1,
        transform:
          transition && transition.animate
            ? `translateY(${50 * (transition.dir === 'up' ? -1 : 1)}px)`
            : `translate(0px)`,
        borderBottom: '1px solid',
        borderColor: 'transparent',
        transition:
          'transform 1000ms, opacity 1000ms, height 600ms, background-color 600ms, border-color 500ms, border 500ms, color 500ms',
        ...itemProps?.(props)?.sx,
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <ListItemButton
        sx={{ p: 0 }}
        title={`${title} ${optionalName ? '(' + optionalName + ')' : ''}`}
        disabled={checkboxState === ECheckboxState.DISABLED}
        onClick={toggleItem}
      >
        <ListItemIcon sx={{ minWidth: 24 }}>
          <CheckboxState transitioningTo={'dir' in transition ? transition.dir : null} state={checkboxState} />
        </ListItemIcon>
        <Box sx={{ height: GROUP_ITEM_HEIGHT, overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex', pt: 0.25 }}>
          <Typography variant={'body2'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {title}
          </Typography>

          {optionalName && (
            <Typography
              variant={'caption'}
              sx={{ textOverflow: 'ellipsis', overflow: 'hidden', ml: 1 }}
              color="grey.400"
            >
              ({optionalName})
            </Typography>
          )}
        </Box>
      </ListItemButton>

      {typeof additionalRendered === 'function' &&
        additionalRendered(props as unknown as IGroupItem<{ id: string; selectAndAllParent: boolean }>, hovered)}
    </ListItem>
  );
});

const CheckboxState: FC<{ state: ECheckboxState; transitioningTo: 'up' | 'down' | null }> = ({
  transitioningTo,
  state,
}) => {
  if (state === ECheckboxState.UNCHECKED) {
    return (
      <>
        {transitioningTo === 'up' ? (
          <CheckBoxIcon color="primary" fontSize="small" />
        ) : (
          <CheckBoxOutlineBlankIcon color="action" fontSize="small" />
        )}
      </>
    );
  }

  if (state === ECheckboxState.CHECKED) {
    return (
      <>
        {transitioningTo === 'down' ? (
          <CheckBoxOutlineBlankIcon color="action" fontSize="small" />
        ) : (
          <CheckBoxIcon color="primary" fontSize="small" />
        )}
      </>
    );
  }

  if (state === ECheckboxState.DISABLED) {
    return <IndeterminateCheckbox color="disabled" fontSize="small" />;
  }

  return null;
};
