import dayjs from 'dayjs';
import { getDefaultStore } from 'jotai';
import { userPreferencesAtom } from 'hooks/useUserPreferences';

const store = getDefaultStore();

type TParseDateConfig = {
  format?: string;
};

export const parseDateStringTz = (
  dateString: string,
  timezone = store.get(userPreferencesAtom).timezone,
  config?: TParseDateConfig,
) => {
  return dayjs(dateString)
    .tz(timezone)
    .format(config?.format || 'YYYY-MM-DD HH:mm:ss z');
};
