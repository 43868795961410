import { gql } from '@apollo/client';

export const GROUP_LIST = gql`
  query GroupList {
    clusters {
      id
      displayName
      name
    }
    clients {
      id
      displayName
      name
      clusterId
    }
    operators {
      id
      displayName
      name
      clientId
    }
  }
`;
